import { Box } from '@mui/material';
import useRemove from '../../../../../serverInteraction/hooks/entity/useRemove';
import { DeleteDialog } from '../../../../common/dialog/DeleteDialog';
import useAlert from '../../../../../context/alertContext/useAlert';
import { LifeCycleProps } from '../../../../../types/content/life-cycles/LifeCycleProps';

type Props = {
  lifeCycle: LifeCycleProps;
  onDelete?: () => void;
};

export function LifeCyclesDeleteDialog({ lifeCycle, onDelete }: Props) {
  const { showSuccess } = useAlert();
  const onSuccess = (result: boolean) => {
    if (result) {
      showSuccess('Deleted successfully!');
      onDelete && onDelete();
    }
  };

  const { remove } = useRemove(`remove_life_cycle`, onSuccess);
  const handleDelete = async () => {
    await remove(lifeCycle.lifeCycleId);
  };

  return (
    <DeleteDialog title={`Delete Facet ${lifeCycle.lifeCycleId}`} remove={handleDelete}>
      <Box>{`Are you sure to delete the feature ${lifeCycle.lifeCycleId}?`}</Box>
    </DeleteDialog>
  );
}
