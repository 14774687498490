import { SchemaProps } from '../../../../types/content/schemas/SchemaProps';
import { SchemaWithFieldsJsonProps } from '../../../../types/content/schemas/SchemaWithFieldsProps';
import useGet from '../../entity/useGet';

const useSchemaWithFields = (schema: SchemaProps) => {
  // const entityName = 'schema_fields';
  const entityName = 'schemas/complete-hierarchy';

  const { status, data, error, isLoading, isFetching, isRefetching, refetch } = useGet<SchemaWithFieldsJsonProps>(
    entityName,
    `project=${schema.companyName}-${schema.documentName}&featureId=${schema.featureId}`,
  );

  return {
    status,
    schema: data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default useSchemaWithFields;
