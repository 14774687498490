import {Box} from '@mui/material'
import { ControlledInput } from '../../../../../../common/input/ControlledInput';
import useCreate from '../../../../../../../serverInteraction/hooks/entity/useCreate';

import { GridOneToThree, GridOneToThreeItemProps } from '../../../../../../common/grid/GridOneToThree';
import { useForm } from 'react-hook-form';
import {Permission} from '../../../../../../../types/content/roles-and-permissions/permissions/Permission';
import { UpdateDialog } from '../../../../../../common/dialog/UpdateDialog';
import useUpdate from '../../../../../../../serverInteraction/hooks/entity/useUpdate';

type Props = {
    Perms: Permission;
    FeaturePermsList: String[];
    refetch: () => void;
  };

export function UpdateDialogPerms({refetch, ...props}: Props) {
    const {update} = useUpdate<Permission, Permission>('updatePerm');
    
    const {
            control,
            register,
            handleSubmit,
            trigger,
            formState: { errors },
        } = useForm<Permission>();

        const createItems: GridOneToThreeItemProps[] = [
            {
              title: 'Feature ID',
              required: true,
              content: (
                <ControlledInput
                  type="text"
                  register={{
                    ...register('featureId', {
                      required: {
                        value: true,
                        message: 'Feature ID is required',
                      },
                      minLength: {
                        value: 3,
                        message: 'Must be at least 3 symbols',
                      },
                      maxLength:{
                        value: 40,
                        message: 'Must be at more 40 symbols',
                      },
                      pattern:{
                        value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                        message: "Only alphanumeric characters, '_' and '-' are allowed in the IDs. The ID must start with a letter.",
                      },
                      validate: value => props.FeaturePermsList.indexOf(value) === -1 || 'FeatureId is not unique',
                    }),
                  }}
                  helperText={errors.featureId?.message}
                  error={errors.featureId !== undefined}
                  sx={{ width: '100%' }}
                />
              ),
            }
        ];
    const onSubmit = async (newPerms: Permission) => {
        newPerms.id = props.Perms.id;
        await update(props.Perms.featureId, newPerms);
    };

    const validate = async () => {
        return await trigger();
    };
    
    return (
    <UpdateDialog title="Update Permission" update={handleSubmit(onSubmit)} validate={validate} isUpdate={refetch}> 
        <hr />
        <Box sx={{height: "auto", marginTop: 1}}>
        <GridOneToThree items={createItems} />
        </Box>
    </UpdateDialog>
    );
}