// Ваш файл с контекстом (DocumentContext.tsx)

import React, { createContext, useContext, useReducer, Dispatch } from 'react';

interface DocumentState {
  tabValues: Record<number, any>; // Хранение значений полей для каждой вкладки
}

type DocumentAction = { type: 'SET_TAB_VALUES'; tab: number; values: any };

const initialState: DocumentState = {
  tabValues: {},
};

const DocumentContext = createContext<
  { state: DocumentState; dispatch: Dispatch<DocumentAction> } | undefined
>(undefined);

const documentReducer = (state: DocumentState, action: DocumentAction): DocumentState => {
  switch (action.type) {
    case 'SET_TAB_VALUES':
      return {
        ...state,
        tabValues: {
          ...state.tabValues,
          [action.tab]: action.values,
        },
      };
    default:
      return state;
  }
};

// const DocumentProvider: React.FC = ({ children }) => {
const DocumentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(documentReducer, initialState);
  return (
    <DocumentContext.Provider value={{ state, dispatch }}>{children}</DocumentContext.Provider>
  );
};

const useDocumentContext = () => {
  const context = useContext(DocumentContext);
  if (!context) {
    throw new Error('useDocumentContext must be used within a DocumentProvider');
  }
  return context;
};

export { DocumentProvider, useDocumentContext };
