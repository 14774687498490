import { Box, Button } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { RenameDialog } from '../../../../common/dialog/RenameDialog';

type StateBlockProps = {
  style?: any;
  value?: string;
};

const StateBlock: React.FC<StateBlockProps> = ({ style, value }: StateBlockProps) => {
  const [isMouseCaptured, setIsMouseCaptured] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const stateElement = useRef<HTMLDivElement>(null);
  const [name, setName] = useState('');
  const [open, setOpen] = useState(false);
  const [isHidden, setIsHidden] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  document.addEventListener('click', (e: MouseEvent) => {
    if (!(e.target as HTMLElement).classList.contains('arrow_down_right_icon')) {
      setIsHidden(false);
    }
  });

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  useEffect(() => {
    setOpen(false);
  }, [name]);

  const handleMouseEnter = (e: React.MouseEvent) => {
    if (e.currentTarget.classList.contains('state_container')) {
      if (e.currentTarget) {
        e.currentTarget.children[0].removeAttribute('hidden');
      }
    }
  };

  const handleMouseLeave = (e: React.MouseEvent) => {
    e.currentTarget.children[0].setAttribute('hidden', `${true}`);
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsMouseCaptured(true);
    const { clientX, clientY } = event;
    const rect = stateElement.current?.getBoundingClientRect();

    if (rect) {
      const offsetX = clientX - rect.left;
      const offsetY = clientY - rect.top;
      setPosition({ x: offsetX, y: offsetY });
    }

    document.addEventListener('mouseup', handleMouseRelease);
  };

  const handleMouseRelease = () => {
    setIsMouseCaptured(false);
    document.removeEventListener('mouseup', handleMouseRelease);
  };

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (isMouseCaptured) {
        const parentRect = stateElement.current?.parentElement?.getBoundingClientRect();

        if (parentRect) {
          const x = event.clientX - parentRect.left - position.x;
          const y = event.clientY - parentRect.top - position.y;

          const moveStyles: React.CSSProperties = {
            position: 'absolute',
            top: `${y}px`,
            left: `${x}px`,
          };

          if (stateElement.current?.style) {
            Object.assign(stateElement.current.style, moveStyles);
          }
        }
      }
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isMouseCaptured, position]);

  const handleDeleteState = (target: any) => {
    target.currentTarget.parentElement.parentElement.remove();
  };

  const handRename = (newName: string) => {
    if (newName && newName !== undefined && newName !== '' && newName !== null) {
      setName(newName);
    }
  };

  const handleHidden = () => {
    setIsHidden(!isHidden);
  };

  const handleIsOpen = (newValue: boolean) => {
    setOpen(newValue);
  };

  const modalToggle = (e: React.MouseEvent) => {
    e.currentTarget.parentElement?.setAttribute('hidden', `${true}`);
    handleMouseLeave(e);
    setOpen(true);
  };
  return (
    <>
      <Box
        ref={stateElement}
        className="state_container"
        onMouseDown={handleMouseDown}
        sx={style}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {name !== '' ? name : value}
        <div
          style={{ cursor: 'pointer' }}
          className="icon__container"
          hidden
          onClick={handleHidden}
        >
          <svg
            className="arrow_down_right_icon"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M7 7l10 10" />
            <path d="M17 8l0 9l-9 0" />
          </svg>
        </div>
        <Box
          display={!isHidden ? 'none' : 'flex'}
          bgcolor="#fff"
          width="auto"
          border="1px solid #000"
          padding="10px"
          borderRadius="4px"
          position="absolute"
          top="100%"
          left="100%"
          sx={{ cursor: 'pointer' }}
        >
          {/* <DeleteIconButton sx={{ width: 10, height: 10 }} size="small" /> */}
          <Button
            startIcon={<DeleteIcon />}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            onClick={(e) => handleDeleteState(e)}
          />
          <Button
            startIcon={<ArrowRightAltIcon />}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            // onClick={(e) => handleCreateLink(e)}
          />
          <Button
            onClick={(e) => modalToggle(e)}
            startIcon={<EditIcon />}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          />
        </Box>
        <RenameDialog
          isClose={handleIsOpen}
          open={open}
          returnNewName={handRename}
          title="Rename state"
          inputRef={inputRef}
        />
      </Box>
    </>
  );
};

export default StateBlock;
