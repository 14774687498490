import { SelectField } from "../types/common/SelectField";

export const labelFieldValid: string = 'label.schema.constraint.violation';

export const FieldsSelectValue: Array<SelectField> = [
    {value: 'STRING', label: 'String'},
    {value: 'BOOLEAN', label: 'Boolean'},
    {value: 'INTEGER', label: 'Integer'},
    {value: 'FLOATING_POINT', label: 'Floating point'},
    {value: 'DATE', label: 'Date'},
    {value: 'BLOB', label: 'Blob'},
    {value: 'COMPLEX', label: 'Complex'},
    {value: 'DIRECTORY', label: 'Directory'},
    {value: 'USER_GROUP', label: 'User/Group'},
    {value: 'DOCUMENT', label: 'Document'}
];

export const PatternLists: Array<SelectField> = [
    {value: 'unknown', label: 'Provided patterns'},
    {value: '^.*\\S+.*$', label: 'Not empty'},
    {value: "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$", label: 'Email'},
    {value: "^((www\\.|(http|https|ftp|news|file)+\\:\\/\\/)[_.a-z0-9-]+\\.[a-z0-9\\/_:@=.+?,##%&~-]*[^.|\\'|\\# |!|\\(|?|,| |>|<|;|\\)])$", label: 'Web link'},
    {value: '^[a-zA-Zа-яА-ЯёЁ ]*$', label: 'Letters and spaces'},
    {value: '^[a-zA-Zа-яА-ЯёЁ0-9]*$', label: 'Letters and digits'},
    {value: '^[-\\w]+(?:\\W+[-\\w]+){9,99}\\W*$', label: 'From 10 to 100 Words'}
];

export const arrayPatternValue: Array<string> = PatternLists.map((item: SelectField) => {return item.value as string});

export const ListDocsValueStored: Array<SelectField> = [
    {value: 'id', label: 'docsValue_id'},
    {value: 'idOnly', label: 'docsValue_idOnly'},
    {value: 'path', label: 'docsValue_path'},
    {value: 'pathOnly', label: 'docsValue_pathOnly'}
];

export const ListUserGroupContains: Array<SelectField> = [
    {value: 'all', label: 'listUserGroup_all'},
    {value: 'user', label: 'listUserGroup_user'},
    {value: 'group', label: 'listUserGroup_group'}
];

export const ListDefaultFeatureForDirectory: Array<string> = ['continent', 'country', 'language', 'nature', 'search_operators', 'sort_order', 'subject', 'subtopic', 'topic'];