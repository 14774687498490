import { Button } from "@mui/material";
import { cloneDeep, merge } from "lodash";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { FieldProps } from "../../../../../../../types/content/schemas/Fields/NewFieldProp";
import { SchemaWithFieldsProps } from "../../../../../../../types/content/schemas/SchemaWithFieldsProps";
import { FieldContext } from "../../AdvancedFieldSetting";

type paramUrl = {
    id: string;
    fieldName: string
};

export default function ButtonsFieldView(){
    const params = useParams() as paramUrl;
    const { handleSubmit} = useFormContext<FieldProps>();
    const { t } = useTranslation();
    const schema: SchemaWithFieldsProps = useLocation().state;
    const navigate = useNavigate();
    const { setKey } = useContext(FieldContext);

    const onSubmit = (data: FieldProps) => {
        const newFields = schema.fields.map((item: FieldProps) => {
            return params.fieldName === item.name ? data : item;
        });
        const updatedSchema = merge(schema, {fields: newFields});
        navigate(`/content/schemas/${params.id}`, {state : { schemaFullHierarchy: updatedSchema }});
    };

    return(
        <div style={{
            display: 'flex',
            justifyContent: 'end',
            marginTop: '1em',
            marginRight: '1em'
        }}>
            <Button variant="outlined" color="primary" onClick={() => setKey(Math.random())}>
                {t('button_discard')}
            </Button>
            <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary" sx={{marginLeft: '1em'}}>
                {t('save')}
            </Button>
        </div>
    )
}