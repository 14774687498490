import { Link, Stack, Typography, Box } from '@mui/material';
import { Loader } from '../../../common/loader/Loader';
import { AxiosError } from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import { theme } from '../../../../styling/Theme';
import { SchemasDeleteDialog } from './delete/SchemasDeleteDialog';
import useSchemas from '../../../../serverInteraction/hooks/content/schemas/useSchemas';
import { SchemaProps, SchemaProjectProps } from '../../../../types/content/schemas/SchemaProps';
import { useProjectContext } from '../../../../ProjectContext';
import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { SchemasCreateDialog } from './create/SchemasCreateDialog';
import { convertSchemaProjectToSchema } from '../../../../utils/CommonUtils';

export function SchemasView() {
  const project = useProjectContext();
  const { status, schemas, isLoading, isFetching, error } = useSchemas(project.companyName, project.documentName);

  if (isLoading || isFetching || schemas === undefined) {
    return <Loader />;
  }

  if (status === 'error' && error instanceof AxiosError) {
    return (
      <Typography variant="h6" align="center">
        Error: {error.message}
      </Typography>
    );
  }

  const schemasArr: Array<SchemaProps> = JSON.parse(schemas?.toString()).map((item: SchemaProjectProps)=>{
    return convertSchemaProjectToSchema(item);
  });

  return <SchemasForm schemas={schemasArr} />
}

function SchemasForm({ schemas }:{ schemas: SchemaProps[] }){
  const schemaFormMethods = useForm<{listSchemas: SchemaProps[]}>({
    defaultValues: {
      listSchemas: schemas
    }
  });

  return (
    <FormProvider {...schemaFormMethods}>
      <SchemasCreateDialog />
      <SchemaFormList />
    </FormProvider>
  );
}

function SchemaFormList(){
  const { control } = useFormContext<{listSchemas: SchemaProps[]}>();
  const { fields, remove } = useFieldArray({
    control, 
    name: 'listSchemas',
    keyName: '_id'
  });
  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 600,
          backgroundColor: theme.palette.grey[200],
          py: 1,
          px: 2,
        }}
      >
        Name
      </Typography>
      <Stack>
        {fields.map((schema: SchemaProps, index: number) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ py: 1, px: 2 }}
            key={schema.featureId}
          >
            <Link underline="hover" 
              component={RouterLink} 
              to={`${schema.id}`}
              state={ schema }>
              {schema.featureId}
            </Link>

            <Stack justifyContent="space-between" alignItems="right" spacing={2}>
              <SchemasDeleteDialog schema={schema} onDelete={() => remove(index)} />
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  )
}
