import { Control, Controller, FieldValues, Path } from "react-hook-form";
import {
  Button,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { SelectResourceModal } from "./SelectResourceModal";

type Props<T extends FieldValues> = {
  control?: Control<T>;
  name: Path<T>;
};

export function ControlledSingleResource<T extends FieldValues>({
  control,
  name,
}: Props<T>) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Stack
          direction="row"
          spacing={3}
          sx={{ display: "flex", alignItems: "center" }}
        >
          {value && (
            <ListItem sx={{ p: 0, width: "auto" }}>
              <ListItemIcon sx={{ minWidth: 0 }}>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText sx={{ mx: 1 }}>{value}</ListItemText>
              <IconButton
                aria-label="delete"
                onClick={() => onChange(undefined)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          )}
          <Button
            variant="contained"
            sx={{
              fontSize: "0.75rem",
              px: 2,
              height: "2rem",
            }}
            onClick={handleModalOpen}
          >
            Select
          </Button>
          <SelectResourceModal
            isOpen={isModalOpen}
            handleClose={handleModalClose}
            handleChange={onChange}
          />
        </Stack>
      )}
    />
  );
}
