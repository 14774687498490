import { Box, Button, Checkbox, FormControlLabel, Stack, TextField } from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next";
import { Link as RouterLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { FieldsSelectValue } from "../../../../../../../staticValues/Constants";
import { FieldProps } from "../../../../../../../types/content/schemas/Fields/NewFieldProp";
import { SchemaWithFieldsProps } from "../../../../../../../types/content/schemas/SchemaWithFieldsProps";
import { ControlledSelect } from "../../../../../../common/select/ControlledSelect";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { SimpleModal } from "../../../../../../common/modal/SimpleModal";
import { FieldContext } from "../../AdvancedFieldSetting";
import { cloneDeep, merge } from "lodash";

type paramUrl = {
    id: string;
    fieldName: string
}

export default function CommonFieldView(){
    const schema: SchemaWithFieldsProps = useLocation().state; 
    const { t } = useTranslation();
    const { control, register, formState: {errors} } = useFormContext<FieldProps>();
    const fieldsWatch = useWatch({
        control,
        name: ['name', 'fieldType']
    });
    const { field: fieldOriginal, setField } = useContext(FieldContext);

    useEffect(() => {
        if (fieldOriginal?.fieldType !== fieldsWatch[1]){
            var cloneObject = cloneDeep([merge(fieldOriginal, {'fieldType': fieldsWatch[1]})]);
            setField(cloneObject[0]);
        };
    }, [fieldsWatch[1]])

    return (
        <Fragment>
            <Stack direction="row" height="auto" marginBottom='1em'>
                <Fragment>
                    <DialogBackSchemaView schema={schema} />
                    <ArrowRightAltIcon fontSize="large" />
                </Fragment>       
                <div style={{
                    height: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius:'5px',
                    color: 'white',
                    backgroundColor: 'rgb(25, 118, 210)',
                    marginLeft: '0.25em',
                    paddingInline: '1em'
                }}>
                    {fieldsWatch[0]}
                </div>
            </Stack>
            <Stack direction="row" height="auto" marginBottom='1em' marginTop='3em'>
                <Controller
                    name={'name'}
                    control={control}
                    render={({ field }) => <TextField {...field} label={<Trans i18nKey={'fieldName'} />} variant="outlined" size='small' sx={{
                        paddingRight: '1em'
                    }}
                    {...register('name', {
                        required: {
                        value: true,
                        message: t('inputRequired').replace('%1', t('name'))
                        },
                        minLength: {
                        value: 2,
                        message: t('minValidation').replace('%1', '2')
                        },
                        maxLength: {
                        value: 256,
                        message: t('maxValidation').replace('%1', '256')
                        },
                        pattern: {
                        value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                        message: t('patternValidation')
                        }
                    })}
                    helperText={errors.name?.message}
                    error={errors.name !== undefined}
                    />
                }/>
                <Controller
                name={'fieldType'}
                control={control}
                render={({ field }) => (
                    <ControlledSelect valueList={FieldsSelectValue} {...field} size='small' label={<Trans i18nKey={'fieldType'} />} sx={{
                        width: '200px'
                        }}
                        {...register('fieldType', {
                        required: {
                            value: true,
                            message: t('inputRequired').replace('%1', t('fieldType'))
                        }
                        })}
                        helperText={errors.fieldType?.message}
                        error={errors.fieldType !== undefined} 
                        // onChange={(e: React.ChangeEvent<HTMLInputElement>) => hadleChangeType(e)}
                    />
                )}
                />
                <Controller
                name={'isMultiValue'}
                control={control}
                render={({ field: { onChange, value } }) => <FormControlLabel 
                        control={<Checkbox onChange={onChange} checked={value} />} 
                        label={<Trans i18nKey={'fieldMulti'} />}
                        sx={{paddingLeft: '1em'}}
                    />}
                />
                <Controller
                name={'defaultValue'}
                control={control}
                render={({ field }) => (<TextField {...field} 
                        label={<Trans i18nKey={'fieldDefault'} />}
                        {...register('defaultValue', {})}
                        variant="outlined" 
                        size='small'
                        sx={['blob', 'Complex'].indexOf(fieldsWatch[1]) !== -1 ? {display: 'none'} : {}}
                        />
                    )}
                />
            </Stack>
        </Fragment>)
}

function DialogBackSchemaView({ schema }:{ schema: SchemaWithFieldsProps }){
    const paramsUrl = useParams() as paramUrl;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [isModification, setIsModification] = useState(false);
    const { watch } = useFormContext<FieldProps>();

    useEffect(() => {
        watch(() => {
            setIsModification(true); 
        })
      }, []);

    const handleSwitch = () => {
        navigate(`/content/schemas/${paramsUrl.id}`, {state : { schemaFullHierarchy: schema }});
    }
    return (
        <Fragment>
            <Button sx={{
                marginRight: '0.5em',
                color: '#1976d2',
                border: '1px solid rgba(25, 118, 210, 0.5)',
                fontSize: '0.8rem',
            }}
            onClick={() => isModification ? setIsOpen(true) : handleSwitch()}
            >
                {schema.featureId}
            </Button>
            <SimpleModal title={t('discard_newUrl')} isOpen={isOpen} handleClose={() => setIsOpen(false) }>
            <Box>
                <Stack
                direction="row-reverse"
                spacing={3}
                alignItems="right"
                justifyContent="end"
                sx={{ pt: 3 }}
                >
                <Button variant="contained" onClick={handleSwitch} sx={{ width: 150 }}>
                    {t('answer_yes')}
                </Button>
                <Button variant="outlined" onClick={() => setIsOpen(false)} sx={{ width: 150 }}>
                    {t('answer_no')}
                </Button>
                </Stack>
            </Box>
            </SimpleModal>
        </Fragment>
    );

}