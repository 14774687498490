import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import useResources from "../../../serverInteraction/hooks/resources/useResources";
import { Loader } from "../loader/Loader";
import { AxiosError } from "axios";

type Props = {
  selectedUrl?: string;
  handleSelect: (id: string) => void;
};

export function SelectResourceList({ selectedUrl, handleSelect }: Props) {
  const { status, resources, isLoading, isFetching, error } = useResources();

  if (isLoading || isFetching || resources === undefined) {
    return <Loader />;
  }

  if (status === "error" && error instanceof AxiosError) {
    return (
      <Typography variant="h6" align="center">
        Error: {error.message}
      </Typography>
    );
  }

  return (
    <>
      <List disablePadding>
        {resources.map(({ id, url }) => (
          <ListItemButton
            key={id}
            selected={selectedUrl === url}
            onClick={() => {
              handleSelect(url);
            }}
          >
            <ListItemText primary={url} key={id} />
          </ListItemButton>
        ))}
      </List>
      <Box sx={{ width: "25%" }}>
        <img
          src={selectedUrl}
          srcSet={selectedUrl}
          alt={selectedUrl}
          loading="lazy"
        />
      </Box>
    </>
  );
}
