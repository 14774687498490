import React, { useContext } from "react";

const ProjectContext = React.createContext<{
        companyName: string;
        documentName: string; 
    }>({companyName: '', documentName: ''});

export const ProjectProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <ProjectContext.Provider value={{ companyName: 'company', documentName: 'document' }}>{children}</ProjectContext.Provider>
    );
};

export const useProjectContext = () => useContext(ProjectContext)