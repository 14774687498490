import {
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { TOption } from '../../../types/UI/Option';

type Props = {
  entityList: TOption[];
  loadEntities?: TOption[];
  updateParent?: (newRight: string[]) => void;
  isUserTransferList?: boolean;
  width?: string | number;
  height?: string | number;
  onSelect?: (value: string) => void
};

function not(a: any[], b: any[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: any[], b: any[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export function TransferList({ entityList, loadEntities, updateParent, width, height, isUserTransferList, onSelect }: Props) {
  const [checked, setChecked] = useState<TOption[]>([]);
  const [isFirstPass, setFirstPass] = useState<boolean>(true);
  let [left, setLeft] = useState<TOption[]>(entityList.filter((elem: TOption) => (!loadEntities?.find((e: TOption) => e.value === elem.value))));
  const [right, setRight] = useState<TOption[]>(loadEntities || []);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (option: TOption) => () => {
    const currentIndex = checked.findIndex(elem => elem.value === option.value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(option);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    
    //обработчик на родительском компоненте
    if(onSelect)
      onSelect(option.value)
  };

  useEffect(() => {
    updateParent && updateParent(right.map(elem => elem.value));
  }, [right]);

  useEffect(() => {
    if (loadEntities && isFirstPass) {
      const commonSchemas = intersection(loadEntities, entityList);

      const uniqueSchemas = commonSchemas.filter((schema) => !right.includes(schema));

      setRight([...right, ...uniqueSchemas]);

      setLeft(not(left, uniqueSchemas));

      setFirstPass(false);
    }else if(loadEntities && !isFirstPass && isUserTransferList) {
      const isRightList = intersection(loadEntities, entityList);
      setRight(isRightList);
      setLeft(not(entityList, isRightList));
    }
  }, [loadEntities]);

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    //двигать можем только !disabled
    const moveElems = right.filter((elem:TOption) => !elem.disabled);
    const stayElems = right.filter((elem:TOption) => elem.disabled);
    setLeft(left.concat(moveElems));
    setRight(stayElems);
  };

  const customList = (items: TOption[]) => (
    <Paper
      style={{
        width: width ? width : 400,
        height: height ? height : 220,
        overflow: 'auto',
      }}
    >
      <List component="div" role="list">
        <>
          {items.map((option: TOption, index) => {
            const labelId = `transfer-list-item-${option.value}-label`;
            return (
              <ListItem 
                key={index} 
                role="listitem"  
                onClick={handleToggle(option)} 
                sx={{padding: 0}}
              >
                <ListItemButton 
                  disabled={option.disabled}
                  sx={{ padding: 0}}
                >
                <ListItemIcon>
                  {!option.disabled && 
                    <Checkbox
                      checked={checked.find(elem => elem.value === option.value) ? true : false}
                      disabled = {true}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  }
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${option.label}`} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </>
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{customList(left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.filter((elem:TOption) => !elem.disabled)?.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right)}</Grid>
    </Grid>
  );
}
