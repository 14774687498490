import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { Typography, FormControl } from '@mui/material';
import { Loader } from '../../common/loader/Loader';
import { PackagesForm } from './PackagesForm';
import { ControlledInput } from '../../common/input/ControlledInput';
import usePackages from '../../../serverInteraction/hooks/packages/usePackages';
import { PackageProps } from '../../../types/packages/PackageProps';
import { useSelector } from 'react-redux';

export function PackagesView() {
  const { t } = useTranslation();
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const { status, projects, isLoading, isFetching, error } = usePackages(userPackage.company || "");
  
  //RENDERING
  if (isLoading || isFetching || projects === undefined) {
    return <Loader />;
  }

  if (status === 'error' && error instanceof AxiosError) {
    return (
      <Typography variant="h6" align="center">
        Error: {error.message}
      </Typography>
    );
  }

  const packagesList: PackageProps[] = JSON.parse(projects?.toString());

  return (
    <>
      <FormControl variant="outlined">
        <ControlledInput
          value={userPackage.company}
          disabled={true}
          type="text"
          register={{}}
          label={t('usersCompany')} 
          sx={{
            width: '300px',
          }}
        />
      </FormControl>
      <PackagesForm packages={packagesList || []}/>  
    </>
  )
}
