export const DefaultSchemasForDocument: string[] = [ 
    'blog',
    'blogpost',
    'collection',
    'collectionMember',
    'contextualLink',
    'domain',
    'files',
    'image_metadata',
    'iptc',
    'mail',
    'note',
    'picture',
    'picturebook',
    'post',
    'protocol',
    'publishing',
    'task',
    'thread',
    'video',
    'webcontainer',
    'webpage'
]