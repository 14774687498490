import { Box } from "@mui/material";
import { BrandingProps } from "../../../../types/branding/BrandingProps";
import useRemove from "../../../../serverInteraction/hooks/entity/useRemove";
import { DeleteDialog } from "../../../common/dialog/DeleteDialog";
import useAlert from "../../../../context/alertContext/useAlert";

type Props = {
  branding: BrandingProps;
  onDelete?: () => void;
};

export function BrandingDeleteDialog({ branding, onDelete }: Props) {
  const { showSuccess } = useAlert();
  const onSuccess = (result: boolean) => {
    if (result) {
      showSuccess("Deleted successfully!");
      onDelete && onDelete();
    }
  };

  const { remove } = useRemove("branding", onSuccess);
  const handleDelete = async () => {
    await remove(branding.id);
  };

  return (
    <DeleteDialog
      title={`Delete Branding ${branding.name}`}
      remove={handleDelete}
    >
      <Box>{`Are you sure to delete the feature ${branding.name}?`}</Box>
    </DeleteDialog>
  );
}
