import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { PropsWithChildren, CSSProperties } from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "768px",
  width: "50%",
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

type Props = {
  title: string;
  isOpen: boolean;
  handleClose: () => void;
} & PropsWithChildren;

export function SimpleModal({ title, isOpen, handleClose, children }: Props) {
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h5">{title}</Typography>
        <Box sx={{ pt: 3 }}>{children}</Box>
      </Box>
    </Modal>
  );
}
