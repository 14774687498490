import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import enums_en from './en/enums.json';

i18next
  .use(initReactI18next) // Для интеграции с React
  .init({
    resources: {
      // переводы для разных языков
      en: {
        translation: {
          //General
          deletedRecord: 'Record deleted',
          dataUpdated: 'Data updated',
          validation: 'Validation rules',
          validRequired: 'Required',
          validDescription: 'Field value can’t be null.',
          validationkey: 'Translation key',
          validRequieredDescr: 'If you want to customize the value displayed on validation error for this field. Use the displayed translation key.',
          validTextFormat: 'Text format',
          TextFormatDescr: 'You can apply a regular expression to validate the structure of the string value.',

          // buttons
          add: 'Add',
          save: 'Save',
          new: 'New',
          button_create: 'Create',
          button_delete:  'Delete',
          button_discard: 'Discard changes',
          discard_description: 'Are you sure you want to undo the changes?',
          discard_newUrl: 'The new value will not be saved, do you want to switch?',
          answer_yes: 'Yes',
          answer_no: 'No',
          inputRequired: '"%1" is required',
          minValidation: 'Must be at least %1 symbols',
          maxValidation: 'Maximum of %1 characters',
          patternValidation: "Only alphanumeric characters, '_' and '-' are allowed in the IDs. The ID must start with a letter.",
          minValueValidation: 'The number must be more than %1',
          maxValueValidation: 'The number must be less than %1',
          onlyIntegerNumber: 'Integers only',
          validPattern: 'Pattern',
          validListElementsRequired: 'List Elements Required',
          validListElementsRequiredDescr: 'List element values can’t be null',
          validTextLength: 'Text Length',
          validTextLengthDescr: 'You can define the accepted range of number of characters for the string value. Range values are inclusive.\n\n By default Nuxeo Platform will limit the maximum number of characters to 4000 for performance matters. You may choose another value or select the large text pattern if you need to store more information.',
          validMaximum: 'Maximum',
          validMinimum: 'Minimum',
          validNumRange: 'Numeric range',
          numRangeDescr: 'You can define the accepted range for the numeric value.',
          validInclude: 'Including',
          validMinDate: 'Minimum date',
          validMaxDate: 'Maximum date',
          validDateRange: 'Date range',
          dateRangeDescr: 'You can define accepted range for a date.',
          documentFieldType: 'Document Field Type Specific Attributes',
          documentValueStored: 'Value stored',
          docsValue_id: 'Document ID with repository prefix (default)',
          docsValue_idOnly: 'Document ID',
          docsValue_path: 'Document path with repository prefix',
          docsValue_pathOnly: 'Document path',
          docsValueDescr: `Document reference is usually stored along with the repository id in case you may want to use several of them. It is the default and recommended value, although it may require to specify the repository id in some cases when dealing with automation operations.
                          You may still choose to store a bare value in case you are absolutely sure you will not use several repositories.
                          Storing the document id rather than its path is recommended for improved efficiency. Note that once documents using this property have been created, this configuration should not be changed without migrating values at database level.`,
          listUserGroup_all: 'Both users and groups',
          listUserGroup_user: 'Only users',
          listUserGroup_group: 'Only groups',
          userGroupFieldType: 'User/Group Field Type Specific Attributes',
          userGroupFieldContains: 'Contains',
          directoryFieldType: 'Directory Field Type Specific Attributes',
          directoryFieldName: 'Directory name',
          dirSelectVocabulary: 'or a select vocabulary',
          complexSubFields: 'Sub fields',

          //header
          profile: 'Profile',

          //packages
          package: 'Package',
          packages: 'Packages',
          createPackge: 'Create packge',
          document: 'Document',

          // schemas
          titleSchemas: 'Document Schema',
          headerSchemas:
            'Schemas are metadata blocks (containing information like dates, numbers, text, files). You can add as many schemas as you want in a document type, and they can be reused for any number of document types as well. Use this screen to create additional schemas, that can be tied to your document types.',
          headerSchema: 'Define an additional schema to be tied to your document types.',
          name: 'Name',
          importedFromXsdFile: 'Imported from XSD-file',

          // scheams
          configuration: 'CONFIGURATION',
          contentModel: 'Content Model',
          resources: 'Resources',
          branding: 'Branding',
          schemas: 'Schemas',
          schema: 'Schema',
          docTypes: ' Document Types',
          facets: 'Facets',
          lifeCycles: 'Life Cycles',
          structureTemplates: 'Structure Templates',
          schemaName: 'Schema',
          schemaPrefix: 'Schema prefix',

          //fields
          fieldsConfig: 'Field configuration',
          fieldName: 'Field name',
          fieldType: 'Field type',
          fieldMulti: 'Multi-valued',
          fieldDefault: 'Default Value',
          fieldAdd: 'Add field',
          fieldAdvSett: 'Additional field settings',

          //info
          info_title1: "What's this?",
          info_title2: "How-to:",
          info_docPerms: "Permissions are used for security management. You can create new Permissions and use them for filtering user actions, managing some workflow roles, etc. Users are able to set the permission you just created on the document types you associate it to here.",
          info_docHowTo: 'You have already created the permission from the "Permissions" menu on the left. You can now define on which document types users are able to set this permission.',
          info_schemaWhat: 'This screen allows you to create independent schemas containing metadata which can be shared between several document types.',
          info_schemaHowTo: 'Complete one line per attribute, specifying data type and whether the attribute is complex.',

          //permissions
          perms_title: 'Permission',
          perms_description: 'Permissions are used for security management. You can create new Permissions and use them for filtering user actions, managing some workflow roles. You can define for which document types the permission can be set by the user in the web interface.',
          permsForDoc_description: 'Users are able to set the permissions you just created on the document types you associate it to here.',
          perms_typeDoc: 'Select a document type where the permission should be displayed.',
          perms_order: 'Order',
          
          //Users&Groups
          usersGroups_title: 'Users & Groups',
          usersGroups_descr: 'Create an initial Users & Groups structure',
          usersGroups_exposition: 'To create an initial Users & Groups structure. This generates configuration that works with the default table names. It can be used with SQL users repository, and NoSQL backends starting from Nuxeo Server LTS 2016 when the MongoDB ext addon is added to your dependencies.',
          usersGroups_notStruct: "Some features in Nuxeo products configurable with Studio are restricted to very specific groups or users. To make sure that users are not blocked by a missing group that the functional administrators haven't created, Studio enables you to configure users and groups that are automatically created by the system.",
          usersGroups_createStruct: "No Users & Groups was defined. Click on the button below to create one.",
          usersGroups_warning: 'Use this feature in development phase. You should make sure you disable it when delivering the plugin to production, otherwise you might either break your authentication system or lose all the users and groups already created and replace them with the one defined here (if the creation policy is not set to "Never").',
          usersGroups_tablePolicy: 'Table Creation Policy',
          usersGroups_tablePolicyDescr: 'Defines when the underlying database table is created.',
          usersGroups_never_descr: '"never": table is never created. Useful when a third party tool will populate it.',
          usersGroups_always_descr: '"always": table is dropped and recreated each time the Nuxeo Server instance restarts. Modifications made directly in the instance are lost in the process. Useful during development phase.',
          usersGroups_on_missing_columns_descr: '"If not existing": table is created if not existing, then only updated if additional fields need to be added in the database. Recommended for production.',
          usersGroups_never: 'Never',
          usersGroups_always: 'Always',
          usersGroups_on_missing_columns: 'If not existing',
          usersGroups_loadingPolicy: 'Data Loading Policy',
          usersGroups_legacy: 'Legacy',
          usersGroups_never_load: 'Never',
          usersGroups_update_duplicate: 'Studio wins',
          usersGroups_skip_duplicate: 'Instance wins',
          usersGroups_reject_duplicate: 'Throw an error',
          usersGroups_loadingPolicyDescr: 'Defines which entry will take priority when a conflict happens.',
          usersGroups_legacy_descr: '"Legacy": Default',
          usersGroups_never_load_descr: '"Never": Studio configuration is never used',
          usersGroups_update_duplicate_descr: '"Studio wins": Studio configuration overrides any change made in the instance',
          usersGroups_skip_duplicate_descr: '"Instance wins": Changes made in the instance are chosen over Studio configuration values',
          usersGroups_reject_duplicate_descr: '"Thrown an error": A log entry is created so you can resolve the conflict manually',
          usersGroups_users: 'Users',
          usersGroups_groups: 'Groups',
          usersName: 'Name',
          usersPassword: 'Password',
          usersFirstName: 'First Name',
          usersLastName: 'Last Name',
          usersMail: 'E-Mail',
          usersCompany: 'Company',
          groupsDescription: 'Description',
          usersPassSec: 'The password must contain at least one special character, one digit and one letter',
          userDeleteTitile: 'Are you sure you want to delete the user "%1"?',
          groupDeleteTitile: 'Are you sure you want to delete the group "%1"?',
          usersGroups_allUsers: 'All users',
          usersGroups_allGroups: 'All groups',
          usersGroups_usersInGroup: 'Group users',
          usersGroups_groupsInUser: 'User groups',

          // doctypes
          titleDoctypes: 'Document Type',
          headerDoctypes: 'A document type is a combination of schemas (which metadata are handled), a life cycle (states the document can go trough), facets (capabilities like versioning, publishing, containing other documents), etc. It is one of the first features you want to configure using Studio. Some projects require many document types.',
          headerDoctype: 'Define the main characteristics of your document type',
          configureLayoutsButton: 'Configure layouts in',
          extends: 'Extends',
          definition: 'Definition',
          documentHelperText: 'By extending a document type, you will inherit some of its configuration (metadata, facets...). If you select "Nothing", you will only have the "Dublin Core" metadata. Select "File" if the main goal of your document type is to hold a binary, and "Folder" if you want to create a custom Folder.',
          fileHelperText: 'Inherit from File if you want to design a document that aims at holding a file, with different metadata, with a different icon, lifecycle, ...',
          folderHelperText: 'Inherit from Folder if you want to create a custom Folder with some different icons, metadata, allowed content types...',
          otherAdvanced: 'Other (advanced)',
          // doctypes definitions
          lifeCycle: 'Life cycle',
        },
      },
      ru: {
        translation: {
          //General
          deletedRecord: 'Запись удалена',
          dataUpdated: 'Данные обновлены',
          validation: 'Правила валидации',
          validRequired: 'Обязательно',
          validDescription: 'Значение поля не может быть нулевым.',
          validationkey: 'Ключ записи',
          validRequieredDescr: 'Если вы хотите настроить значение, отображаемое при ошибке проверки для этого поля. Используйте отображаемый ключ.',
          validTextFormat: 'Текстовый формат',
          TextFormatDescr: 'Вы можете применить регулярное выражение для проверки структуры строкового значения.',
          validPattern: 'Шаблон',
          validListElementsRequired: 'Элементы списка обязательны',
          validListElementsRequiredDescr: 'Все элементы списка должны быть заполнены',
          validTextLength: 'Длина текста',
          validTextLengthDescr: 'Вы можете определить допустимый диапазон количества символов для строкового значения. Значения диапазона являются инклюзивными.\n\nПо умолчанию платформа Nuxeo ограничивает максимальное количество символов до 4000 из соображений производительности. Вы можете выбрать другое значение или выбрать крупный текстовый шаблон, если вам нужно сохранить больше информации.',
          validMaximum: 'Максимум',
          validMinimum: 'Минимум',
          validNumRange: 'Числовой диапозон',
          numRangeDescr: 'Вы можете определить допустимый диапазон для числового значения.',
          validInclude: 'Включая', 
          validMinDate: 'Минимальная дата',
          validMaxDate: 'Максимальная дата',
          validDateRange: 'Диапазон дат',
          dateRangeDescr: 'Вы можете определить допустимый диапазон для даты.',
          documentFieldType: 'Атрибуты поля "Документ"',
          documentValueStored: 'Хранимое значение',
          docsValue_id: 'Идентификатор документа с префиксом хранилища (по умолчанию)',
          docsValue_idOnly: 'Идентификатор документа',
          docsValue_path: 'Путь к документу с префиксом хранилища',
          docsValue_pathOnly: 'Путь к документу',
          docsValueDescr: `Ссылка на документ обычно хранится вместе с идентификатором хранилища на случай, если вы захотите использовать несколько из них. Это значение по умолчанию и рекомендуемое значение, хотя в некоторых случаях при работе с операциями автоматизации может потребоваться указать идентификатор хранилища.
                          Вы все еще можете выбрать сохранение простого значения в случае, если вы абсолютно уверены, что не будете использовать несколько репозиториев.
                          Для повышения эффективности рекомендуется сохранять идентификатор документа, а не путь к нему. Обратите внимание, что после создания документов, использующих это свойство, эту конфигурацию не следует изменять без переноса значений на уровне базы данных.`,
          listUserGroup_all: 'Пользователи и группы',
          listUserGroup_user: 'Только пользователи',
          listUserGroup_group: 'Только группы',
          userGroupFieldType: 'Атрибуты поля "пользователь/группы"',
          userGroupFieldContains: 'Содержит',
          directoryFieldType: 'Атрибута типа поля "Каталог"',
          directoryFieldName: 'Имя каталога',
          dirSelectVocabulary: 'или выберите словарь',
          complexSubFields: 'Вложенные поля',

          //buttons
          add: 'Добавить',
          save: 'Сохранить',
          new: 'Новая',
          button_create: 'Создать',
          button_delete:  'Удалить',
          button_discard: 'Отменить изменения',
          
          discard_description: 'Вы точно хотите отменить изменения?',
          discard_newUrl: 'Новые значение не будут сохранены, вы хотите выйти со страницы?',
          answer_yes: 'Да',
          answer_no: 'Нет',
          inputRequired: 'Поле "%1" обязательно',
          minValidation: 'Должно быть не менее %1 символов',
          maxValidation: 'Не более %1 символов',
          patternValidation: "В идентификаторах допускаются только буквенно-цифровые символы '_' и '-'. Идентификатор должен начинаться с буквы.",
          minValueValidation: 'Число должно быть более %1',
          maxValueValidation: 'Число должно быть менее %1',
          onlyIntegerNumber: 'Tолько целые числа',

          //header
          profile: 'Профиль пользователя',

          //packages
          package: 'Проект',
          packages: 'Проекты',
          createPackge: 'Создание проекта',
          document: 'Документ',

          //schemas
          titleSchemas: 'Схема Документа',
          headerSchemas:
            'Схемы - это блоки метаданных (содержащие такую информацию, как даты, числа, текст, файлы). Вы можете добавить столько схем, сколько захотите, в один тип документа, и они также могут быть повторно использованы для любого количества типов документов. Используйте этот экран для создания дополнительных схем, которые можно привязать к вашим типам документов.',
          headerSchema: `Определите дополнительную схему, которая будет привязана к вашим типам документов`,
          importedFromXsdFile: 'Импортирована из XSD-файла',
          configuration: 'КОНФИГУРАЦИЯ',
          contentModel: 'Модель Контента',
          resources: 'Ресурсы',
          branding: 'Маркировка',
          schemas: 'Схемы',
          schema: 'Схема',
          docTypes: 'Типы Документов',
          facets: 'Аспекты',
          lifeCycles: 'Жизненные Циклы',
          structureTemplates: 'Шаблоны Структур',
          name:'Имя',
          schemaName: 'Схема',
          schemaPrefix: 'Префикс схемы',

          //fields
          fieldsConfig: 'Настройка полей',
          fieldName: 'Имя поля',
          fieldType: 'Тип поля',
          fieldMulti: 'Множественное поле',
          fieldDefault: 'Значение по умолчанию',
          fieldAdd: 'Добавить поле',
          fieldAdvSett: 'Расширенные настройки поля',

          //info
          info_title1: "Что это?",
          info_title2: "Как:",
          info_docPerms: "Разрешения используются для управления безопасностью. Вы можете создавать новые разрешения и использовать их для фильтрации действий пользователей, управления некоторыми ролями рабочего процесса и т. д. Пользователи могут устанавливать только что созданные вами разрешения для типов документов, с которыми вы их здесь связываете.",
          info_docHowTo: 'Вы уже создали разрешение в меню "Разрешения". Теперь вы можете определить, для каких типов документов пользователи могут устанавливать эти разрешения.',
          info_schemaWhat: 'Эта страница позволяет создавать независимые схемы, содержащие метаданные, которые могут использоваться несколькими типами документов.',
          info_schemaHowTo: 'Заполните данные для каждого атрибута, указав тип данных и является ли атрибут комплексным.',
 
          //permissions
          perms_title: 'Разрешение',
          perms_description: 'Разрешения используются для управления безопасностью. Вы можете создавать новые разрешения и использовать их для фильтрации действий пользователей, управления некоторыми ролями рабочего процесса. В веб-интерфейсе вы можете определить, для каких типов документов пользователь может установить разрешение.',
          permsForDoc_description: 'Пользователи могут устанавливать только что созданные вами разрешения для типов документов, с которыми вы их связываете.',
          perms_typeDoc: 'Выберите тип документа, в котором должно отображаться разрешение.',
          perms_order: 'Порядок',

          //Users&Groups
          usersGroups_title: 'Пользователи и группы',
          usersGroups_descr: 'Создайте первоначальную структуру пользователей и групп.',
          usersGroups_exposition: 'Создать первоначальную структуру пользователей и групп. При этом создается конфигурация, которая работает с именами таблиц по умолчанию. Его можно использовать с репозиторием пользователей SQL и сервверами NoSQL, начиная с Nuxeo Server LTS 2016, когда к вашим зависимостям добавляется надстройка MongoDB ext.',
          usersGroups_notStruct: "Некоторые функции продуктов Nuxeo, настраиваемые с помощью Studio, доступны только определенным группам или пользователям. Чтобы убедиться, что пользователи не заблокированы отсутствующей группой, которую функциональные администраторы не создали, Studio позволяет вам настраивать пользователей и группы, которые автоматически создаются системой.",
          usersGroups_createStruct: "Пользователи и группы не определены. Нажмите на кнопку ниже, чтобы создать структуру.",
          usersGroups_warning: 'Используйте эту функцию на этапе разработки. Вы должны обязательно отключить его при доставке плагина в производство, иначе вы можете либо сломать свою систему аутентификации, либо потерять всех уже созданных пользователей и группы и заменить их определенными здесь (если для политики создания не установлено значение «Никогда»).',
          usersGroups_tablePolicy: 'Правила создания таблиц',
          usersGroups_tablePolicyDescr: 'Определяет, когда создается базовая таблица базы данных.',
          usersGroups_never_descr: '"никогда": таблица никогда не создается. Полезно, когда его заполняет сторонний инструмент.',
          usersGroups_always_descr: '"всегда": таблица удаляется и создается заново каждый раз при перезапуске экземпляра сервера Nuxeo. Изменения, сделанные непосредственно в экземпляре, теряются в процессе. Полезно на этапе разработки.',
          usersGroups_on_missing_columns_descr: '"если не существует": таблица создается, если не существует, то обновляется только в том случае, если в базу данных необходимо добавить дополнительные поля. Рекомендовано к производству.',
          usersGroups_never: 'Никогда',
          usersGroups_always: 'Всегда',
          usersGroups_on_missing_columns: 'Если не существует',
          usersGroups_loadingPolicy: 'Правила загрузки данных',
          usersGroups_legacy: 'Наследие',
          usersGroups_never_load: 'Никогда',
          usersGroups_update_duplicate: 'Студия выигрывает',
          usersGroups_skip_duplicate: 'Экземпляр выигрывает',
          usersGroups_reject_duplicate: 'Выдать ошибку',
          usersGroups_loadingPolicyDescr: 'Определяет, какая запись будет иметь приоритет при возникновении конфликта.',
          usersGroups_legacy_descr: '"Наследие": по умолчанию',
          usersGroups_never_load_descr: '"Никогда": конфигурация Studio никогда не используется',
          usersGroups_update_duplicate_descr: '"Студия выигрывает": конфигурация студии отменяет любые изменения, внесенные в экземпляр',
          usersGroups_skip_duplicate_descr: '"Экземпляр выигрывает": изменения, внесенные в экземпляр, выбираются вместо значений конфигурации Studio',
          usersGroups_reject_duplicate_descr: '"Выдать ошибку": создается запись в журнале, чтобы вы могли разрешить конфликт вручную',
          usersGroups_users: 'Пользователи',
          usersGroups_groups: 'Группы',
          usersName: 'Логин',
          usersPassword: 'Пароль',
          usersFirstName: 'Имя',
          usersLastName: 'Фамилия',
          usersMail: 'Электронная почта',
          usersCompany: 'Организация',
          groupsDescription: 'Описание',
          usersPassSec: 'Пароль должен содержать хотя бы один специальный символ, одну цифру и одну букву',
          userDeleteTitile: 'Вы уверены что хотите удалить пользователя "%1"?',
          groupDeleteTitile: 'Вы уверены что хотите удалить группу "%1"?',
          usersGroups_allUsers: 'Все пользователи',
          usersGroups_allGroups: 'Все группы',
          usersGroups_usersInGroup: 'Пользователи группы',
          usersGroups_groupsInUser: 'Группы пользователя',
        
          // doctypes
          titleDoctypes: 'Тип документа',
          headerDoctypes: 'Тип документа — это комбинация схем (метаданные которых обрабатываются), жизненного цикла (состояния, через которые может пройти документ), аспектов (возможности, такие как управление версиями, публикация, содержание других документов) и т. д. Это одна из первых функций, которую вы хотите настроить с помощью Studio. Некоторые проекты требуют много типов документов.',
          headerDoctype: 'Определите основные характеристики вашего типа документа',
          configureLayoutsButton: 'Настройте макеты в',
          definition: 'Определение',
          extends: 'Расширяет',
          documentHelperText: 'Расширяя тип документа, вы унаследуете часть его конфигурации (метаданные, грани...). Если вы выберете «Ничего», у вас будут только метаданные «Dublin Core». Выберите «Файл», если основная цель вашего типа документа — хранить двоичный файл, и «Папка», если вы хотите создать пользовательскую папку.',
          fileHelperText: 'Наследуйте от File, если вы хотите разработать документ, предназначенный для хранения файла с другими метаданными, другим значком, жизненным циклом, ...',
          folderHelperText: 'Наследуйте от папки, если вы хотите создать пользовательскую папку с другими значками, метаданными, разрешенными типами контента...',
          otherAdvanced: 'Ещё (расширенный)',
          // doctypes definitions
          lifeCycle: 'Жизненный цикл',
        },
      },
    },
    lng: 'eng', // Язык по умолчанию
    fallbackLng: 'en', // Язык, используемый при отсутствии перевода
    interpolation: {
      escapeValue: false, // Не экранировать HTML и строки по умолчанию
    },
  });

export default i18next;
