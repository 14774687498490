import { Box, Grid, List, ListItemButton, ListItemText, Paper, TextFieldProps } from "@mui/material";
import { Fragment, useEffect, useMemo, useState } from "react";
import { FieldErrors, useForm, UseFormRegister, UseFormTrigger } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { ButtonsContainer } from "../UsersGroups";
import { ControlledInput } from '../../../../../common/input/ControlledInput';
import PasswordInput from "../../../../../common/input/PasswordInput";
import { TransferList } from "../../../../../common/transfetList/TransferList";
import { NewUser, User } from "../../../../../../types/content/roles-and-permissions/users&groups/User";
import { Group, NewGroup } from "../../../../../../types/content/roles-and-permissions/users&groups/Group";
import useRemove from "../../../../../../serverInteraction/hooks/entity/useRemove";
import useAlert from "../../../../../../context/alertContext/useAlert";
import useCreate from "../../../../../../serverInteraction/hooks/entity/useCreate";

export type UserTabProps = {
  listUsers: Array<User | NewUser>,
  listGroups: Array<Group | NewGroup>,
  index: number,
  trigger: any,
  isCorrect: boolean,
  newGroupsCurUser: any
}

let userTab: UserTabProps = {
  listUsers: [],
  listGroups: [],
  index: 0,
  isCorrect: false,
  newGroupsCurUser: [],
  trigger: undefined
};

export function UsersContainer({users, groups}: {users: Array<User | NewUser>, groups: Array<Group | NewGroup>}){
    const [listUsers, setListUsers] = useState<Array<User | NewUser>>(users);
    
    useEffect(() => {
      userTab.listUsers = users;
      userTab.listGroups = groups;
      userTab.index = 0;
    }, [])

    const { t } = useTranslation();
    const { showSuccess } = useAlert();
    const { remove } = useRemove('User');
    const { create } = useCreate('saveUser');

    const newListUserGroup = (newList: any) => {
      newGroupsCurUser = newList;
      userTab.newGroupsCurUser = newList;
    };

    const {register, trigger, formState: { errors }} = useForm<User| NewUser>();
    userTab.trigger = trigger;
    
    const listNameUsers: Array<string> = listUsers.map((item: User | NewUser) => item.name);

    const udpateUsersInGroups = async (user: User | NewUser, listGroupsId: Array<string | null>): Promise<void> => {
      for(let i: number = 0; i < groups.length; i++){
        let indexUser: number = groups[i].listUsers.indexOf(Number(user.id));
        let isUserInGroup: boolean = listGroupsId.indexOf(groups[i].id?.toString() || null) === -1;
        if(indexUser !== -1 && isUserInGroup){
          groups[i].listUsers.splice(indexUser, 1);
        }else if(indexUser === -1 && !isUserInGroup){
          groups[i].listUsers.push(Number(user.id));
        };
      };
    };

    const [selectedIndex, setSelectedIndex] = useState<number>(users.length === 0 ? -1 : 0);
    const handleListItemClick = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
      if(await trigger()){
        let listGroupsId: Array<string | null> = [];
        const values: User | NewUser ={
          id: listUsers[selectedIndex].id,
          name: (document.getElementById("userName") as HTMLInputElement).value,
          firstName: (document.getElementById("userFirstName") as HTMLInputElement).value,
          lastName: (document.getElementById("userLastName") as HTMLInputElement).value,
          mail: (document.getElementById("userMail") as HTMLInputElement).value,
          company: (document.getElementById("userCompany") as HTMLInputElement).value,
          password: (document.getElementById("userPassword") as HTMLInputElement).value,
          groups:  groups.length === 0 ? [] : newGroupsCurUser.map((item: string) => {
                    let index: number = allGroups.indexOf(item);
                    listGroupsId.push(groups[index].id?.toString() || null);
                    return groups[index];
                  }),
          roles: []
        };
        let newList: Array<User | NewUser> = listUsers;
        udpateUsersInGroups(values, listGroupsId);
        newList[selectedIndex] = values;
        userTab.listGroups = groups;
        userTab.listUsers = newList;
        userTab.index = index;
        setSelectedIndex(index);
        setListUsers(newList);
      }
    };

    const allGroups: Array<string> = groups.map((item: Group | NewGroup)=> item.name);
    const userGroups: Array<string> = useMemo(() => {
      return listUsers.length !== 0 ? listUsers[selectedIndex].groups.map((item: Group | NewGroup) => item.name) : [];
    }, [selectedIndex]);
    let newGroupsCurUser: Array<string> = userGroups;


    const onAddedUser = async () => {
      if(await trigger()){
        const newUser: User = {
          id: 0,
          name: "NewUser",
          firstName: '',
          lastName: '',
          mail: '',
          company: '',
          password: '',
          groups: [],
          roles: []
        }
        let listGroupsId: Array<string | null> = [];
        const savedUser: User = JSON.parse(`${await create(newUser)}`);
        let list: Array<User | NewUser> = [...listUsers, savedUser];
        
        if (listUsers.length !== 0) {
          const values: User | NewUser ={
            id: listUsers[selectedIndex].id,
            name: (document.getElementById("userName") as HTMLInputElement).value,
            firstName: (document.getElementById("userFirstName") as HTMLInputElement).value,
            lastName: (document.getElementById("userLastName") as HTMLInputElement).value,
            mail: (document.getElementById("userMail") as HTMLInputElement).value,
            company: (document.getElementById("userCompany") as HTMLInputElement).value,
            password: (document.getElementById("userPassword") as HTMLInputElement).value,
            groups:  newGroupsCurUser.map((item: string) => {
                      let index: number = allGroups.indexOf(item);
                      listGroupsId.push(groups[index].id?.toString() || null);
                      return groups[index];
                    }),
            roles: []
          };
          list[selectedIndex] = values;
          await udpateUsersInGroups(values, listGroupsId);
        }

        const index: number = [...listUsers, savedUser].length - 1;
        userTab.listUsers = list;
        userTab.index = index;
        setListUsers(list);
        setSelectedIndex(index);
      };
    }

    const onDeleteUser = async () => {
      await remove(listUsers[selectedIndex].id?.toString());
      
      let newIndex: number = selectedIndex === 0 ? listNameUsers.length - 2 : selectedIndex - 1;
      let newList: Array<User | NewUser> = listUsers.filter((item, index) => index !== selectedIndex);
      for(let i: number = 0; i < newList.length; i++){
        for(let j: number = 0; j < newList[i].groups.length; j++){
          let index: number = newList[i].groups[j].listUsers.indexOf(Number(listUsers[selectedIndex].id));
          if(index !== -1){
            newList[i].groups[j].listUsers.splice(index, 1);
            break;
          };
        };
      };
      userTab.listGroups = groups;

      groups = groups.map((item: Group | NewGroup)=>{
        const i: number = item.listUsers.indexOf(Number(listUsers[selectedIndex].id));
        if (i !== -1) item.listUsers.splice(i, 1);
        return item;
      });

      userTab.listUsers = newList;
      userTab.index = newIndex;
      showSuccess(t('deletedRecord'));
      setSelectedIndex(newIndex);
      setListUsers(newList);
    };

    return (
      <Grid container 
          sx={{
              marginBottom: 1,
              marginTop: 1,
          }}
      >
        <Grid item xs={12} sx={{
          border: '1px solid #e7e7ee',
          borderRadius: '3px' 
        }}>
          <ButtonsContainer onAdded={onAddedUser} onDelete={onDeleteUser} title={t('userDeleteTitile').replace('%1', selectedIndex === -1 ? '' : listUsers[selectedIndex].name)} />
        </Grid>
       {selectedIndex === -1 ? null :  
       <Fragment>
        <Grid item
            xs={2}
            sx={{
              marginTop: 1,
              height: 'auto'
            }}
          >
            <Paper
              style={{
                overflow: 'auto',
                height: '95%'
              }}
            >
              <List component="nav" aria-label="Users">
                {listNameUsers.map((item: string, index: number) => (
                  <ListItemButton key={index} selected={selectedIndex === index} onClick={(event) => handleListItemClick(event, index)}>
                    <ListItemText primary={item} key={index} />
                  </ListItemButton>
                ))}
              </List>
            </Paper>
          </Grid><Grid item
            xs={5}
            sx={{
              marginTop: 1,
              height: 'auto'
            }}
          >
              {listUsers.length !== 0 ? <InputsUsers index={selectedIndex} register={register} errors={errors} listUsers={listUsers} /> : null}
            </Grid><Grid item
              xs={5}
              sx={{
                marginTop: 1,
                height: 'auto'
              }}
            >
              <div style={{ display: 'inline-flex', width: '100%' }}>
                <div style={{ width: '50%' }}>
                  <b style={{ marginLeft: '20%' }}><Trans i18nKey={'usersGroups_allGroups'} /></b>
                </div>
                <div style={{ width: '50%' }}>
                  <b style={{ marginLeft: '20%' }}><Trans i18nKey={'usersGroups_groupsInUser'} /></b>
                </div>
              </div>
              <div style={{ marginLeft: '1.25em' }}>
                {listUsers.length !== 0 ? <TransferList entityList={allGroups} width={window.screen.width < 2000 ? 175 : 240} loadEntities={userGroups} isUserTransferList={true} updateParent={newListUserGroup} /> : null}
              </div>
            </Grid>
          </Fragment>
        }
      </Grid>
    )
  }

  type FormUsers = {
    index: number, 
    register: UseFormRegister<User | NewUser>, 
    errors: FieldErrors<User | NewUser>,
    listUsers: Array<User | NewUser>
  }

function InputsUsers({index, register, errors, listUsers}: FormUsers){
  const { t } = useTranslation();
    return (
      <Box sx={{
          marginLeft: 2,
          width: '100%',
          height: '100%'
      }}>
        <InputUser 
          type="text"
          id='userName'
          variant='outlined'
          currentValue={listUsers[index].name}
          label={<Trans i18nKey={'usersName'}/>}
          selectedIndex={index}
          inputProps={{ 
              style: { 
              padding: "0.5rem 1rem "
              },
              maxLength: 50
          }} 
          sx={{
              width: '100%'
          }}
          size='small'
          register={{
              ...register('name', {
              required: {
                value: true,
                message: 'Name is required',
              },
              minLength: {
                value: 3,
                message: 'Must be at least 3 symbols',
              },
              pattern:{
                value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                message: "Only alphanumeric characters, '_' and '-' are allowed in the IDs. The ID must start with a letter.",
              }}),
          }}
          helperText={errors.name?.message}
          error={errors.name !== undefined}
        />
        <InputUser
          id='userPassword'
          label={<Trans i18nKey={'usersPassword'}/>}
          variant='outlined'
          currentValue={listUsers[index].password}
          selectedIndex={index}
          isPassword={true}
          inputProps={{ 
              style: { 
              padding: "0.5rem 1rem "
              },
              maxLength: 16
          }}
          sx={{
              width: '100%',
              marginTop: 2
          }}
          size='small'
          register={{
              ...register('password', {
              required: {
                  value: true,
                  message: 'Password is required',
              },
              minLength: {
                  value: 8,
                  message: 'Password be at least 8 symbols',
              },
              pattern:{
                value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                message: t('usersPassSec'),
              }}),
          }}
          helperText={errors.password?.message}
          error={errors.password !== undefined}
        />
        <InputUser 
          type="text"
          id='userFirstName'
          variant='outlined'
          label={<Trans i18nKey={'usersFirstName'}/>}
          currentValue={listUsers[index].firstName}
          selectedIndex={index}
          inputProps={{ 
              style: { 
              padding: "0.5rem 1rem "
              },
              maxLength: 30
          }} 
          sx={{
              width: '100%',
              marginTop: 2
          }}
          size='small'
          register={{
              ...register('firstName', {}),
          }}
          helperText={errors.firstName?.message}
          error={errors.firstName !== undefined}
        />
        <InputUser 
          type="text"
          id='userLastName'
          variant='outlined'
          label={<Trans i18nKey={'usersLastName'}/>}
          currentValue={listUsers[index].lastName}
          selectedIndex={index}
          inputProps={{ 
              style: { 
              padding: "0.5rem 1rem "
              },
              maxLength: 30
          }} 
          sx={{
              width: '100%',
              marginTop: 2
          }}
          size='small'
          register={{
              ...register('lastName', {}),
          }}
          helperText={errors.lastName?.message}
          error={errors.lastName !== undefined}
        />
        <InputUser 
          type="text"
          id='userMail'
          variant='outlined'
          label={<Trans i18nKey={'usersMail'}/>}
          currentValue={listUsers[index].mail}
          selectedIndex={index}
          inputProps={{ 
              style: { 
              padding: "0.5rem 1rem "
              },
              maxLength: 50
          }} 
          sx={{
              width: '100%',
              marginTop: 2
          }}
          size='small'
          register={{
              ...register('mail', {}),
          }}
          helperText={errors.mail?.message}
          error={errors.mail !== undefined}
        />
        <InputUser 
          type="text"
          id='userCompany'
          variant='outlined'
          label={<Trans i18nKey={'usersCompany'}/>}
          currentValue={listUsers[index].company}
          selectedIndex={index}
          inputProps={{ 
              style: { 
              padding: "0.5rem 1rem "
              },
              maxLength: 50
          }} 
          sx={{
              width: '100%',
              marginTop: 2
          }}
          size='small'
          register={{
              ...register('company', {}),
          }}
          helperText={errors.company?.message}
          error={errors.company !== undefined}
        />
      </Box>
    )
}

type InputUser = {
    register: any;
    currentValue: string | null;
    isPassword?: boolean;
    selectedIndex: number;
  } & TextFieldProps;

function InputUser({ register, currentValue, isPassword, selectedIndex, ...textFieldProps }: InputUser){
  const [value, setValue] = useState<string | null>(currentValue);
  useEffect(() => {
    setValue(currentValue);
  }, [selectedIndex])
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };
  if (isPassword){
    return(<PasswordInput register={register} {...textFieldProps} value={value} onChange={onChange}/>);
  }else{
    return(<ControlledInput register={register} {...textFieldProps} value={value} onChange={onChange}/>);
  };
};

export const ChangeUserTab = async (): Promise<UserTabProps> => {
  let groups: Array<Group | NewGroup> = userTab.listGroups;
  const udpateUsersInGroups = (user: User | NewUser, listGroupsId: Array<string | null>): void => {
    for(let i: number = 0; i < groups.length; i++){
      let indexUser: number = groups[i].listUsers.indexOf(Number(user.id));
      let isUserInGroup: boolean = listGroupsId.indexOf(groups[i].id?.toString() || null) === -1;
      if(indexUser !== -1 && isUserInGroup){
        groups[i].listUsers.splice(indexUser, 1);
      }else if(indexUser === -1 && !isUserInGroup){
        groups[i].listUsers.push(Number(user.id));
      };
    };
    userTab.listGroups = groups;
  }; 

  try {
    let allGroups = groups.map((item: Group | NewGroup)=> item.name);

    userTab.isCorrect = userTab.trigger && await userTab.trigger();
    if(userTab.isCorrect && userTab.listUsers.length !== 0){
      let listGroupsId: Array<string | null> = [];
      const values: User | NewUser ={
        id: userTab.listUsers[userTab.index].id,
        name: (document.getElementById("userName") as HTMLInputElement).value,
        firstName: (document.getElementById("userFirstName") as HTMLInputElement).value,
        lastName: (document.getElementById("userLastName") as HTMLInputElement).value,
        mail: (document.getElementById("userMail") as HTMLInputElement).value,
        company: (document.getElementById("userCompany") as HTMLInputElement).value,
        password: (document.getElementById("userPassword") as HTMLInputElement).value,
        groups:  groups.length === 0 ? [] : userTab.newGroupsCurUser.map((item: string) => {
                  let index: number = allGroups.indexOf(item);
                  listGroupsId.push(groups[index].id?.toString() || null);
                  return groups[index];
                }),
        roles: []
      };
      userTab.listUsers[userTab.index] = values;
      await udpateUsersInGroups(values, listGroupsId);
    }

    return userTab;
  } catch(error) {
    throw('error');
  }
}
