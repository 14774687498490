import { Box } from '@mui/material';
import useRemove from '../../../../../serverInteraction/hooks/entity/useRemove';
import { DeleteDialog } from '../../../../common/dialog/DeleteDialog';
import useAlert from '../../../../../context/alertContext/useAlert';
import { FacetProps } from '../../../../../types/content/facets/FacetProps';

type Props = {
  facet: FacetProps;
  onDelete?: () => void;
};

export function FacetsDeleteDialog({ facet, onDelete }: Props) {
  const { showSuccess } = useAlert();
  const onSuccess = (result: boolean) => {
    if (result) {
      showSuccess('Deleted successfully!');
      onDelete && onDelete();
    }
  };

  const { remove } = useRemove('remove_facet', onSuccess);
  const handleDelete = async () => {
    await remove(facet.facetId);
  };

  return (
    <DeleteDialog title={`Delete Facet ${facet.facetId}`} remove={handleDelete}>
      <Box>{`Are you sure to delete the feature ${facet.facetId}?`}</Box>
    </DeleteDialog>
  );
}
