import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  title: string | any;
  toolbar?: ReactNode;
};

export function PageHeader({ title, toolbar }: Props) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ display: 'flex', py: 2, px: 3 }}
    >
      <Typography variant="h6">{title}</Typography>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="right"
        spacing={2}
        sx={{ height: '2rem' }}
      >
        {toolbar}
      </Stack>
    </Stack>
  );
}
