import { Stack, Typography } from "@mui/material";
import { Trans } from "react-i18next";
import { labelFieldValid } from "../../../../../../../staticValues/Constants";
import IconMoreInfo from "../../../../../../common/button/iconButton/MoreInfo";

type LabelProps = {
    schema: string;
    field: string
}

//isRequired
export function Inforequired(){
    return (
        <Typography>
            <Trans i18nKey={'validRequired'} />
            <IconMoreInfo title={<Trans i18nKey={'validDescription'} />} style={{
                marginLeft: '0.5em'
            }}
            placement={'right'}
            />
        </Typography>
    );
}

export function LabelRequired({schema, field}: LabelProps){
    return(
        <Stack direction='row' sx={{
            marginLeft: '3em',
            alignItems: 'center'
        }}>
            <Trans i18nKey={'validationkey'} />:
            <div style={{
                marginLeft: '1em',
                fontWeight: 'bold'
            }}>
            {`${labelFieldValid}.NotNullConstraint.${schema}.${field}`}
            <IconMoreInfo title={<Trans i18nKey={'validRequieredDescr'}/>} style={{
                marginLeft: '0.5em'
            }}
            placement={'right'}
            />
            </div>
        </Stack>
    )
};

//isListRequired
export function InfoListRequired(){
    return (
        <Typography>
            <Trans i18nKey={'validListElementsRequired'} />
            <IconMoreInfo title={<Trans i18nKey={'validListElementsRequiredDescr'} />} style={{
                marginLeft: '0.5em'
            }}
            placement={'right'}
            />
        </Typography>
    );
}

//isTextFormat
export function TextFormatCheckbox(){
    return (
        <Typography>
            <Trans i18nKey={'validTextFormat'} />
            <IconMoreInfo title={<Trans i18nKey={'TextFormatDescr'} />} style={{
                marginLeft: '0.5em'
            }}
            placement={'right'}
            />
        </Typography>
    );
}

export function LabelTextFormat({schema, field}: LabelProps){
    return(
        <Stack direction='row' sx={{
            alignItems: 'center'
        }}>
            <Trans i18nKey={'validationkey'} />:
            <div style={{
                marginLeft: '1em',
                fontWeight: 'bold'
            }}>
            {`${labelFieldValid}.PatternConstraint.${schema}.${field}`}
            <IconMoreInfo title={<Trans i18nKey={'validRequieredDescr'}/>} style={{
                marginLeft: '0.5em'
            }} placement={'right'} />
            </div>
        </Stack>
    )
};

//isLengthText
export function TextLengthCheckbox(){
    return (
        <Typography>
            <Trans i18nKey={'validTextLength'} />
            <IconMoreInfo title={<Trans i18nKey={'validTextLengthDescr'} />} style={{
                marginLeft: '0.5em'
            }}
            />
        </Typography>
    );
}

export function LabelTextLength({schema, field}: LabelProps){
    return(
        <Stack direction='row' sx={{
            marginLeft: '3em',
            marginTop: '1em',
            alignItems: 'center'
        }}>
            <Trans i18nKey={'validationkey'} />:
            <div style={{
                marginLeft: '1em',
                fontWeight: 'bold'
            }}>
            {`${labelFieldValid}.LengthConstraint.${schema}.${field}`}
            <IconMoreInfo title={<Trans i18nKey={'validRequieredDescr'}/>} style={{
                marginLeft: '0.5em'
            }}
            placement={'right'}
            />
            </div>
        </Stack>
    )
};

//isRangeText
export function TextRangeCheckbox(){
    return (
        <Typography>
            <Trans i18nKey={'validNumRange'} />
            <IconMoreInfo title={<Trans i18nKey={'numRangeDescr'} />} style={{
                marginLeft: '0.5em'
            }}
            placement={'right'}
            />
        </Typography>
    );
}

export function LabelTextRange({schema, field}: LabelProps){
    return(
        <Stack direction='row' sx={{
            marginLeft: '3em',
            marginTop: '1em',
            alignItems: 'center'
        }}>
            <Trans i18nKey={'validationkey'} />:
            <div style={{
                marginLeft: '1em',
                fontWeight: 'bold'
            }}>
            {`${labelFieldValid}.NumericIntervalConstraint.${schema}.${field}`}
            <IconMoreInfo title={<Trans i18nKey={'validRequieredDescr'}/>} style={{
                marginLeft: '0.5em'
            }}
            placement={'right'}
            />
            </div>
        </Stack>
    )
};

//isDateInterval
export function TextDateIntervalCheckbox(){
    return (
        <Typography>
            <Trans i18nKey={'validDateRange'} />
            <IconMoreInfo title={<Trans i18nKey={'dateRangeDescr'} />} style={{
                marginLeft: '0.5em'
            }}
            placement={'right'}
            />
        </Typography>
    );
}

export function LabelTextDateInterval({schema, field}: LabelProps){
    return(
        <Stack direction='row' sx={{
            marginLeft: '3em',
            marginTop: '1em',
            alignItems: 'center'
        }}>
            <Trans i18nKey={'validationkey'} />:
            <div style={{
                marginLeft: '1em',
                fontWeight: 'bold'
            }}>
            {`${labelFieldValid}.DateIntervalConstraint.${schema}.${field}`}
            <IconMoreInfo title={<Trans i18nKey={'validRequieredDescr'}/>} style={{
                marginLeft: '0.5em'
            }}
            placement={'right'}
            />
            </div>
        </Stack>
    )
};

//isDocValueStored
export function LabelDocValueStored({schema, field}: LabelProps){
    return(
        <Stack direction='row' sx={{
            marginLeft: '3em',
            marginTop: '1em',
            alignItems: 'center'
        }}>
            <Trans i18nKey={'validationkey'} />:
            <div style={{
                marginLeft: '1em',
                fontWeight: 'bold'
            }}>
            {`${labelFieldValid}.documentResolver.${schema}.${field}`}
            <IconMoreInfo title={<Trans i18nKey={'validRequieredDescr'}/>} style={{
                marginLeft: '0.5em'
            }}
            placement={'right'}
            />
            </div>
        </Stack>
    )
};

//isUserGroupValueContains
export function LabelUserGroupContains({schema, field}: LabelProps){
    return(
        <Stack direction='row' sx={{
            marginLeft: '3em',
            marginTop: '1em',
            alignItems: 'center'
        }}>
            <Trans i18nKey={'validationkey'} />:
            <div style={{
                marginLeft: '1em',
                fontWeight: 'bold'
            }}>
            {`${labelFieldValid}.userManagerResolver.${schema}.${field}`}
            <IconMoreInfo title={<Trans i18nKey={'validRequieredDescr'}/>} style={{
                marginLeft: '0.5em'
            }}
            placement={'right'}
            />
            </div>
        </Stack>
    )
};

//isLabelDirectoryField
export function LabelDirectoryField({schema, field}: LabelProps){
    return(
        <Stack direction='row' sx={{
            marginLeft: '3em',
            marginTop: '1em',
            alignItems: 'center'
        }}>
            <Trans i18nKey={'validationkey'} />:
            <div style={{
                marginLeft: '1em',
                fontWeight: 'bold'
            }}>
            {`${labelFieldValid}.directoryResolver.${schema}.${field}`}
            <IconMoreInfo title={<Trans i18nKey={'validRequieredDescr'}/>} style={{
                marginLeft: '0.5em'
            }}
            placement={'right'}
            />
            </div>
        </Stack>
    )
};