import { Box, Checkbox, FormControl, Stack, Switch, TextField} from "@mui/material";
import { Fragment, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Controller, FormProvider, useForm, useFormContext, useWatch} from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useAlert from "../../../../../../context/alertContext/useAlert";
import useUpdate from "../../../../../../serverInteraction/hooks/entity/useUpdate";
import { arrayPatternValue, PatternLists } from "../../../../../../staticValues/Constants";
import { UpdateFieldProps } from "../../../../../../types/content/schemas/Fields/FieldProps";
import { FieldProps } from "../../../../../../types/content/schemas/Fields/NewFieldProp";
import { ControlledSelect } from "../../../../../common/select/ControlledSelect";
import { advancedSettingsContext, ButtonsOnSubmitAdvancedField, FieldContext } from "../AdvancedFieldSetting";
import ButtonsFieldView from "./impl/ButtonsView";
import CommonFieldView from "./impl/CommonFieldView";
import { Inforequired, LabelRequired, LabelTextFormat, LabelTextLength, TextFormatCheckbox, TextLengthCheckbox } from "./impl/ValidationLabels";
import ListRequiredView from "./impl/validationViews/ListRequiredView";
import RequiredView from "./impl/validationViews/RequiredView";
import TextFormatView from "./impl/validationViews/TextFormatView";
import TextLengthView from "./impl/validationViews/TextLengthView";

const defaultConstraints: Array<any> = [
    {
        mandatoryConstraint: {
            value: false
        }
    },
    {
        listElementsMandatoryConstraint: {
            value: false
        }
    },
    {
        textFormatConstraint: {
            pattern: null
        }
    },
    {
        textLengthConstraint: {
            minimum: null,
            maximum: null,
        }
    }
];

export default function StringFieldForm(){
    const field = useContext(FieldContext).field as FieldProps;

    const constraints = defaultConstraints.map((item: any) => {
            const constraint = field.constraints.filter((constraint: any) => {
                return Object.keys(item)[0] === Object.keys(constraint)[0];
            });
            return constraint.length > 0 ? constraint[0] : item;
        });
    
    const isFeatureAdvanced: boolean = constraints[0].mandatoryConstraint.value || constraints[1].listElementsMandatoryConstraint.value ||
            constraints[2].textFormatConstraint.pattern !== null || constraints[3].textLengthConstraint.minimum !== null || constraints[3].textLengthConstraint.maximum !== null;

    const formMethods = useForm<FieldProps>({
        defaultValues: {
            id: field.id,
            name: field.name,
            fieldType: field.fieldType,
            defaultValue: field.defaultValue,
            isMultiValue: field.isMultiValue,
            constraints: field.fieldType === 'STRING' ? constraints : defaultConstraints,
            referenceConfiguration: null,
            subFields: null
        }
    });

    return(
        <FormProvider {...formMethods}>
            <CommonFieldView />
            <FormAdvancedString isFeatureAdvanced={isFeatureAdvanced} />
        </FormProvider>
    );
}

function FormAdvancedString({isFeatureAdvanced}: {isFeatureAdvanced: boolean}){
    const [isWatchValidation, setWatchValidation] = useState(isFeatureAdvanced);
    const { setValue } = useFormContext<FieldProps>();
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (!isWatchValidation && !isFirstRender.current) setValue('constraints', defaultConstraints);
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        };
    }, [isWatchValidation]);

    return (
        <Fragment >
            <Stack display='flex' direction='row'>
                <Switch checked={isWatchValidation} color="info" onChange={() => setWatchValidation(!isWatchValidation)} />
                <FormControl sx={{
                    justifyContent: 'center',
                    fontSize: 'large'
                }}>
                    <Trans i18nKey={'validation'}/>
                </FormControl>
            </Stack>
            {isWatchValidation && 
                <Box sx={{marginTop: '1em'}}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <RequiredView index={0} />
                        <ListRequiredView index={1} />
                        <TextFormatView index={2} />
                        <TextLengthView index={3} />
                    </Box>
                </Box>
            }
            <ButtonsFieldView />
        </Fragment>
    );
}
