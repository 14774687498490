import { Button, ButtonProps } from "@mui/material";
import { PropsWithChildren } from "react";
import AddIcon from "@mui/icons-material/Add";

type Props = ButtonProps & PropsWithChildren;

export function AddButton({ children, ...props }: Props) {
  return (
    <Button startIcon={<AddIcon />} {...props}>
      {children ? children : "New"}
    </Button>
  );
}
