import { useSelector } from 'react-redux';
import { GetDocTypesProps } from '../../../../types/content/documentTypes/GetDocTypesProps';
import useGet from '../../entity/useGet';
import { PackageProps } from '../../../../types/packages/PackageProps';
import { DOCTYPES_ENDPOINT } from '../../../services/endpointsConstants';

const useDocType = (id: string) => {
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  
  const params = `company=${userPackage.company}&document=${userPackage.document}&id=${id}`;

  const { status, data, error, isLoading, isFetching, isRefetching, refetch } =
    useGet<GetDocTypesProps>(DOCTYPES_ENDPOINT, params);

  return {
    status,
    documentType: data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default useDocType;
