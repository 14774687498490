import { Box } from '@mui/material';
import useRemove from '../../../../../../../serverInteraction/hooks/entity/useRemove';
import { DeleteDialog } from '../../../../../../common/dialog/DeleteDialog';
import useAlert from '../../../../../../../context/alertContext/useAlert';
import { Permission } from '../../../../../../../types/content/roles-and-permissions/permissions/Permission';

type Props = {
    Perms: Permission;
    onDelete?: () => void;
  };


export function DeleteDialogPerms({ Perms, onDelete }: Props) {
    const { showSuccess } = useAlert();
    const onSuccess = (result: boolean) => {
        if (result) {
            showSuccess('Deleted successfully!');
            onDelete && onDelete();
        }
    };

    const { remove } = useRemove('Perms', onSuccess);
    const handleDelete = async () => {
        await remove(Perms.id);
    };

    return (
        <DeleteDialog title={`Delete Permission ${Perms.featureId}`} remove={handleDelete}>
        <Box>{`Are you sure to delete the Permission ${Perms.featureId}?`}</Box>
        </DeleteDialog>
    );
}