import { Paper, Stack } from '@mui/material';
import { Page } from '../../../common/page/Page';
import { PageContent } from '../../../common/page/PageContent';
import { PageHeader } from '../../../common/page/PageHeader';
import { SchemasView } from './SchemasPageList';
import { Trans } from 'react-i18next';

export function SchemasPage() {
  
  return (
    <Page>
      <PageHeader title="Document Schema" />
      <PageContent subtitle={<Trans i18nKey={'headerSchemas'} />}>
        <Paper elevation={2} square sx={{ p: 2 }}>
          <Stack spacing={3}>
            <SchemasView />
          </Stack>
        </Paper>
      </PageContent>
    </Page>
  );
}
