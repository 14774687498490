import { Paper, Stack } from '@mui/material';
import { Page } from '../../../common/page/Page';
import { PageContent } from '../../../common/page/PageContent';
import { PageHeader } from '../../../common/page/PageHeader';
import { FacetsCreateDialog } from './create/FacetsCreateDialog';
import { FacetsPageList } from './FacetsPageList';


export function FacetsPage() {
  return (
    <Page>
      <PageHeader title="Facet" />
      <PageContent subtitle="A facet is a yes/no marker that can be added to a document type. Facets attached to document types identify a certain characteristic and can be used in a range of Nuxeo processes, such as automation, searches and workflows. A facet can be created in this section. Note that it is possible for a facet to be a simple binary marker, or to add a schema when active.">
        <Paper elevation={2} square sx={{ p: 2 }}>
          <Stack spacing={3}></Stack>
          <FacetsCreateDialog/>
          <FacetsPageList/>
        </Paper>
      </PageContent>
    </Page>
  );
}
