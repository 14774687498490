import { ResourceProps } from "../../../types/resources/ResourceProps";
import useGetAll from "../entity/useGetAll";

const useResources = () => {
  const entityName = "resources";

  const { status, data, error, isLoading, isFetching, isRefetching, refetch } =
    useGetAll<ResourceProps>(entityName);

  return {
    status,
    resources: data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default useResources;
