import { useTranslation } from 'react-i18next';
import { Paper, Stack } from '@mui/material';
import { Page } from '../../common/page/Page';
import { PageContent } from '../../common/page/PageContent';
import { PageHeader } from '../../common/page/PageHeader';
import { PackagesView } from './PackagesPageList';

export function PackagesPage() {
  const {t} = useTranslation();
  
  return (
    <Page>
      <PageHeader title={t('packages')} />
      <PageContent subtitle=''>
        <Paper elevation={2} square sx={{ p: 2 }}>
          <Stack spacing={3}>
            <PackagesView />
          </Stack>
        </Paper>
      </PageContent>
    </Page>
  );
}
