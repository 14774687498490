import { useQuery } from "@tanstack/react-query";
import { BaseEntityProps } from "../../../types/BaseEntityProps";
import EntityService from "../../services/EntityInteractionService";

const useGet = <T extends BaseEntityProps>(entityName: string, id: string) => {
  const { status, data, error, isLoading, isFetching, isRefetching, refetch } =
    useQuery([entityName, id], async (): Promise<T> => {
      return await EntityService.get(entityName, id);
    });

  return {
    status,
    data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default useGet;
