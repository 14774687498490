import { Box } from '@mui/material';
import useRemove from '../../../../../serverInteraction/hooks/entity/useRemove';
import { DeleteDialog } from '../../../../common/dialog/DeleteDialog';
import useAlert from '../../../../../context/alertContext/useAlert';
import { SchemaProps } from '../../../../../types/content/schemas/SchemaProps';

type Props = {
  schema: SchemaProps;
  onDelete?: () => void;
};

export function SchemasDeleteDialog({ schema, onDelete }: Props) {
  const { showSuccess } = useAlert();
  const onSuccess = (result: boolean) => {
    showSuccess('Deleted successfully!');
    onDelete && onDelete();
  };

  const schemaParams = `featureId=${schema.featureId}&project=${schema.companyName}-${schema.documentName}`

  const { remove } = useRemove('schemas', onSuccess);
  const handleDelete = async () => {
    await remove(schemaParams);
  };

  return (
    <DeleteDialog title={`Delete Schema ${schema.featureId}`} remove={handleDelete}>
      <Box>{`Are you sure to delete the feature ${schema.featureId}?`}</Box>
    </DeleteDialog>
  );
}
