import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { ColorPicker } from "./ColorPicker";

type Props<T extends FieldValues> = {
  control?: Control<T>;
  name: Path<T>;
};

export function ControlledColorPicker<T extends FieldValues>({
  control,
  name,
}: Props<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <ColorPicker value={value} handleChange={onChange} />
      )}
    />
  );
}
