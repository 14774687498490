import { Box, Button, Checkbox, FormControl, FormControlLabel, Stack, Switch, TextField } from "@mui/material";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ControlledSelect } from "../../../../common/select/ControlledSelect";
import { FieldsSelectValue } from "../../../../../staticValues/Constants";
import { useState } from "react";
import RequiredView from "./impl/validationViews/RequiredView";
import ListRequiredView from "./impl/validationViews/ListRequiredView";
import TextFormatView from "./impl/validationViews/TextFormatView";
import TextLengthView from "./impl/validationViews/TextLengthView";

interface IProps {
    index: number,
    control: any,
    register: any,
    errors: any,
    setValue: any,
    getValues: any,
    setSearchParams: any
}

const SchemaFieldExtension = ({ index, control, register, errors, setValue, getValues,setSearchParams }:IProps) => {
    const { t } = useTranslation();
    const [isWatchValidation, setWatchValidation] = useState(true);
    
    const handleSchemeButtonClick = (event: any) => {
        setSearchParams({tab: '1', ext: 'false'});
    }

    const hadleChangeType = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        setValue(`fields.${index}.fieldType`, event.target.value);
        if (['BLOB', 'COMPLEX'].indexOf(event.target.value) !== -1) {
          setValue(`fields.${index}.defaultValue`, '');
        }
      };

    return (
        <>
            <Box sx={{display: "flex", mb: '20px'}}>
                <Button 
                    sx={{
                        marginRight: '0.5em',
                        color: '#1976d2',
                        border: '1px solid rgba(25, 118, 210, 0.5)',
                        fontSize: '0.8rem',
                    }}
                    onClick = {handleSchemeButtonClick}
                >
                 {getValues('customSchema')?.featureId}
                </Button>
                <ArrowRightAltIcon fontSize="large" />
                <div style={{
                    height: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius:'5px',
                    color: 'white',
                    backgroundColor: 'rgb(25, 118, 210)',
                    marginLeft: '0.25em',
                    paddingInline: '1em'
                }}>
                    {getValues('customSchema')?.fields[index].name}
                </div>
            </Box>
            <Box>
                <Controller
                    name={`fields.${index}.name`}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={t('fieldName')}
                            variant="outlined"
                            size="small"
                            sx={{
                                paddingRight: '1em',
                              }}
                              {...register(`fields.${index}.name`, {
                                required: {
                                  value: true,
                                  message: t('inputRequired').replace('%1', t('fieldName')),
                                },
                                minLength: {
                                  value: 2,
                                  message: t('minValidation').replace('%1', '2'),
                                },
                                maxLength: {
                                  value: 256,
                                  message: t('maxValidation').replace('%1', '256'),
                                },
                                pattern: {
                                  value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                                  message: t('patternValidation'),
                                },
                              })}
                            helperText={(errors.fields?.[index] as any)?.name?.message}
                            error={(errors.fields?.[index] as any)?.name !== undefined}
                        />
                    )}
                />
                <Controller
                    name={`fields.${index}.fieldType`}
                    control={control}
                    render={({ field }) => (
                    <FormControl variant="outlined">
                        <ControlledSelect
                            valueList={FieldsSelectValue}
                            {...field}
                            size="small"
                            label={t('fieldType')}
                            sx={{ width: '200px' }}
                            {...register(`fields.${index}.fieldType`, {
                            required: {
                            value: true,
                            message: t('inputRequired').replace('%1', t('fieldType')),
                            },
                            })}
                            helperText={(errors.fields?.[index] as any)?.fieldType?.message}
                            error={(errors.fields?.[index] as any)?.fieldType !== undefined}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                hadleChangeType(e, index)
                            }
                        />
                    </FormControl>
                    )}
                />
                <Controller
                    name={`fields.${index}.isMultiValue`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                            control={<Checkbox onChange={onChange} checked={value} />}
                            label={t('fieldMulti')}
                            sx={{ paddingLeft: '1em' }}
                        />
                    )}
                />
                <Controller
                    name={`fields.${index}.defaultValue`}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={t('fieldDefault')}
                            {...register(`fields.${index}.defaultValue`, {})}
                            variant="outlined"
                            size="small"
                        />
                    )}
                />
            </Box>
            <Stack display='flex' direction='row' sx = {{mt: "20px"}}>
                <Switch checked={isWatchValidation} color="info" onChange={() => setWatchValidation(!isWatchValidation)} />
                <FormControl sx={{
                    justifyContent: 'center',
                    fontSize: 'large'
                }}>
                    {t('validation')}
                </FormControl>
            </Stack>
            {isWatchValidation && 
                <Box sx={{marginTop: '1em'}}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <RequiredView 
                            schemaName = {getValues('customSchema')?.featureId || ''}
                            fieldName = {getValues('customSchema')?.fields[index].name || ''}
                            index={0} 
                            fieldIndex = {index}
                            control = {control}
                        />
                        <ListRequiredView 
                            schemaName = {getValues('customSchema')?.featureId || ''}
                            fieldName = {getValues('customSchema')?.fields[index].name || ''}
                            index={1} 
                            fieldIndex = {index}
                            control = {control}
                        />
                        <TextFormatView 
                            schemaName = {getValues('customSchema')?.featureId || ''}
                            fieldName = {getValues('customSchema')?.fields[index].name || ''}
                            index={2}
                            fieldIndex = {index}
                            control = {control}
                            getValues = {getValues}
                            setValue = {setValue}
                        />
                        <TextLengthView 
                            schemaName = {getValues('customSchema')?.featureId || ''}
                            fieldName = {getValues('customSchema')?.fields[index].name || ''}
                            index={3} 
                            fieldIndex = {index}
                            control = {control}
                            register = {register}
                            errors = {errors}
                            getValues = {getValues}
                            setValue = {setValue}
                        />
                    </Box>
                </Box>
            }
        </>
    )
}

export default SchemaFieldExtension;