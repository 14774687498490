import useAlert from "../context/alertContext/useAlert";
import { FieldJSONProps, FieldProps } from "../types/content/schemas/Fields/NewFieldProp";
import { SchemaProjectProps, SchemaProps } from "../types/content/schemas/SchemaProps";
import { SchemaWithFieldsJsonProps, SchemaWithFieldsProps } from "../types/content/schemas/SchemaWithFieldsProps";

export function IsUniqueFeatureId(featureId: String, featureArray: String[]): Boolean {
    const { showError } = useAlert();
    const isUnique = featureArray.indexOf(featureId) === -1;
    if(!isUnique){
        showError('FeatureId is not unique');
    };
    return isUnique;
}

export function getListFeaturesId(listFeaturesId: any[]): String[] {
    const featuresId: String[] = [];
    listFeaturesId.forEach((obj) =>{
        featuresId.push(obj.featureId);
    });
    return featuresId;
}

export function convertSchemaProjectToSchema(schema: SchemaProjectProps): SchemaProps{
    return {
        id: schema.id,
        featureId: schema.featureId,
        prefix: schema.prefix,
        companyName: schema.project.split('-')[0],
        documentName: schema.project.split('-')[1],
        imported: schema.imported
    }
}

export function convertSchemaFieldsJsonToSchemaFieldsType(schemaJSON: SchemaWithFieldsJsonProps): SchemaWithFieldsProps {
    const schema: SchemaWithFieldsProps = {
        id: schemaJSON.id,
        featureId: schemaJSON.featureId,
        prefix: schemaJSON.prefix,
        companyName: schemaJSON.project.split('-')[0],
        documentName: schemaJSON.project.split('-')[1],
        isImported: schemaJSON.isImported,
        fields: convertFieldJSONToFieldType(schemaJSON.fields)
    }
    return schema;
}

function convertFieldJSONToFieldType(fieldsJSON: Array<FieldJSONProps>): Array<FieldProps>{
    const fields: Array<FieldProps> = fieldsJSON.map((item: FieldJSONProps) => {
        return {
            id: item.id,
            name: item.name,
            fieldType: item.fieldType,
            isMultiValue: item.isMultiValue,
            defaultValue: item.defaultValue,
            referenceConfiguration: JSON.parse(item.referenceConfigurationJson),
            constraints: JSON.parse(item.constraintsJson),
            subFields: item.subFields === null ? [] : convertFieldJSONToFieldType(item.subFields)
        }
    });
    return fields;
}