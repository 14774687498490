import { useSearchParams } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import { SimpleAccordion } from '../../../../common/accordion/SimpleAccordion';
import { TransferList } from '../../../../common/transfetList/TransferList';
import { DefaultSchemasForDocument } from '../../../../../enum/DefaultSchemasForDocument';
import { ControlledInput } from '../../../../common/input/ControlledInput';
import SchemaFieldsTable from './SchemaFieldsTable';
import SchemaFieldExtension from './SchemaFieldExtension';

interface IProps {
  extendType: string,
  inheritedSchemas: string,
  schemasBroughtByFacets: string,
  schemasList: string[],
  control: any,
  register: any,
  errors: any,
  getValues: any,
  setValue: any
}

export const SchemaForm = ({extendType, schemasList, inheritedSchemas, schemasBroughtByFacets, control, register, errors, getValues, setValue}: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const extensionParam = searchParams.get('ext') || 'false';
  const loadedSchemas = getValues('schemas');
  const schemasFullList: string[] = DefaultSchemasForDocument.concat(schemasList).sort();
    
  const updateSchemas = (newList: any) => {
    setValue('schemas', newList)
  };

  if (extensionParam === 'false') {
    return (
      <>
        <Box>
          <Stack direction="row" gap="10px">
                  <Typography >
                    This document inherits from:
                  </Typography>
                  <Typography fontWeight="bold">
                    {extendType}
                  </Typography>
          </Stack>
          <Stack direction="row" gap="10px">
                  <Typography >
                    Inherited schemas:
                  </Typography>
                  <Typography fontWeight="bold">
                    {inheritedSchemas}
                  </Typography>
          </Stack>
          <Stack direction="row" gap="10px">
                  <Typography >
                    Schemas brought by facets:
                  </Typography>
                  <Typography fontWeight="bold">
                    {schemasBroughtByFacets}
                  </Typography>
          </Stack>
        </Box>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <SimpleAccordion
              title="Add extra schemas to this document type"
              sx={{ mt: 3 }}
              defaultExpanded={true}
            >
              <TransferList
                entityList={schemasFullList}
                updateParent={updateSchemas}
                loadEntities={loadedSchemas}
              />
            </SimpleAccordion>
        </div>
        <Box>
          <SimpleAccordion title="Add custom schema" sx={{ mt: 3 }} defaultExpanded={true}>
            <Box display="flex" gap="20px" marginBottom="20px">
              <Box display="flex" alignItems="start">
                <Typography variant="h6" style={{ marginRight: 10, paddingTop: 4 }}>
                  Name
                </Typography>
                <ControlledInput
                  type="text"
                  required = {true}
                  register={{
                    ...register('customSchema.featureId', {
                      required: {
                        value: true,
                        message: 'Name is required',
                      },
                    }),
                  }}
                  helperText={errors.customSchema?.featureId?.message}
                  error={errors.customSchema?.featureId !== undefined}
                  sx={{ width: 300 }}
                />
              </Box>
              <Box display="flex" alignItems="start">
                <Typography variant="h6" style={{ marginRight: 10, paddingTop: 4 }}>
                  Prefix
                </Typography>
                <ControlledInput
                  type="text"
                  required = {true}
                  register={{
                    ...register('customSchema.prefix', {
                      required: {
                        value: true,
                        message: 'Prefix is required',
                      },
                    }),
                  }}
                  helperText={errors.customSchema?.prefix?.message}
                  error={errors.customSchema?.prefix !== undefined}
                  sx={{ width: 300 }}
                />
              </Box>
            </Box>
              <SchemaFieldsTable 
                control = {control}
                register = {register}
                setValue = {setValue}
                errors = {errors}
                name = 'fields'
                setSearchParams = {setSearchParams}
              />
            </SimpleAccordion>
          </Box>
        </>
      
    );
  }

  return (
    <SchemaFieldExtension 
      index = {0}
      control = {control}
      register = {register}
      errors = {errors}
      setValue = {setValue}
      getValues = {getValues}
      setSearchParams = {setSearchParams}
    />
  )
};
