import { TextField, Stack, Typography, Box , Button, Grid, Tooltip} from '@mui/material';
import { Loader } from '../../../../../../common/loader/Loader';
import useGetPermsForDoc from '../../../../../../../serverInteraction/hooks/content/roles-and-permissions/permissions/useGetPermsForDoc';
import { AxiosError } from 'axios';
import { theme } from '../../../../../../../styling/Theme';
import { useParams } from 'react-router-dom';
import useDocTypes from "../../../../../../../serverInteraction/hooks/content/document-types/useDocTypes";
import { SelectField } from "../../../../../../../types/common/SelectField";
import { ControlledSelect } from "../../../../../../common/select/ControlledSelect";
import { DocTypesProps } from "../../../../../../../types/content/documentTypes/DocTypesProps";
import { PermsForDoc } from '../../../../../../../types/content/roles-and-permissions/permissions/PermsForDoc';
import AddIcon from '@mui/icons-material/Add';
import React, { Fragment, useMemo, useState } from 'react';
import { DeleteIconButton } from '../../../../../../common/button/iconButton/DeleteIconButton';
import useAlert from '../../../../../../../context/alertContext/useAlert';
import useUpdate from '../../../../../../../serverInteraction/hooks/entity/useUpdate';
import { Trans} from 'react-i18next';


let listDocProps: Array<DocTypesProps> = [];
type addedPermForDoc = {
    id: null;
    featureId: string;
    order?: number;
    documentType: DocTypesProps;
}
let arrayDocsPerms: Array<PermsForDoc | addedPermForDoc> = [];

export function PermsListForDoc() {
    const params = useParams();
    const {showError} = useAlert();
    const { status, ListPerms, isLoading, isFetching, error, refetch } = useGetPermsForDoc(params.featureId as string);
    const [choosePerms, setChoosePerms] = useState<string[]>([]);
    const [selectedDocForAdd, setSelectedDocForAdd] = useState<string>('');
    const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);
    let initialPerms: Array<string> = [];
    const { update } = useUpdate<Array<PermsForDoc | addedPermForDoc>, PermsForDoc>('saveDocPerm');

    if (isLoading || isFetching || ListPerms === undefined) {
        return <Loader />;
    }

    if (isFirstLoading) {
        arrayDocsPerms = JSON.parse(`${ListPerms}`);
        initialPerms = arrayDocsPerms.map((item) => {return item.documentType.featureId;})
        setChoosePerms(initialPerms)
        setIsFirstLoading(false);
        return <Loader />;
    }

    if (status === 'error' && error instanceof AxiosError) {
        return (
            <Typography variant="h6" align="center">
                Error: {error.message}
            </Typography>
        );
    }

    const onSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedDocForAdd(event.target.value);
    };

    const onClickButton = () => {
        let selectedDoc: DocTypesProps | undefined = undefined;
        listDocProps.forEach((item) => {if(item.featureId === selectedDocForAdd) selectedDoc = item});
        if (selectedDoc !== undefined){
            let addedPermForDoc: addedPermForDoc = {
                id: null,
                featureId: params.featureId as string,
                documentType: selectedDoc
            };
            arrayDocsPerms.push(addedPermForDoc);
            setChoosePerms(choosePerms => [...choosePerms, `${selectedDocForAdd}`]);
            setSelectedDocForAdd('');
        }else{
            showError('Select document');
        }
    };

    const onDeleteItem = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const i: number = Number(e.currentTarget.id.split('_')[1]);
        const deletedItem: string = arrayDocsPerms[i].documentType.featureId;
        arrayDocsPerms.splice(i, 1);
        setChoosePerms(choosePerms => choosePerms.filter((item) => item !== deletedItem));
    };

    const onSavePerms = async () => {
      await update(params.featureId as string, arrayDocsPerms);
      window.history.back();
    };

    return (
        <div>
            <Typography variant='body2' sx={{
                 marginBottom: 2
                }}>
                <Trans i18nKey={'perms_typeDoc'} />
            </Typography>
            <SelectPermsForDoc choosePerms={choosePerms} onSelectChange={onSelectChange} onClickButton={onClickButton} value={selectedDocForAdd}/>
            <TableListForDoc listPerms={arrayDocsPerms} onClick={onDeleteItem}/>
            <Button
                    variant="contained"
                    color="primary"
                    style={{ 
                      marginLeft: 25,
                      position: 'absolute',
                      right: '1%',
                      bottom: '5%'
                    }}
                    startIcon={<AddIcon />}
                    onClick={onSavePerms}
                >
                    <Trans i18nKey={'save'} />
              </Button>
        </div>
    )
}

type SelectProps = {
    choosePerms: Array<string>;
    value?: string;
    onSelectChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClickButton: () => void;
}

function SelectPermsForDoc({choosePerms, onSelectChange, onClickButton, value} : SelectProps){
    const { status, documentTypes, isLoading, isFetching, error, refetch } = useDocTypes();
    let arrayValue: Array<SelectField> = [];

    if (isLoading || isFetching || documentTypes === undefined) {
        return <div></div>;
    }

    listDocProps = JSON.parse(`${documentTypes}`);
    listDocProps.forEach((item) => {
        arrayValue.push({value: item.featureId, label: item.featureId, hidden: choosePerms.indexOf(item.featureId) !== -1});
    });
    const isDisabled: boolean = listDocProps.length === arrayDocsPerms.length;

    return (
        <Box marginBottom={2}>
            <ControlledSelect valueList={arrayValue} size="small" label={<Trans i18nKey={'docTypes'} />} onChange={onSelectChange} value={value} disabled={isDisabled}/>
            <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: 25 }}
                    startIcon={<AddIcon />}
                    onClick={onClickButton}
                    disabled={isDisabled}
                >
                    <Trans i18nKey={'add'} />
              </Button>
        </Box>
    );
}

type TableProps = {
    listPerms: Array<PermsForDoc | addedPermForDoc>;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

function TableListForDoc({listPerms, onClick}: TableProps){

    const handlerChange =((e: React.ChangeEvent<HTMLInputElement>) => {
        const i: number = Number(e.target.id.split('_')[1]);
        arrayDocsPerms[i].order = e.target.valueAsNumber;
    });
    
    return (
        <Grid container 
            spacing={1} 
            sx={{
                marginTop: 1,
                display: 'flex',
                alignItems: 'center',
                marginBottom: 1
            }}
        >
            <Grid item 
                xs={2} 
                sx={{
                fontWeight: 600,
                backgroundColor: theme.palette.grey[200],
                height: '30px',
                paddingTop: '0.4em',
                borderRadius: '5px 0 0 5px',

                }}>
                <Trans i18nKey={'docTypes'} />
            </Grid><Grid item 
                xs={8} 
                sx={{
                fontWeight: 600,
                backgroundColor: theme.palette.grey[200],
                height: '30px',
                paddingTop: '0.4em'
                }}>
                <Trans i18nKey={'perms_order'} />
            </Grid><Grid item 
                xs={2} 
                sx={{
                fontWeight: 600,
                backgroundColor: theme.palette.grey[200],
                height: '30px',
                borderRadius: '0 5px 5px 0',
                }}>

            </Grid>
            {listPerms.map((item: PermsForDoc | addedPermForDoc, index: number) => (
                <Fragment key={item.documentType.featureId}>
                    <Grid item xs={2}>
                        {item.documentType.featureId}
                    </Grid>
                    <Grid item xs={8}>
                        <TextField id={`order_${index}`} onChange={handlerChange} inputProps={{ type: 'number', defaultValue: item.order}} size="small" sx={{width: '100%'}} />
                    </Grid>
                    <Grid item xs={2}>
                        <Stack width='100%' marginLeft="90%" justifyContent="space-between" spacing={2} direction="row">
                            <DeleteIconButton id={`itemDeleted_${index}`} onClick={onClick} size="small" />
                        </Stack>
                    </Grid>
                </Fragment>
            ))}
      </Grid>
      
    )
}
