import {
  Box,
  Button,
  InputBase,
  InputBaseComponentProps,
  Stack,
} from "@mui/material";
import { SimpleModal } from "../modal/SimpleModal";
import { SelectResourceList } from "./SelectResourceList";
import { useState } from "react";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  handleChange: (...event: any[]) => void;
  inputProps?: InputBaseComponentProps;
};

export function SelectResourceModal({
  isOpen,
  handleClose,
  handleChange,
  inputProps,
}: Props) {
  const [selectedUrl, setSelectedUrl] = useState<string>();

  const handleSelect = () => {
    handleChange(selectedUrl);
    handleClose();
  };

  return (
    <SimpleModal
      title="Select Resource"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <Box sx={{ minHeight: 500 }}>
        <Stack spacing={3}>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={3}
            sx={{ display: "flex", alignItems: "center", py: 3, height: 48 }}
          >
            <InputBase
              type="file"
              inputProps={inputProps}
              sx={{ height: 28 }}
            />
            <Button variant="contained" sx={{ height: 28 }}>
              Upload
            </Button>
          </Stack>
          <Stack
            direction="row"
            spacing={3}
            alignItems="start"
            justifyContent="space-between"
          >
            <SelectResourceList
              selectedUrl={selectedUrl}
              handleSelect={(id) => setSelectedUrl(id)}
            />
          </Stack>
        </Stack>
      </Box>
      <Stack
        direction="row-reverse"
        spacing={3}
        alignItems="right"
        justifyContent="end"
        sx={{ pt: 3 }}
      >
        <Button variant="contained" onClick={handleSelect} sx={{ width: 150 }}>
          OK
        </Button>
        <Button variant="outlined" onClick={handleClose} sx={{ width: 150 }}>
          Cancel
        </Button>
      </Stack>
    </SimpleModal>
  );
}
