import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Box, Link, Stack, Typography } from "@mui/material";
import { theme } from "../../../styling/Theme";
import { PackageProps } from "../../../types/packages/PackageProps";

export function PackagesFormList({packages}:{packages:PackageProps[]}){
    const { t } = useTranslation();
    
    return (
        <Box>
        <Typography
          sx={{
            fontWeight: 600,
            backgroundColor: theme.palette.grey[200],
            py: 1,
            px: 2,
          }}
        >
          {t('name')}
        </Typography>
        <Stack>
          {packages.map((elem: PackageProps) => (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ py: 1, px: 2 }}
              key={elem.id}
            >
              <Link underline="hover" 
                component={RouterLink} 
                to={`${elem.id}`}
                state={ elem }
              >
                {elem.project}
              </Link>
            </Stack>
          ))}
        </Stack>
      </Box>
    )
  }
  