import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { ControlledRadioGroup } from "./ControlledRadioGroup";

type Props<T extends FieldValues> = {
  control?: Control<T>;
  name: Path<T>;
};

export function ControlledYesNoRadioGroup<T extends FieldValues>({
  control,
  name,
}: Props<T>) {
  const id = name.replaceAll(".", "-");

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <ControlledRadioGroup
          id={id}
          value={value}
          items={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
          onChange={onChange}
        />
      )}
    />
  );
}
