import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useCreate from '../../../../serverInteraction/hooks/entity/useCreate';
import { useForm, useFormContext } from 'react-hook-form';
import { AddDialog } from '../../../common/dialog/AddDialog';
import { ControlledInput } from '../../../common/input/ControlledInput';
import { ControlledTextarea } from '../../../common/textarea/ControlledTextarea';

import { GridOneToThree, GridOneToThreeItemProps } from '../../../common/grid/GridOneToThree';
import { Loader } from '../../../common/loader/Loader';
import { PackageProps } from '../../../../types/packages/PackageProps';
import { useSelector } from 'react-redux';


export function PackageCreateDialog() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getValues: getValuesMainForm } = useFormContext<{listPackages: PackageProps[]}>();
  const { create, isCreating: isLoading } = useCreate<PackageProps, PackageProps>('package');
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  
  const {
    register,
    handleSubmit,
    trigger,
    watch,
    reset,
    formState: { errors },
  } = useForm<PackageProps>({
    defaultValues: {
      company: userPackage.company,
      document: '',
      description: ''
    },
  });

  const onSubmit = async (newPackage: PackageProps) => {
    isLoading && <Loader />;
    const res: PackageProps = JSON.parse(`${await create(newPackage)}`);
    reset();
    navigate(`${res.id}`, { state: res});
  };

  const validate = async () => {
    return await trigger();
  };

  const isUnique = (value: string) => getValuesMainForm('listPackages').map((item: PackageProps) => item.document).indexOf(value) === -1;

  const createItems: GridOneToThreeItemProps[] = [
    {
      title: 'Company',
      required: true,
     
      content: (
        <ControlledInput
          type="text"
          disabled= {true}
          register={{...register('company', {}) }}
          sx={{ width: '100%' }}
        />
      ),
    },
    {
      title: 'Document',
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register('document', {
              pattern: {
                value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                message: t('patternValidation')
              },
              required: {
                value: true,
                message: t('inputRequired').replace('%1', t('document')),
              },
              minLength: {
                value: 3,
                message: t('minValidation').replace('%1', '2'),
              },
              validate: value => isUnique(value) || 'Document is not unique'
            }),
          }}
          helperText={errors.document?.message}
          error={errors.document !== undefined}
          sx={{ width: '100%' }}
        />
      ),
    },
    {
      title: 'Description',
      required: false,
      content: (
        <ControlledTextarea
          register={{
            ...register('description', {}),
            maxRows: 4,
            minRows: 3,
          }}
        />
      ),
    },
   
  ]
   

  return (
    <AddDialog
      title={t('createPackge')}
      add={handleSubmit(onSubmit)}
      validate={validate}
    >
      <Box sx={{ minHeight: 500 }}>
        <GridOneToThree items={createItems} />
      </Box>
    </AddDialog>
  );
}
