import { Link, Stack, Typography, Box } from "@mui/material";
import useBrandings from "../../../serverInteraction/hooks/branding/useBrandings";
import { Loader } from "../../common/loader/Loader";
import { AxiosError } from "axios";
import { Link as RouterLink } from "react-router-dom";
import { theme } from "../../../styling/Theme";
import { BrandingDeleteDialog } from "./delete/BrandingDeleteDialog";

export function BrandingPageList() {
  const { status, brandings, isLoading, isFetching, error, refetch } =
    useBrandings();

  if (isLoading || isFetching || brandings === undefined) {
    return <Loader />;
  }

  if (status === "error" && error instanceof AxiosError) {
    return (
      <Typography variant="h6" align="center">
        Error: {error.message}
      </Typography>
    );
  }

  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 600,
          backgroundColor: theme.palette.grey[200],
          py: 1,
          px: 2,
        }}
      >
        Name
      </Typography>
      <Stack>
        {brandings?.map((branding) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ py: 1, px: 2 }}
            key={branding.id}
          >
            <Link
              underline="hover"
              component={RouterLink}
              to={`/branding/${branding.id}`}
            >
              {branding.name}
            </Link>
            <Stack
              justifyContent="space-between"
              alignItems="right"
              spacing={2}
            >
              <BrandingDeleteDialog
                branding={branding}
                onDelete={() => refetch()}
              />
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
