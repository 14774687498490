import {
  FormControl,
  FormControlLabel,
  FormControlProps,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

type ItemProps = {
  label: string;
  value: any;
};

type Props = {
  id: string;
  label?: string;
  value: any;
  // items: ItemProps[];
  items: any[];
  onChange: (...event: any[]) => void;
} & FormControlProps;

export function ControlledRadioGroup({
  id,
  label,
  value,
  items,
  onChange,
  ...formControlProps
}: Props) {
  const labelId = `${id}-select-label`;

  return (
    <FormControl {...formControlProps}>
      {label !== undefined && (
        <FormLabel id={labelId} sx={{ pb: 1 }}>
          {label}
        </FormLabel>
      )}
      <RadioGroup row aria-labelledby={labelId}>
        {items.map((item) => (
          <FormControlLabel
            key={item.value}
            style={{
              display: 'flex',
              marginRight: '20px',
            }}
            control={<Radio checked={item.value === value} onChange={() => onChange(item.value)} />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
