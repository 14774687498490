import { Box, BoxProps, Toolbar } from "@mui/material";
import { PropsWithChildren } from "react";

type Props = BoxProps & PropsWithChildren;

export function Page({ children, ...props }: Props) {
  return (
    <Box sx={{ width: "100%" }} {...props}>
      <Toolbar />
      {children}
    </Box>
  );
}
