import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

type Props = IconButtonProps;

export function DeleteIconButton({ ...props }: Props) {
  const {t} = useTranslation();
  return (
    <Tooltip title={t('button_delete')}>
      <IconButton aria-label='delete' {...props}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
}
