import { TextField, TextFieldProps, MenuItem } from "@mui/material";
import { Trans } from "react-i18next";
import { SelectField } from "../../../types/common/SelectField";

type Props = {
    valueList: Array<SelectField>;
    isTranslate?: boolean;
    selectedValue?: string | number;
  } & TextFieldProps;
  
  export function ControlledSelect({valueList, isTranslate, selectedValue, ...props }: Props) {
    return (
        <TextField
            sx={{ width: 250}}
            inputProps={{ style: { padding: "0.5rem 1rem " } }}
            {...props}
            select
            defaultValue={selectedValue}
        >
        {valueList.map((option: SelectField) => (
            <MenuItem
              key={option.value} 
              value={option.value} 
              sx={{
                display: option.hidden ? 'none' : 'flex' }}>
              {isTranslate ? <Trans i18nKey={option.label} /> : option.label}
            </MenuItem>
          ))}
        </TextField>
    );
  }