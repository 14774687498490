import { useQuery } from '@tanstack/react-query';
import { BaseEntityProps } from '../../../types/BaseEntityProps';
import EntityService from '../../services/EntityInteractionService';

const useGetAll = <T extends BaseEntityProps>(entityName: string) => {
  const { status, data, error, isLoading, isFetching, isRefetching, refetch } = useQuery(
    [entityName, 'all'],
    async (): Promise<T[]> => {
      return await EntityService.getAll(entityName);
    },
  );

  return {
    status,
    data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default useGetAll;
