import {
  Box,
  BoxProps,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { PropsWithChildren, useState } from 'react';

type Props = {
  title: string;
  style?: React.CSSProperties;
  returnNewName: (newName: string) => void;
  isClose: (newValue: boolean) => void;
  open: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
} & BoxProps &
  PropsWithChildren;

export function RenameDialog({
  title,
  children,
  open,
  returnNewName,
  isClose,
  inputRef,
  ...props
}: Props) {
  const [name, setName] = useState('');

  const handleClose = () => {
    isClose(false);
  };

  const handleRename = () => {
    returnNewName(name);
  };

  return (
    <Dialog
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={3}>
          <input
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                handleRename();
              }
              if (e.code === 'Esc') {
                handleClose();
              }
            }}
            style={{
              width: 200,
              border: '1px solid #000',
            }}
            type="text"
            onChange={(e) => setName(e.target.value)}
            ref={inputRef}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleRename} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
