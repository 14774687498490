import { Button, Divider, Paper, Typography } from "@mui/material";
import { Page } from "../../../common/page/Page";
import { PageHeader } from "../../../common/page/PageHeader";
import { PageContent } from "../../../common/page/PageContent";
import { useParams } from "react-router-dom";
import useBranding from "../../../../serverInteraction/hooks/branding/useBranding";
import { Loader } from "../../../common/loader/Loader";
import { AxiosError } from "axios";
import { BrandingProps } from "../../../../types/branding/BrandingProps";
import { useForm } from "react-hook-form";
import { SimpleAccordion } from "../../../common/accordion/SimpleAccordion";
import {
  GridOneToThree,
  GridOneToThreeItemProps,
} from "../../../common/grid/GridOneToThree";
import { ControlledYesNoRadioGroup } from "../../../common/radioGroup/ControlledYesNoRadioGroup";
import { ControlledInput } from "../../../common/input/ControlledInput";
import { ControlledSingleResource } from "../../../common/resource/ControlledSingleResource";
import { ControlledColorPicker } from "../../../common/color/ControlledColorPicker";
import useUpdate from "../../../../serverInteraction/hooks/entity/useUpdate";
import { ProgressButton } from "../../../common/button/ProgressButton";

type Params = {
  id: string;
};

export function BrandingEditPage() {
  const { id } = useParams<Params>();
  const { status, branding, isLoading, isFetching, error } = useBranding(id!);
  const { update, isUpdating } = useUpdate<BrandingProps, BrandingProps>(
    "branding"
  );

  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<BrandingProps>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    values: branding,
    defaultValues: {
      loginBox: { backgroundColor: "#fff", buttonBackgroundColor: "#000" },
    },
  });

  const onSubmit = async (newBranding: BrandingProps) => {
    console.log(newBranding);
    await update(id!, newBranding);
  };

  const watchIsImageCoversAllScreen = watch(
    "background.isImageCoversAllScreen"
  );

  if (isLoading || isFetching || branding === undefined) {
    return (
      <Page>
        <Loader />
      </Page>
    );
  }

  if (status === "error" && error instanceof AxiosError) {
    return (
      <Page>
        <Typography variant="h6" align="center">
          Error: {error.message}
        </Typography>
      </Page>
    );
  }

  const backgroundItems: GridOneToThreeItemProps[] = [
    {
      title: "Background Video URLs / MIME Type",
      content: "123",
    },
    {
      title: "Video Looping",
      content: (
        <ControlledYesNoRadioGroup
          control={control}
          name="background.isVideoLooping"
        />
      ),
    },
    {
      title: "Video Muted",
      content: (
        <ControlledYesNoRadioGroup
          control={control}
          name="background.isVideoMuted"
        />
      ),
    },
    {
      title: "Background Image",
      content: (
        <ControlledSingleResource
          control={control}
          name="background.backgroundImageUrl"
        />
      ),
    },
    {
      title: "Image Covers All the Screen",
      content: (
        <ControlledYesNoRadioGroup
          control={control}
          name="background.isImageCoversAllScreen"
        />
      ),
    },
    {
      title: "Horizontal Position (px)",
      content: (
        <ControlledInput
          type="number"
          disabled={watchIsImageCoversAllScreen}
          register={{
            ...register("background.horizontalPosition", {
              required: {
                value: !watchIsImageCoversAllScreen,
                message: "Must be 0 or higher",
              },
              min: { value: 0, message: "Must be 0 or higher" },
            }),
          }}
          helperText={
            watchIsImageCoversAllScreen
              ? undefined
              : errors.background?.horizontalPosition?.message
          }
          error={errors.background?.horizontalPosition !== undefined}
        />
      ),
    },
    {
      title: "Vertical Position (px)",
      content: (
        <ControlledInput
          type="number"
          disabled={watchIsImageCoversAllScreen}
          register={{
            ...register("background.verticalPosition", {
              required: {
                value: !watchIsImageCoversAllScreen,
                message: "Must be 0 or higher",
              },
              min: { value: 0, message: "Must be 0 or higher" },
            }),
          }}
          helperText={
            watchIsImageCoversAllScreen
              ? undefined
              : errors.background?.verticalPosition?.message
          }
          error={errors.background?.verticalPosition !== undefined}
        />
      ),
    },
  ];

  const loginLogoItems: GridOneToThreeItemProps[] = [
    {
      title: "Logo image",
      content: (
        <ControlledSingleResource
          control={control}
          name="loginLogo.logoImageUrl"
        />
      ),
    },
    {
      title: "Width (px)",
      content: (
        <ControlledInput
          type="number"
          register={{
            ...register("loginLogo.width", {
              min: { value: 0, message: "Must be 0 or higher" },
            }),
          }}
          helperText={errors.loginLogo?.width?.message}
          error={errors.loginLogo?.width !== undefined}
        />
      ),
    },
    {
      title: "Height (px)",
      content: (
        <ControlledInput
          type="number"
          register={{
            ...register("loginLogo.height", {
              min: { value: 0, message: "Must be 0 or higher" },
            }),
          }}
          helperText={errors.loginLogo?.height?.message}
          error={errors.loginLogo?.height !== undefined}
        />
      ),
    },
    {
      title: "Logo Alt",
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register("loginLogo.logoAlt"),
          }}
          helperText={errors.loginLogo?.logoAlt?.message}
          error={errors.loginLogo?.logoAlt !== undefined}
        />
      ),
    },
  ];

  const loginBoxItems: GridOneToThreeItemProps[] = [
    {
      title: "Box background image",
      content: (
        <ControlledSingleResource
          control={control}
          name="loginBox.boxBackgroundImageUrl"
        />
      ),
    },
    {
      title: "Background Color",
      content: (
        <ControlledColorPicker
          control={control}
          name="loginBox.backgroundColor"
        />
      ),
    },
    {
      title: "Width (px)",
      content: (
        <ControlledInput
          type="number"
          register={{
            ...register("loginBox.width", {
              min: { value: 0, message: "Must be 0 or higher" },
            }),
          }}
          helperText={errors.loginBox?.width?.message}
          error={errors.loginBox?.width !== undefined}
        />
      ),
    },
    {
      title: "Button Background Color",
      content: (
        <ControlledColorPicker
          control={control}
          name="loginBox.buttonBackgroundColor"
        />
      ),
    },
  ];

  const additionalSettingsItems: GridOneToThreeItemProps[] = [
    {
      title: "Display News",
      content: (
        <ControlledYesNoRadioGroup
          control={control}
          name="additionalSettings.isDisplayNews"
        />
      ),
    },
    {
      title: "News Feed URL",
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register("additionalSettings.newsFeedUrl"),
          }}
          helperText={errors.additionalSettings?.newsFeedUrl?.message}
          error={errors.additionalSettings?.newsFeedUrl !== undefined}
        />
      ),
    },
    {
      title: "Display Mobile App Banner",
      content: (
        <ControlledYesNoRadioGroup
          control={control}
          name="additionalSettings.isDisplayMobileAppBanner"
        />
      ),
    },
    {
      title: "Display Footer",
      content: (
        <ControlledYesNoRadioGroup
          control={control}
          name="additionalSettings.isDisplayFooter"
        />
      ),
    },
  ];

  const toolbar = (
    <>
      <Button variant="outlined" sx={{ width: 100 }} onClick={() => reset()}>
        Reset
      </Button>
      <ProgressButton
        variant="contained"
        sx={{ minWidth: 100 }}
        type="submit"
        isProgressing={isSubmitting || isUpdating}
        title="Save"
        progressTitle="Saving..."
      />
    </>
  );

  return (
    <Page component="form" onSubmit={handleSubmit(onSubmit)}>
      <PageHeader title={`Branding ${branding.name}`} toolbar={toolbar} />
      <PageContent subtitle="Customize the platform to match your organization's visual identity by creating branding sets.">
        <Paper elevation={2} square sx={{ p: 2 }}>
          <SimpleAccordion title="Background">
            <GridOneToThree items={backgroundItems} />
          </SimpleAccordion>
          <Divider />
          <SimpleAccordion title="Login logo">
            <GridOneToThree items={loginLogoItems} />
          </SimpleAccordion>
          <Divider />
          <SimpleAccordion title="Login box">
            <GridOneToThree items={loginBoxItems} />
          </SimpleAccordion>
          <Divider />
          <SimpleAccordion title="Additional settings">
            <GridOneToThree items={additionalSettingsItems} />
          </SimpleAccordion>
        </Paper>
      </PageContent>
    </Page>
  );
}
