import { BrandingProps } from "../../../types/branding/BrandingProps";
import useGet from "../entity/useGet";

const useBranding = (id: string) => {
  const entityName = "branding";

  const { status, data, error, isLoading, isFetching, isRefetching, refetch } =
    useGet<BrandingProps>(entityName, id);

  return {
    status,
    branding: data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default useBranding;
