import { ClickAwayListener, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import InfoIcon from '@mui/icons-material/Info';

type Props = {
  title: React.ReactNode | string; 
  style?: React.CSSProperties
  placement? : "bottom-end" | "bottom-start" | "bottom" | "left-end" | "left-start" | "left" | "right-end" | "right-start" | "right" | "top-end" | "top-start" | "top";
};

export default function IconMoreInfo({title, style, placement}: Props) {
    const [open, setOpen] = useState<boolean>(false);
    return (
        <ClickAwayListener onClickAway={() => {setOpen(false);}}>
            <Tooltip
                PopperProps={{
                disablePortal: true,
                }}
                onClose={() => {setOpen(false);}}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={title}
                style={style}
                placement={placement}
            >
              <IconButton size='small' sx={{width: '30px'}}>
                <InfoIcon onClick={() => {setOpen(true);}}/>
              </IconButton>
            </Tooltip>
        </ClickAwayListener>
    )
}