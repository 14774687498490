import { useSelector } from "react-redux";
import { FacetProps } from "../../../../types/content/facets/FacetProps";
import useGetAll from "../../entity/useGetAll";
import { PackageProps } from "../../../../types/packages/PackageProps";

const useFacets = () => {
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const entityName = `facets?company=${userPackage.company}&document=${userPackage.document}`;

  const { status, data, error, isLoading, isFetching, isRefetching, refetch } =
    useGetAll<FacetProps>(entityName);

  return {
    status,
    facets: data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default useFacets;
