import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './constants';

const packagesSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    setUserPackage(state, action){
      state.userPackage = action.payload.userPackage;
    }
    
  }
})

export const { setUserPackage } = packagesSlice.actions;
export default packagesSlice.reducer;

