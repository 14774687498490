import { FormLabel, Grid } from '@mui/material';
import React from 'react';
import { ReactNode } from 'react';

export type GridOneToThreeItemProps = {
  title: string;
  tooltip?: ReactNode;
  content: ReactNode;
  required?: boolean;
};

type Props = {
  items: GridOneToThreeItemProps[];
};

export function GridOneToThree({ items }: Props) {
  return (
    <Grid container columns={4} spacing={1} justifyContent="center" alignItems="center">
      {items.map(({ title, content, required }) => (
        <React.Fragment key={title}>
          <Grid item xs={1} sx={{ height: '100%' }}>
            <FormLabel required={required}>{title}</FormLabel>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: 'flex',
              minHeight: '3rem',
              alignItems: 'center',
            }}
          >
            {content}
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
}
