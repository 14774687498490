import { Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from "react-i18next";
import { theme } from "../../../../../styling/Theme";
import { Controller, useFieldArray } from "react-hook-form";
import { DeleteIconButton } from "../../../../common/button/iconButton/DeleteIconButton";
import { ControlledSelect } from "../../../../common/select/ControlledSelect";
import { FieldsSelectValue } from "../../../../../staticValues/Constants";

interface IProps {
    name: string,
    register: any,
    setValue: any, 
    errors: any,
    control: any,
    setSearchParams: any
}

function SchemaFieldsTable( { name, register, setValue, errors, control, setSearchParams }: IProps ) {
    const { t } = useTranslation();
    const { fields,  append, remove } = useFieldArray({control, name: name});
      
    const handleItemAdd = () => {
      append({
          name: "",
          fieldType: "STRING",
          isMultiValue: false,
          defaultValue: "",
      });
    };
  
    const hadleChangeType = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
      setValue(`fields.${index}.fieldType`, event.target.value);
      if (['BLOB', 'COMPLEX'].indexOf(event.target.value) !== -1) {
        setValue(`fields.${index}.defaultValue`, '');
      }
    };

    const handleClickToFieldPage = (index: number)=> {
      setSearchParams({tab: '1', ext: 'true'});
    };
  
    return (
      <>
        <Box
          sx={{
            width: '100%',
            paddingBottom: '1em',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: 10 }}
            startIcon={<AddIcon />}
            onClick={handleItemAdd}
          >
            {t('fieldAdd')}
          </Button>
        </Box>
        <Box
          sx={{
            maxHeight: '500px',
            overflowY: 'auto',
            border: '1px solid grey',
            borderRadius: '5px',
          }}
        >
          <Box>
            <Stack
              direction="row"
              sx={{
                fontWeight: 600,
                height: '40px',
              }}
            >
              <div
                style={{
                  width: '40px',
                }}
              ></div>
              <div
                style={{
                  backgroundColor: theme.palette.grey[200],
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '1em',
                  borderLeft: '1px solid grey',
                }}
              >
                {t('fieldsConfig')}
              </div>
            </Stack>
          </Box>
          
          {fields.map((item: any, index: number) => (
              <Box key={item.id}>
                <Stack direction="row" height="auto" width="100%">
                  <div
                    style={{
                      width: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderTop: '1px solid grey',
                    }}
                  >
                    <DeleteIconButton onClick={() => remove(index)} size="small" />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      borderLeft: '1px solid grey',
                      borderTop: '1px solid grey',
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: '1em',
                    }}
                  >
                    <Stack
                      direction="row"
                      py={2}
                      sx={{
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>
                        <Controller
                          name={`fields.${index}.name`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label={t('fieldName')}
                              variant="outlined"
                              size="small"
                              sx={{
                                paddingRight: '1em',
                              }}
                              {...register(`fields.${index}.name`, {
                                required: {
                                  value: true,
                                  message: t('inputRequired').replace('%1', t('fieldName')),
                                },
                                minLength: {
                                  value: 2,
                                  message: t('minValidation').replace('%1', '2'),
                                },
                                maxLength: {
                                  value: 256,
                                  message: t('maxValidation').replace('%1', '256'),
                                },
                                pattern: {
                                  value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                                  message: t('patternValidation'),
                                },
                              })}
                              helperText={(errors.fields?.[index] as any)?.name?.message}
                              error={(errors.fields?.[index] as any)?.name !== undefined}
                            />
                          )}
                        />
                        <Controller
                          name={`fields.${index}.fieldType`}
                          control={control}
                          render={({ field }) => (
                            <FormControl variant="outlined">
                              <ControlledSelect
                                valueList={FieldsSelectValue}
                                {...field}
                                size="small"
                                label={t('fieldType')}
                                sx={{
                                  width: '200px',
                                }}
                                {...register(`fields.${index}.fieldType`, {
                                  required: {
                                    value: true,
                                    message: t('inputRequired').replace('%1', t('fieldType')),
                                  },
                                })}
                                helperText={(errors.fields?.[index] as any)?.fieldType?.message}
                                error={(errors.fields?.[index] as any)?.fieldType !== undefined}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  hadleChangeType(e, index)
                                }
                              />
                            </FormControl>
                          )}
                        />
                        <Controller
                          name={`fields.${index}.isMultiValue`}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <FormControlLabel
                              
                              control={<Checkbox onChange={onChange} checked={value} />}
                              label={t('fieldMulti')}
                              sx={{ paddingLeft: '1em' }}
                            />
                          )}
                        />
                        <Controller
                          name={`fields.${index}.defaultValue`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label={t('fieldDefault')}
                              {...register(`fields.${index}.defaultValue`, {})}
                              variant="outlined"
                              size="small"
                              
                            />
                          )}
                        />
                      </div>
                      <Stack spacing={2} direction="row" style={{
                        marginRight: '1%'
                      }}>
                        <Tooltip title={t('fieldAdvSett')}>
                          <IconButton onClick={()=> handleClickToFieldPage(index)}>
                            <SettingsIcon/>
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </Stack>
                  </div>
                </Stack>
              </Box>
            ))}
         
        </Box>
            </>
    )
}

export default SchemaFieldsTable
