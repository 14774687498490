import { createContext, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Box, Button, FormControl, Paper, Stack, Typography } from "@mui/material";
import { Page } from "../../common/page/Page";
import { PageHeader } from "../../common/page/PageHeader";
import { PageContent } from "../../common/page/PageContent";
import { PackageProps } from "../../../types/packages/PackageProps";
import { ControlledSelect } from "../../common/select/ControlledSelect";
import { Loader } from "../../common/loader/Loader";
import { ControlledInput } from "../../common/input/ControlledInput";
import usePackages from "../../../serverInteraction/hooks/packages/usePackages";
import { useDispatch, useSelector } from "react-redux";
import { setUserPackage } from "../../../store/packages/packagesSlice";

export default function ProfilePage() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const { status, projects, isLoading, isFetching, error } = usePackages(userPackage.company || "");
  

  const FormContext = createContext<Partial<PackageProps>>({
    company: userPackage.company || "",
    document: userPackage.document || ""
  });

  const formFields = useContext(FormContext);
  
  const formMethods = useForm<Partial<PackageProps>>({
      defaultValues: formFields
  });

  const { control, handleSubmit, register, setValue, formState: { errors } } = formMethods;

  const handleChangeDocument = (event: any) => {
    setValue('document', event.target.value)
  }

  
  //RENDERING
   //RENDERING
   if (isLoading || isFetching || projects === undefined) {
    return <Loader />;
  }

  const packagesList: PackageProps[] = projects ? JSON.parse(projects.toString()) : [];
  
  const onSubmit = (data: Partial<PackageProps>) => {
    const newPackage = packagesList.find(elem => (elem.company === data.company && elem.document === data.document));
    if(newPackage) {
      dispatch(setUserPackage({userPackage: newPackage}));
    }
    if(location.key !== "default"){
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Page >
        <PageHeader title={t('profile')}></PageHeader>
        <PageContent subtitle="">
          <Paper elevation={2} square sx={{ p: 2 }} style={{ width: '500px' }}>
            <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: "20px" }}>
              {t('package')}
            </Typography>
            <Stack spacing={3}></Stack>
            <Box display="flex" marginBottom="10px">
              <Box display="flex" alignItems="top" sx={{ paddingRight: '1em' }}>
                <Controller
                  name='company'
                  control={control}
                  render={({ field }) => (
                    <FormControl variant="outlined">
                      <ControlledInput
                        {...field}
                        disabled={true}
                        type="text"
                        register={{}}
                        size="small"
                        label={t('usersCompany')} 
                        sx={{
                          width: '200px',
                        }}
                      />
                    </FormControl>
                  )}
                />
              </Box>
              <Box display="flex" alignItems="top" sx={{ paddingRight: '1em' }}>
                <Controller
                  name='document'
                  control={control}
                  render={({ field }) => (
                    <FormControl variant="outlined">
                      <ControlledSelect
                        valueList={packagesList.map((elem: PackageProps) => ({value: elem.document, label: elem.document})) || []}
                        {...field}
                        size="small"
                        label={t('document')} 
                        sx={{
                          width: '200px',
                        }}
                        {...register('document', {
                          required: {
                            value: true,
                            message: t('inputRequired').replace('%1', t('document')),
                          },
                        })}
                        helperText={errors.document?.message}
                        error={errors.document !== undefined}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChangeDocument(e)
                        }
                      />
                    </FormControl>
                  )}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: '100%',
                justifyContent: 'right',
                display: 'flex',
                paddingTop: '1em',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{ 
                  marginLeft: 25,
                }}
                onClick={handleSubmit(onSubmit)}
              >
                {t("save")}
              </Button>
            </Box>
          </Paper>
        </PageContent>
      </Page>
    </FormProvider>
  );
}