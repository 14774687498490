import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputBase, Stack} from '@mui/material';

type Props = {
  title: string;
  handleChangeIconFile: (newFile: File | undefined) => void;
};

export function IconDialog({ title, handleChangeIconFile, ...props }: Props) {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCommit = async () => {
    
    handleChangeIconFile(file);
    //#TODO Ждем бэкенд. Пока закидываем в doctype название. Договоренность в telegram backend 06.09.2024 
    /*if (file) {
      console.log('Uploading file...');
  
      const formData = new FormData();
      formData.append('file', file);
  
      try {
        // You can write the URL of your server or any other endpoint used for file upload
        const result = await fetch('https://api.ruxeo.ru/content/upload?document=document4&company=company4', {
          method: 'POST',
          body: formData,
        });
  
        const data = await result.json();
  
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    }*/
    setOpen(false);
  };

  const handleClose = async () => {
    /*if (file) {
      const formData = new FormData();
      formData.append('file', file);
      const d = 1;
      }
    handleChangeIconFile(file);*/
    if (file) {
      console.log('Uploading file...');
  
      const formData = new FormData();
      formData.append('file', file);
  
      try {
        // You can write the URL of your server or any other endpoint used for file upload
        const result = await fetch('https://api.ruxeo.ru/content/upload?document=document4&company=company4', {
          method: 'POST',
          body: formData,
        });
  
        const data = await result.json();
  
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    }
    setOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setFile(files[0]);
    }
  }

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Select Resource
      </Button>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent>
          <Stack direction="row" spacing={3}>
            <InputBase
              type="file"
              onChange={handleChange}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleCommit} color="primary">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
