import {User} from "../../../../../types/content/roles-and-permissions/users&groups/User";
import useGetAll from "../../../entity/useGetAll";

const useGetAllUsers = () => {
    const entityName = "Users";
    const { status, data, error, isLoading, isFetching, isRefetching, refetch } = useGetAll<User>(entityName);
  
    return {
      status,
      UsersList: data,
      error,
      isLoadingUsers: isLoading,
      isFetchingUsers: isFetching,
      isRefetching,
      refetch,
    };
  };
  
  export default useGetAllUsers;