import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import useAlert from "../../../context/alertContext/useAlert";
import useLoader from "../../../context/loaderContext/useLoader";
import { Setting } from "../../../types/common/Setting";
import useCreate from "../../hooks/entity/useCreate";
import useGet from "../../hooks/entity/useGet";
import useRemove from "../../hooks/entity/useRemove";
import BaseServerInteractionService from "../../services/BaseServerInteractionService";

//Получение настройки по id
export const useGetSettings = (id: string) => {
    const { status, data, error, isLoading, isFetching, isRefetching, refetch } =
      useGet<Setting>('getSetting', id);

    return {
      status,
      setting: data,
      error,
      isLoading,
      isFetching,
      isRefetching,
      refetch,
    };
};

//Получение листа настроек по списку id
export const usePostListSettings = (listSettings: Array<string>) => {
  const { status, data, error, isLoading, isFetching, isRefetching, refetch } =
    useQuery([listSettings], async (): Promise<any> => {
      return await getPost(listSettings);
    });

  return {
    status,
    settings: data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

//Сохранение одной настройки
export function useSaveSettings(entityName = 'saveSettings'): Function {
    const {create} = useCreate<Setting, Setting>(entityName);

    return async (set: Setting) => {
        await create(set);
    };
}

//Удаление одной настройки
export function useDeleteSettings(): Function {
    const {remove} = useRemove('deleteSetting');

    return async (id: string) => {
        await remove(id);
    };
}

export function useSaveListSettings(): Function {
  const {create} = useCreate<Setting[], Setting>('saveListSettings');

  return async (listSettings: Setting[]) => {
    await create(listSettings);
  };
  
}

const getPost = async (listSettings: Array<string>) => {
  return BaseServerInteractionService.Post<Map<string, any>>(`/getListSettings`, listSettings);
};