import { Box, Checkbox, FormControl, Stack, TextField } from "@mui/material";
import { Fragment, useState } from "react";
import { LabelTextLength, TextLengthCheckbox } from "../ValidationLabels";
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { FieldProps } from "../../../../../../../../types/content/schemas/Fields/NewFieldProp";


export default function TextLengthView({index}:{index: number}){
    const { state } = useLocation();
    const { t } = useTranslation();
    const { control, getValues, register, formState: { errors } } = useFormContext<FieldProps>();
    const textLengthConstraint: Array<any | number> = getValues([`constraints.${index}.textLengthConstraint.minimum`, `constraints.${index}.textLengthConstraint.maximum`]);
    const [isWatchTextLen, setWatchTextLen] =  useState(textLengthConstraint[0] !== null || textLengthConstraint[1] !== null);
    const nameWatch = useWatch({
        control,
        name: 'name'
    });
    return(
        <Fragment>
            <Stack direction='row' alignItems='center' sx={{marginTop: '1em', marginLeft: '1em'}}>
                <Checkbox onChange={() => setWatchTextLen(!isWatchTextLen)} checked={isWatchTextLen} />
                <FormControl sx={{cursor: 'default', width: '100%'}}>
                    <TextLengthCheckbox />  
                </FormControl>
            </Stack>
            {isWatchTextLen &&
            <div style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Box style={{
                    marginLeft: '3em',
                    marginTop: '1em'
                }}>
                    <Controller
                    name={`constraints.${index}.textLengthConstraint.minimum`}
                    control={control}
                    render={({ field }) => (<TextField {...field}
                            variant="outlined" 
                            size='small'
                            type='number'
                            label={<Trans i18nKey={'validMinimum'} />}
                            sx={{
                                marginRight: '1em'
                            }}
                            {...register(`constraints.${index}.textLengthConstraint.minimum`, {
                                min: {
                                    value: 0,
                                    message: t('minValueValidation').replace('%1', '0')
                                },
                                max: {
                                    value: 999999999,
                                    message: t('maxValueValidation').replace('%1', '999999999')
                                },
                                pattern: {
                                    value: /^\d+$/,
                                    message: t('onlyIntegerNumber')
                                }
                            })}
                            helperText={(errors.constraints?.[index] as any)?.textLengthConstraint?.minimum?.message}
                            error={(errors.constraints?.[index] as any)?.textLengthConstraint?.minimum !== undefined}
                            />
                        )}
                    />
                    <Controller
                    name={`constraints.${index}.textLengthConstraint.maximum`}
                    control={control}
                    render={({ field }) => (<TextField {...field}
                            {...register(`constraints.${index}.textLengthConstraint.maximum`, {
                                min: {
                                    value: 0,
                                    message: t('minValidation').replace('%1', '0')
                                },
                                max: {
                                    value: 999999999,
                                    message: t('maxValidation').replace('%1', '999999999')
                                },
                                pattern: {
                                    value: /^\d+$/,
                                    message: t('onlyIntegerNumber')
                                }
                            })}
                            variant="outlined" 
                            size='small'
                            type='number'
                            label={<Trans i18nKey={'validMaximum'} />}
                            helperText={(errors.constraints?.[index] as any)?.textLengthConstraint?.maximum?.message}
                            error={(errors.constraints?.[index] as any)?.textLengthConstraint?.maximum !== undefined}
                            />
                        )}
                    />
                </Box>
                <LabelTextLength schema={state.featureId} field={nameWatch} />
            </div>
            }
        </Fragment>
    )
};