import { BrandingProps } from "../../../types/branding/BrandingProps";
import useGetAll from "../entity/useGetAll";

const useBrandings = () => {
  const entityName = "branding";

  const { status, data, error, isLoading, isFetching, isRefetching, refetch } =
    useGetAll<BrandingProps>(entityName);

  return {
    status,
    brandings: data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default useBrandings;
