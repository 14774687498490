import { SchemaProjectProps } from '../../../../types/content/schemas/SchemaProps';
import useGetAll from '../../entity/useGetAll';

const useSchemas = (company?: string, document?: string) => {
  const entityName = `schemas/project/${company}-${document}`;

  const { status, data, error, isLoading, isFetching, isRefetching, refetch } =
    useGetAll<SchemaProjectProps>(entityName);

  return {
    status,
    schemas: data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default useSchemas;
