import { useSelector } from 'react-redux';
import { LifeCycleProps } from '../../../../types/content/life-cycles/LifeCycleProps';
import useGetAll from '../../entity/useGetAll';
import { PackageProps } from '../../../../types/packages/PackageProps';

const useLifeCycles = () => {
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const entityName = `lifecycles?company=${userPackage.company}&document=${userPackage.document}`;

  const { status, data, error, isLoading, isFetching, isRefetching, refetch } =
    useGetAll<LifeCycleProps>(entityName);

  return {
    status,
    lifeCycles: data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default useLifeCycles;
