import useGetAll from "../../../entity/useGetAll";
import {Permission} from "../../../../../types/content/roles-and-permissions/permissions/Permission"

const useGetPermsList = () => {
  const entityName = "Perms";

  const { status, data, error, isLoading, isFetching, isRefetching, refetch } =
  useGetAll<Permission>(entityName);

  return {
    status,
    PermsList: data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default useGetPermsList;
