import { Paper, Stack } from "@mui/material";
import { Page } from "../../common/page/Page";
import { PageContent } from "../../common/page/PageContent";
import { PageHeader } from "../../common/page/PageHeader";
import { BrandingPageList } from "./BrandingPageList";
import { BrandingCreateDialog } from "./create/BrandingCreateDialog";

export function BrandingPage() {
  return (
    <Page>
      <PageHeader title="Branding" />
      <PageContent subtitle="Configure your application's branding: logo, colors, fonts, borders.">
        <Paper elevation={2} square sx={{ p: 2 }}>
          <Stack spacing={3}>
            <BrandingCreateDialog />
            <BrandingPageList />
          </Stack>
        </Paper>
      </PageContent>
    </Page>
  );
}
