import { ClickAwayListener, IconButton, Paper, Stack, Tooltip, Typography} from '@mui/material';
import { Page } from '../../../../../common/page/Page';
import { PageHeader } from '../../../../../common/page/PageHeader';
import { PageContent } from '../../../../../common/page/PageContent';
import { Trans, useTranslation } from 'react-i18next';
import { PermsListForDoc } from './permsForDoc/PermsListForDoc';
import { useParams } from 'react-router-dom';
import { Fragment, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';

export default function PermForDoc(){
    const {t} = useTranslation();
    return (
        <Page style={{
          overflowX: 'hidden'
          }}>
          <PageHeader title={t('perms_title') + ' (' + useParams().featureId as string + ')'}  />
          <PageContent subtitle={<LabelInfoDoc />}>
            <Paper elevation={2} square sx={{ p: 2 }}>
              <Stack spacing={3}></Stack>
              <PermsListForDoc/>
            </Paper>
          </PageContent>
        </Page>
      );
}

function LabelInfoDoc(){
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Typography variant='body2' sx={{
      marginBottom: 2
     }}>
      <Trans i18nKey={'permsForDoc_description'} />
      <ClickAwayListener onClickAway={() => {setOpen(false);}}>
          <Tooltip
              PopperProps={{
              disablePortal: true,
              }}
              onClose={() => {setOpen(false);}}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={<InfoTitlePermsForDoc/>}
          >
            <IconButton size='small' sx={{width: '30px'}}>
              <InfoIcon onClick={() => {setOpen(true);}}/>
            </IconButton>
          </Tooltip>
      </ClickAwayListener>
    </Typography>
  )
}

function InfoTitlePermsForDoc(){
  return (
    <Fragment>
      <Typography color="inherit">
        <Trans i18nKey={'info_title1'} />
      </Typography>
      <Trans i18nKey={'info_docPerms'} />
      <Typography color="inherit" marginTop={1}>
        <Trans i18nKey={'info_title2'} />
      </Typography>
      <Trans i18nKey={'info_docHowTo'} />
    </Fragment>
  )
}