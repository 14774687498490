import { Page } from "../../common/page/Page";
import { PageContent } from "../../common/page/PageContent";
import { PageHeader } from "../../common/page/PageHeader";
import { Typography } from "@mui/material";
import { UploadAccordion } from "../../common/accordion/UploadAccordion";

export function Resources() {
  return (
    <Page>
      <PageHeader title="Resources Editor" />
      <PageContent subtitle="You can upload resource files used elsewhere in Studio">
        <UploadAccordion title="Images">
          <Typography>
            Images uploaded here are available under the
            <span style={{ fontStyle: "italic" }}> /nuxeo.war/img/ </span>
            path.
          </Typography>
        </UploadAccordion>
        <UploadAccordion
          title="I18n Files"
          inputProps={{ accept: ".properties" }}
        >
          <Typography>
            Internationalization files uploaded here are available on the Nuxeo
            user interface.
            <br />
            You must use proper the i18n file name format:
            <span style={{ fontWeight: 600 }}>
              {" "}
              messages_[lang]_[country].properties{" "}
            </span>
            where [lang] is the two-letter language code and [country] is the
            two-letter country code (for example: messages_en_US.properties,
            messages_fr_FR.properties, etc).
          </Typography>
        </UploadAccordion>
        <UploadAccordion title="Schemas" inputProps={{ accept: ".xsd" }}>
          <Typography>
            XSD Schemas uploaded here can be used to initialize Nuxeo Schemas,
            but can also be referenced by a Nuxeo Schema. These XSD files are
            deployed if they are referenced by a contribution.
          </Typography>
        </UploadAccordion>
        <UploadAccordion title="Vocabularies" inputProps={{ accept: ".csv" }}>
          <Typography>
            CSV files uploaded here can be referenced by Nuxeo Vocabularies.
          </Typography>
        </UploadAccordion>
      </PageContent>
    </Page>
  );
}
