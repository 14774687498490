import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { Page } from '../../../common/page/Page';
import { PageHeader } from '../../../common/page/PageHeader';
import { PageContent } from '../../../common/page/PageContent';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import useLifeCycle from '../../../../serverInteraction/hooks/content/life-cycles/useLifeCycle';
import { Loader } from '../../../common/loader/Loader';
import { AxiosError } from 'axios';
import StateBlock from './components/StateBlock';
import { TestCanvas } from '../test_dnd/TestCanvas';

type Params = {
  id: string;
};

type StateProps = {
  height: number;
  initial: boolean;
  posX: number;
  posY: number;
  stateDescription: string;
  stateId: number;
  stateName: string;
  width: number;
  backgroundColor: string;
  border: string;
};

type DrawingLine = {
  startPoint: { x: number; y: number };
  endPoint: { x: number; y: number };
};

export const LifeCyclePage = () => {
  const { id } = useParams<Params>();
  const { control, handleSubmit, register } = useForm();
  const { status, lifeCycle, isLoading, isFetching, error } = useLifeCycle(`${id}`);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'states',
  });

  const [drawingLines, setDrawingLines] = useState<DrawingLine[]>([]);

  const handleDrawLine = (
    startPoint: { x: number; y: number },
    endPoint: { x: number; y: number },
  ) => {
    setDrawingLines([...drawingLines, { startPoint, endPoint }]);
  };

  const clearDrawingLines = () => {
    setDrawingLines([]);
  };

  if (isLoading || isFetching || lifeCycle === undefined) {
    return <Loader />;
  }

  if (status === 'error' && error instanceof AxiosError) {
    return (
      <Typography variant="h6" align="center">
        Error: {error.message}
      </Typography>
    );
  }

  const { lifeCycleId, links, states } = JSON.parse(`${lifeCycle}`);

  const handleCreateState = (stateColor?: string) => {
    const defaultState = {
      height: 40,
      position: 'absolute',
      left: 0,
      top: 0,
      stateDescription: 'default description',
      stateId: Number(Math.random().toFixed(2)),
      width: 100,
      backgroundColor: 'lightblue',
      border: '1px solid blue',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '5px',
    };

    const stateOptions =
      stateColor === 'initial'
        ? { ...defaultState, stateName: 'Initial state', initial: true }
        : {
            ...defaultState,
            stateName: 'State',
            backgroundColor: 'transparent',
            border: '1px solid #000',
            initial: false,
          };

    append(stateOptions);
  };

  const removeAllContainers = () => {
    const containers = document.querySelectorAll('.state_container');

    containers && containers.forEach((container) => container.remove());
  };

  return (
    <Page style={{ height: '100%' }}>
      <PageHeader title="Document Schema" />
      <PageContent subtitle="DND">
        <Paper elevation={2} square sx={{ p: 2, overflow: 'hidden' }}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              <Button onClick={() => handleCreateState('initial')}>Initial State</Button>
              <Button onClick={() => handleCreateState('state')}>State</Button>
              <Button onClick={removeAllContainers}>Remove All</Button>
            </Box>
            <Button onClick={removeAllContainers}>Save</Button>
          </Box>
          <Stack spacing={3} sx={{ position: 'relative' }}>
            <form
              id="life_cycle_form"
              style={{ position: 'relative', minHeight: '500px', backgroundColor: '#f3f3f3' }}
            >
              <>
                <Box>
                  <TestCanvas drawLines={drawingLines} onDrawLine={handleDrawLine} />
                </Box>
                {
                  ////////////////////////// ONLOAD /////////////////////////////
                  states.map((state: any, index: number) => {
                    state = {
                      position: 'absolute',
                      backgroundColor: state.initial ? 'lightblue' : '#fff',
                      border: state.initial ? '1px solid blue' : '1px solid #000',
                      borderRadius: '4px',
                      display: 'flex',
                      alignItems: 'center',
                      // justifyContent: 'center',
                      justifyContent: 'space-between',
                      width: 'auto',
                      minWidth: 100,
                      height: '40px',
                      padding: '5px',
                      top: `${state.posY}px`,
                      left: `${state.posX}px`,
                      ...state,
                    };

                    return <StateBlock key={index} style={state} value={state.stateDescription} />;
                  })
                }
              </>
              <>
                {
                  ////////////////////////// ONCLICK /////////////////////////////
                  fields.map((field: any, index) => {
                    return <StateBlock key={index} style={field} value={field.stateName} />;
                  })
                }
              </>
            </form>
          </Stack>
        </Paper>
      </PageContent>
    </Page>
  );
};
