import { CreateProps } from '../../types/services/entity/CreateProps';
import { RemoveProps } from '../../types/services/entity/RemoveProps';
import { UpdateProps } from '../../types/services/entity/UpdateProps';
import BaseServerInteractionService from './BaseServerInteractionService';

const get = async <T>(entityName: string, params: string) => {
  return BaseServerInteractionService.Get<T>(`/${entityName}?${params}`);
};

const getAll = async <T>(entityName: string) => {
  return BaseServerInteractionService.List<T[]>(`/${entityName}`);
};

const create = async <T1, T2>({ entityName, createProps }: CreateProps<T1>) => {
  return BaseServerInteractionService.Post<T2>(`/${entityName}`, createProps);
};

const update = async <T1, T2>({ entityName, updateProps }: UpdateProps<T1>) => {
  return BaseServerInteractionService.Put<T2>(`/${entityName}`, updateProps);
};

const remove = async <T1, T2>({ entityName, removeProps }: RemoveProps<T1>) => {
  return BaseServerInteractionService.Delete<T2>(`/${entityName}`, removeProps);
};

const EntityService = {
  get,
  getAll,
  create,
  update,
  remove,
};

export default EntityService;
