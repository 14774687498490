import { Checkbox, FormControl, Stack } from "@mui/material"
import { Controller, useWatch } from "react-hook-form"
import { Inforequired, LabelRequired } from "../ValidationLabels";

interface IProps {
    schemaName: string, 
    fieldName: string,
    index: number, 
    fieldIndex: number,
    control: any

}

export default function RequiredView({schemaName, fieldName, index, fieldIndex, control}: IProps){
    const watchParams = useWatch({
        control,
        name: [`fields.${fieldIndex}.constraints.${index}.mandatoryConstraint.value`]
    });

    return (
        <>
            <Stack direction='row' alignItems='center' sx={{marginLeft: '1em'}}>
                <Controller
                        name={`fields.${fieldIndex}.constraints.${index}.mandatoryConstraint.value`}
                        control={control}
                        render={({ field: { onChange, value } }) => <Checkbox onChange={onChange} checked={value} />}
                />
                <FormControl sx={{cursor: 'default', width: '100%'}}>
                    <Inforequired />  
                </FormControl>
            </Stack>
            {watchParams[0] && <LabelRequired schema={schemaName} field={fieldName}/>}
        </>
        )
}