import { Box, Button, Stack } from "@mui/material";
import { useState } from "react";
import { ChromePicker } from "react-color";

const styles = {
  default: {
    picker: { boxShadow: "none", width: 300 },
  },
};

type Props = {
  value: string;
  handleChange: (value: string) => void;
  handleClose: () => void;
};

export function PickDialog({ value, handleChange, handleClose }: Props) {
  const [color, setColor] = useState<string>(value);

  const handlePick = () => {
    handleChange(color);
    handleClose();
  };

  return (
    <Box>
      <ChromePicker
        color={color}
        onChange={(color) => setColor(color.hex)}
        styles={styles}
      />
      <Stack
        direction="row-reverse"
        spacing={3}
        alignItems="center"
        justifyContent="center"
        sx={{ p: 2 }}
      >
        <Button variant="contained" onClick={handlePick} sx={{ width: 100 }}>
          OK
        </Button>
        <Button variant="outlined" onClick={handleClose} sx={{ width: 100 }}>
          Cancel
        </Button>
      </Stack>
    </Box>
  );
}
