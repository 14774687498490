import { useSelector } from "react-redux";
import { GetDocTypesProps } from "../../../../types/content/documentTypes/GetDocTypesProps";
import useGetAll from "../../entity/useGetAll";
import { PackageProps } from "../../../../types/packages/PackageProps";
import { DOCTYPES_ENDPOINT } from "../../../services/endpointsConstants";

const useDocTypes = () => {
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const entityName = `${DOCTYPES_ENDPOINT}?company=${userPackage.company}&document=${userPackage.document}`;

  const { status, data, error, isLoading, isFetching, isRefetching, refetch } =
    useGetAll<GetDocTypesProps>(entityName);

  return {
    status,
    documentTypes: data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default useDocTypes;
