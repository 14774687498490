import { Stack, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import IconMoreInfo from "../../../../../common/button/iconButton/MoreInfo";
import { t } from "i18next";
import { labelFieldValid } from "../../../../../../staticValues/Constants";

type LabelProps = {
    schema: string;
    field: string
}

//isRequired
export function Inforequired(){
    const { t } = useTranslation();

    return (
        <Typography>
            <Trans i18nKey={'validRequired'} />
            <IconMoreInfo title={t('validDescription')} style={{
                marginLeft: '0.5em'
            }}
            placement={'right'}
            />
        </Typography>
    );
}

export function LabelRequired({schema, field}: LabelProps){
    return(
        <Stack direction='row' sx={{
            marginLeft: '3em',
            alignItems: 'center'
        }}>
            {t('validationkey')}:
            <div style={{
                marginLeft: '1em',
                fontWeight: 'bold'
            }}>
            {`${labelFieldValid}.NotNullConstraint.${schema}.${field}`}
            <IconMoreInfo title={<Trans i18nKey={'validRequieredDescr'}/>} style={{
                marginLeft: '0.5em'
            }}
            placement={'right'}
            />
            </div>
        </Stack>
    )
};

//isListRequired
export function InfoListRequired(){
    const { t } = useTranslation();
    return (
        <Typography>
            <Trans i18nKey={'validListElementsRequired'} />
            <IconMoreInfo title={t('validListElementsRequiredDescr')} style={{
                marginLeft: '0.5em'
            }}
            placement={'right'}
            />
        </Typography>
    );
}

//isTextFormat
export function TextFormatCheckbox(){
    const { t } = useTranslation();

    return (
        <Typography>
            {t('validTextFormat')}
            <IconMoreInfo title={t('TextFormatDescr')} style={{
                marginLeft: '0.5em'
            }}
            placement={'right'}
            />
        </Typography>
    );
}

export function LabelTextFormat({schema, field}: LabelProps){
    const { t } = useTranslation();
    return(
        <Stack direction='row' sx={{
            alignItems: 'center'
        }}>
            {t('validationkey')}:
            <div style={{
                marginLeft: '1em',
                fontWeight: 'bold'
            }}>
            {`${labelFieldValid}.PatternConstraint.${schema}.${field}`}
            <IconMoreInfo title={t('validRequieredDescr')} style={{
                marginLeft: '0.5em'
            }} placement={'right'} />
            </div>
        </Stack>
    )
};

//isLengthText
export function TextLengthCheckbox(){
    const { t } = useTranslation(); 
    return (
        <Typography>
            {t('validTextLength')}
            <IconMoreInfo title={t('validTextLengthDescr')} style={{
                marginLeft: '0.5em'
            }}
            />
        </Typography>
    );
}

export function LabelTextLength({schema, field}: LabelProps){
    const { t } = useTranslation(); 
    return(
        <Stack direction='row' sx={{
            marginLeft: '3em',
            marginTop: '1em',
            alignItems: 'center'
        }}>
            {t('validationkey')}:
            <div style={{
                marginLeft: '1em',
                fontWeight: 'bold'
            }}>
            {`${labelFieldValid}.LengthConstraint.${schema}.${field}`}
            <IconMoreInfo title={t('validRequieredDescr')} style={{
                marginLeft: '0.5em'
            }}
            placement={'right'}
            />
            </div>
        </Stack>
    )
};

//isRangeText
export function TextRangeCheckbox(){
    return (
        <Typography>
            <Trans i18nKey={'validNumRange'} />
            <IconMoreInfo title={<Trans i18nKey={'numRangeDescr'} />} style={{
                marginLeft: '0.5em'
            }}
            placement={'right'}
            />
        </Typography>
    );
}
