import { PropsWithChildren, useState, CSSProperties } from 'react';
import { AddButton } from '../button/AddButton';
import { SimpleModal } from '../modal/SimpleModal';
import { Stack, Button, BoxProps, Box } from '@mui/material';
import { Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';

type Props = {
  title: string;
  add?: () => Promise<void>;
  validate?: () => Promise<boolean>;
  isCreate?: () => void;
  style?: CSSProperties;
} & BoxProps &
  PropsWithChildren;

export function AddDialog({ title, add, validate, children, isCreate, style, ...props }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const handleAdd = async () => {
    if (validate) {
      const result = await validate();
      if (result) {
        setIsOpen(false);

        if (add) {
          await add();
          isCreate && isCreate();
        }
      }
    }
  };

  return (
    <div>
      <AddButton onClick={() => setIsOpen(true)} variant="contained" style={style}>
        <Trans i18nKey={'new'}></Trans>
      </AddButton>
      <SimpleModal title={title} isOpen={isOpen} handleClose={() => setIsOpen(false)}>
        <Box {...props}>
          {children}
          <Stack
            direction="row-reverse"
            spacing={3}
            alignItems="right"
            justifyContent="end"
            sx={{ pt: 3 }}
          >
            <Button variant="contained" onClick={handleAdd} sx={{ width: 150 }}>
              OK
            </Button>
            <Button variant="outlined" onClick={() => setIsOpen(false)} sx={{ width: 150 }}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </SimpleModal>
    </div>
  );
}
