import {
  Box,
  Input,
  TextareaAutosize,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { AddDialog } from '../../../../common/dialog/AddDialog';
import useCreate from '../../../../../serverInteraction/hooks/entity/useCreate';
import { Controller, useForm } from 'react-hook-form';
import { ControlledInput } from '../../../../common/input/ControlledInput';
import { GridOneToThree, GridOneToThreeItemProps } from '../../../../common/grid/GridOneToThree';
import { CreateWorkflowProps } from '../../../../../types/content/workflows/CreateWorkflowProps';
import { WorkflowProps } from '../../../../../types/content/workflows/WorkflowProps';
import { SimpleAccordion } from '../../../../common/accordion/SimpleAccordion';
import { ControlledRadioGroup } from '../../../../common/radioGroup/ControlledRadioGroup';
import { ControlledSingleResource } from '../../../../common/resource/ControlledSingleResource';
import { ControlledTextarea } from '../../../../common/textarea/ControlledTextarea';
import { CreateFacetsProps } from '../../../../../types/content/facets/CreateFacetsProps';
import { FacetProps } from '../../../../../types/content/facets/FacetProps';
import useFacets from '../../../../../serverInteraction/hooks/content/facets/useFacets';

export function FacetsCreateDialog() {
  const { create } = useCreate<CreateFacetsProps, FacetProps>('facets');
  const { refetch } = useFacets();
  // const { translate } = useEnumHelper();

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    formState: { errors },
  } = useForm<CreateFacetsProps>();

  const createItems: GridOneToThreeItemProps[] = [
    {
      title: 'Feature ID',
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register('facetId', {
              required: {
                value: true,
                message: 'ID is required',
              },
              minLength: {
                value: 3,
                message: 'Must be at least 3 symbols',
              },
            }),
          }}
          helperText={errors.facetId?.message}
          error={errors.facetId !== undefined}
          sx={{ width: '100%' }}
        />
      ),
    },
    {
      title: 'Label',
      required: false,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register('label', {}),
          }}
          helperText={errors.label?.message}
          error={errors.label !== undefined}
          sx={{ width: '100%' }}
        />
      ),
    },
    {
      title: 'Description',
      required: false,
      content: (
        <ControlledTextarea
          register={{
            ...register('description', {}),
            maxRows: 4,
            minRows: 3,
          }}
        />
      ),
    },
  ];

  // const watchMode = watch('mode');

  const onSubmit = async (newFacet: CreateFacetsProps) => {
    await create(newFacet);
  };

  const validate = async () => {
    return await trigger();
  };

  return (
    <AddDialog
      title="Create Facet"
      add={handleSubmit(onSubmit)}
      validate={validate}
      isCreate={() => refetch()}
    >
      <hr />
      <Box sx={{ minHeight: 500 }}>
        <GridOneToThree items={createItems} />
      </Box>
    </AddDialog>
  );
}
