import {Group} from "../../../../../types/content/roles-and-permissions/users&groups/Group";
import useGetAll from "../../../entity/useGetAll";

const useGetAllGroups = () => {
    const entityName = "Groups";
    const { status, data, error, isLoading, isFetching, isRefetching, refetch } = useGetAll<Group>(entityName);
  
    return {
      status,
      GroupsList: data,
      error,
      isLoadingGroups: isLoading,
      isFetchingGroups: isFetching,
      isRefetching,
      refetch,
    };
  };
  
  export default useGetAllGroups;