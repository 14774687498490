import {
  Box,
  Button,
  Popover,
  Stack,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useState } from "react";
import { PickDialog } from "./PickDialog";

type Props = {
  value: string;
  handleChange: (value: string) => void;
} & TextFieldProps;

export function ColorPicker({ value, handleChange, ...props }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Stack
      direction="row"
      spacing={3}
      sx={{ display: "flex", alignItems: "center" }}
    >
      <TextField
        sx={{ width: 150 }}
        inputProps={{ style: { padding: "0.5rem 1rem " } }}
        value={value}
        onChange={(event) => handleChange(event.currentTarget.value)}
        {...props}
      />
      <Button
        variant="contained"
        sx={{
          fontSize: "0.75rem",
          px: 2,
          height: "2rem",
        }}
        onClick={handleClick}
      >
        Pick
      </Button>
      <Box
        sx={{
          width: "1.6rem",
          height: "1.6rem",
          backgroundColor: value,
          borderRadius: 5,
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ ml: 3 }}
      >
        <PickDialog
          value={value}
          handleChange={handleChange}
          handleClose={handleClose}
        />
      </Popover>
    </Stack>
  );
}
