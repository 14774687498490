import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import UpdateIcon from '@mui/icons-material/Update';

type Props = IconButtonProps;

export function UpdateIconButton({ ...props }: Props) {
  return (
    <Tooltip title="Update">
      <IconButton aria-label="Update" {...props}>
        <UpdateIcon />
      </IconButton>
    </Tooltip>
  );
}
