import { Paper, Stack } from '@mui/material';
import { Page } from '../../../common/page/Page';
import { PageContent } from '../../../common/page/PageContent';
import { PageHeader } from '../../../common/page/PageHeader';
import { LifeCyclesCreateDialog } from './create/LifeCyclesCreateDialog';
import { LifeCyclesPageList } from './LifeCyclesPageList';

export const LifeCyclesPage = () => {
  return (
    <Page>
      <PageHeader title="Life Cycle" />
      <PageContent subtitle="Each content type has a life cycle, meaning some predefined states in which it can be. For example, an invoice can be draft, open, reconciled, or stand-by. Here you can design custom lifecycles, using the graphical editor.">
        <Paper elevation={2} square sx={{ p: 2 }}>
          <Stack spacing={3}></Stack>
          <LifeCyclesCreateDialog />
          <LifeCyclesPageList />
        </Paper>
      </PageContent>
    </Page>
  );
};
