import { Link, Stack, Typography, Box } from '@mui/material';
import { Loader } from '../../../../../common/loader/Loader';
import { AxiosError } from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import { theme } from '../../../../../../styling/Theme';
import useGetPermList from '../../../../../../serverInteraction/hooks/content/roles-and-permissions/permissions/useGetPermList';
import {Permission} from '../../../../../../types/content/roles-and-permissions/permissions/Permission';
import { DeleteDialogPerms } from './dialogsPerms/DeleteDialogPerms';
import { UpdateDialogPerms } from './dialogsPerms/UpdateDialogPermis';
import { CopyDialogPerms } from './dialogsPerms/CopyDialogPerms';
import { getListFeaturesId} from '../../../../../../utils/CommonUtils';
import { useMemo } from 'react';


export function PermissionsTable() {

    const { status, PermsList, isLoading, isFetching, error, refetch } = useGetPermList();
    const featureList: String[] = useMemo(() => {
      if(PermsList === undefined) return []
      return getListFeaturesId(JSON.parse(`${PermsList}`))
    }, [PermsList]);

    if (isLoading || isFetching || PermsList === undefined) {
        return <Loader />;
    }

    if (status === 'error' && error instanceof AxiosError) {
        return (
            <Typography variant="h6" align="center">
                Error: {error.message}
            </Typography>
        );
    }

    return (
        <Box>
          <Typography
            sx={{
              fontWeight: 600,
              backgroundColor: theme.palette.grey[200],
              py: 1,
              px: 2,
            }}
          >
            Name
          </Typography>
          <Stack>
            {JSON.parse(PermsList?.toString()).map((Perms: Permission) => (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ py: 1, px: 2 }}
                key={Perms.id}
              >
                <Link underline="hover" component={RouterLink} to={`${Perms.featureId}`}>
                  {Perms.featureId}
                </Link>
                <Stack justifyContent="space-between" alignItems="right" spacing={2} direction="row">
                  <CopyDialogPerms Perms={Perms} refetch={() => refetch()} FeaturePermsList={featureList} />
                  <UpdateDialogPerms Perms={Perms} refetch={() => refetch()} FeaturePermsList={featureList} />
                  <DeleteDialogPerms Perms={Perms} onDelete={() => refetch()} />
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Box>
    );
    
}