import {
  Box,
  Button,
  InputBase,
  InputBaseComponentProps,
  Stack,
} from "@mui/material";
import { SimpleAccordion } from "./SimpleAccordion";
import { PropsWithChildren } from "react";

type Props = {
  title: string;
  inputProps?: InputBaseComponentProps;
  onUpload?: () => void;
} & PropsWithChildren;

export function UploadAccordion({ title, inputProps, children }: Props) {
  return (
    <SimpleAccordion title={title} elevation={2}>
      <Box>{children}</Box>
      <Stack direction="row" spacing={3}>
        <InputBase type="file" inputProps={inputProps} />
        <Button variant="contained" sx={{ height: 28 }}>
          Upload
        </Button>
      </Stack>
    </SimpleAccordion>
  );
}
