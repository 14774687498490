import { Box, FormLabel, Stack } from "@mui/material";
import { PropsWithChildren, ReactNode } from "react";

type Props = {
  subtitle: string | ReactNode;
} & PropsWithChildren;

export function PageContent({ subtitle, children }: Props) {
  return (
    <Box sx={{ py: 2, px: 3 }}>
      <Stack>
        <FormLabel>{subtitle}</FormLabel>
        <Stack spacing={3} sx={{ py: 3 }}>
          {children}
        </Stack>
      </Stack>
    </Box>
  );
}
