import {Box} from '@mui/material'
import { ControlledInput } from '../../../../../../common/input/ControlledInput';
import useCreate from '../../../../../../../serverInteraction/hooks/entity/useCreate';
import { GridOneToThree, GridOneToThreeItemProps } from '../../../../../../common/grid/GridOneToThree';
import { useForm } from 'react-hook-form';
import {Permission} from '../../../../../../../types/content/roles-and-permissions/permissions/Permission';
import { AddDialog } from '../../../../../../common/dialog/AddDialog';
import useGetPermsList from '../../../../../../../serverInteraction/hooks/content/roles-and-permissions/permissions/useGetPermList';
import { getListFeaturesId} from '../../../../../../../utils/CommonUtils';
import { useMemo } from 'react';

let featureList: Array<String>;

export function CreateDialogPerms() {
  const {create} = useCreate<Permission, Permission>('savePerm');
  const {PermsList, refetch} = useGetPermsList();
  featureList = useMemo(() => {
    if(PermsList === undefined) return []
    return getListFeaturesId(JSON.parse(`${PermsList}`))
  }, [PermsList]);

    
  const {
          control,
          register,
          handleSubmit,
          trigger,
          formState: { errors },
      } = useForm<Permission>();

        const createItems: GridOneToThreeItemProps[] = [
            {
              title: 'Feature ID',
              required: true,
              content: (
                <ControlledInput
                  type="text"
                  register={{
                    ...register('featureId', {
                      required: {
                        value: true,
                        message: 'Feature ID is required',
                      },
                      minLength: {
                        value: 3,
                        message: 'Must be at least 3 symbols',
                      },
                      maxLength:{
                        value: 40,
                        message: 'Must be at more 40 symbols',
                      },
                      pattern:{
                        value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                        message: "Only alphanumeric characters, '_' and '-' are allowed in the IDs. The ID must start with a letter.",
                      },
                      validate: value => featureList.indexOf(value) === -1 || 'FeatureId is not unique',
                    }),
                  }}
                  helperText={errors.featureId?.message}
                  error={errors.featureId !== undefined}
                  sx={{ width: '100%' }}
                />
              ),
            }
        ];
    const onSubmit = async (newPerms: Permission) => {
        await create(newPerms);
    };

    const validate = async () => {
        return await trigger();
    };
    
    return (
    <AddDialog title="Create Permission" add={handleSubmit(onSubmit)} validate={validate} isCreate={() => refetch()} style={{marginBottom: 10}}>
        <hr />
        <Box sx={{height: "auto", marginTop: 1}}>
        <GridOneToThree items={createItems} />
        </Box>
    </AddDialog>
    );
}
