import { Box } from '@mui/material';
import useRemove from '../../../../../serverInteraction/hooks/entity/useRemove';
import { DeleteDialog } from '../../../../common/dialog/DeleteDialog';
import useAlert from '../../../../../context/alertContext/useAlert';
import { useSelector } from 'react-redux';
import { PackageProps } from '../../../../../types/packages/PackageProps';
import { RemoveDocTypesProps } from '../../../../../types/content/documentTypes/RemoveDocTypesProps';
import { DOCTYPES_ENDPOINT } from '../../../../../serverInteraction/services/endpointsConstants';

type Props = {
  featureId: string;
  onDelete?: () => void;
};

export function DocumentTypesDeleteDialog({ featureId, onDelete }: Props) {
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const { showSuccess } = useAlert();
  const onSuccess = (result: boolean) => {
    if (result) {
      showSuccess('Deleted successfully!');
      onDelete && onDelete();
    }
  };

  const { remove } = useRemove(DOCTYPES_ENDPOINT, onSuccess);
  const handleDelete = async () => {
    const doctypeForDelete: RemoveDocTypesProps = {
      featureId: featureId,
      company: userPackage.company,
      document: userPackage.document,
    }
    try {
      await remove(doctypeForDelete);
      if(onDelete)
        onDelete()
    } catch (error: any ) {
      console.log(error);
    }
  };

  return (
    <DeleteDialog title={`Delete Document Types ${featureId}`} remove={handleDelete}>
      <Box>{`Are you sure to delete the feature ${featureId}?`}</Box>
    </DeleteDialog>
  );
}
