import { Paper, Stack } from '@mui/material';
import { Page } from '../../../../common/page/Page';
import { PageContent } from '../../../../common/page/PageContent';
import { PageHeader } from '../../../../common/page/PageHeader';
import { Trans } from 'react-i18next';
import { PermissionsTable } from './impl/PermissionsTable';
import { CreateDialogPerms } from './impl/dialogsPerms/CreateDialogPerms';


export default function PermissionsPage() {
    return (
      <Page>
        <PageHeader title={<Trans i18nKey={'perms_title'} />} />
        <PageContent subtitle={<Trans i18nKey={'perms_description'} />}>
          <Paper elevation={2} square sx={{ p: 2 }}>
            <Stack spacing={3}></Stack>
            <CreateDialogPerms/>
            <PermissionsTable/>
          </Paper>
        </PageContent>
      </Page>
    );
  }