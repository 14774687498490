import { FacetProps } from '../../../../types/content/facets/FacetProps';
import useGet from '../../entity/useGet';

const useFacet = (id: string) => {
  const entityName = 'facets';

  const { status, data, error, isLoading, isFetching, isRefetching, refetch } = useGet<FacetProps>(
    entityName,
    id,
  );

  return {
    status,
    facet: data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default useFacet;
