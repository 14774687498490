import { Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { SelectField } from "../../../../../../types/common/SelectField";
import IconMoreInfo from "../../../../../common/button/iconButton/MoreInfo";
import { ControlledSelect } from "../../../../../common/select/ControlledSelect";

type Props = {
    listPolicy: Array<string>;
    selectedValue: string;
}

export type UsersSettingsProps = {
    createPolicy: string;
    dataLoadingPolicy: string;
}

let currentSettings: UsersSettingsProps = {
    createPolicy: '',
    dataLoadingPolicy: ''
};

export function UsersSettings({settings}: {settings: Map<string, any>}){
    useEffect(() => {
        currentSettings.createPolicy = settings.get('createPolicy');
        currentSettings.dataLoadingPolicy = settings.get('dataLoadingPolicy');
      }, [])
    const [isVisible, setVisible] = useState<boolean>(settings.get('createPolicy') === 'on_missing_columns');
    return (
        <Fragment>
            <Typography>
                <TablePolicy listPolicy={settings.get('listCreateUsers')} selectedValue={settings.get('createPolicy')} visibleChange={(isVisible: boolean) => setVisible(isVisible)}/>
            </Typography>
            {isVisible ? 
                <Typography>
                    <LoadingPolicy listPolicy={settings.get('listLoadingPolicy')} selectedValue={settings.get('dataLoadingPolicy')}/>
                </Typography> :
                null
            }
        </Fragment>
    )
}

function TablePolicy({listPolicy, selectedValue, visibleChange}: Props & {visibleChange: (isVisible: boolean) => void}){
    let listSelectValue: Array<SelectField> = [];
    const handlerChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) =>{
        currentSettings.createPolicy = event.target.value;
        visibleChange(event.target.value === 'on_missing_columns');
    };
    listPolicy.forEach((item: string) => (listSelectValue.push({value: item, label: "usersGroups_" + item})));
    return (
        <Fragment>
            <Trans i18nKey={'usersGroups_tablePolicy'} />
            <IconMoreInfo title={<TablePolicyDescription />}/>
            <ControlledSelect valueList={listSelectValue} isTranslate={true} size="small" selectedValue={selectedValue} onChange={handlerChangeEvent}/>
        </Fragment>
    )
}


function LoadingPolicy({listPolicy, selectedValue}: Props){
    const handlerChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => currentSettings.dataLoadingPolicy = event.target.value;
    let listSelectValue: Array<SelectField> = [];
    listPolicy.forEach((item: string) => (listSelectValue.push({value: item, label: "usersGroups_" + item})));
    return (
        <Fragment>
            <Trans i18nKey={'usersGroups_loadingPolicy'} />
            <IconMoreInfo title={<LoadinghPolicyDescription/>}/>
            <ControlledSelect valueList={listSelectValue} isTranslate={true} size="small" sx={{marginLeft: '5px', width: 250}} selectedValue={selectedValue} onChange={handlerChangeEvent}/>
        </Fragment>
    )
}

function TablePolicyDescription(){
    const {t} = useTranslation();
    return (
        <ul>
            <li>{t('usersGroups_tablePolicyDescr')}</li>
            <li>• {t('usersGroups_never_descr')}</li>
            <li>• {t('usersGroups_always_descr')}</li>
            <li>• {t('usersGroups_on_missing_columns_descr')}</li>
        </ul>
      )
}

function LoadinghPolicyDescription(){
    const {t} = useTranslation();
    return (
        <ul>
            <li>{t('usersGroups_loadingPolicyDescr')}</li>
            <li>• {t('usersGroups_legacy_descr')}</li>
            <li>• {t('usersGroups_never_load_descr')}</li>
            <li>• {t('usersGroups_update_duplicate_descr')}</li>
            <li>• {t('usersGroups_skip_duplicate_descr')}</li>
            <li>• {t('usersGroups_reject_duplicate_descr')}</li>
        </ul>
      )
}

export function getSettingsUsers(): UsersSettingsProps {
    return currentSettings;
}