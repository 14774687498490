import useGet from "../../../entity/useGet";
import { PermsForDoc} from "../../../../../types/content/roles-and-permissions/permissions/PermsForDoc";

const useGetPermsForDoc = (featureId: string) => {
  
    const { status, data, error, isLoading, isFetching, isRefetching, refetch } = useGet<PermsForDoc>("docPerms", featureId);
  
    return {
      status,
      ListPerms: data,
      error,
      isLoading,
      isFetching,
      isRefetching,
      refetch,
    };
  };
  
  export default useGetPermsForDoc;