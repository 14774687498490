import { useForm } from 'react-hook-form';
import useCreate from '../../../../../serverInteraction/hooks/entity/useCreate';
import { CreateLifeCyclesProps } from '../../../../../types/content/life-cycles/CreateLifeCyclesProps';
import { LifeCycleProps } from '../../../../../types/content/life-cycles/LifeCycleProps';
import { GridOneToThree, GridOneToThreeItemProps } from '../../../../common/grid/GridOneToThree';
import { ControlledInput } from '../../../../common/input/ControlledInput';
import { AddDialog } from '../../../../common/dialog/AddDialog';
import { Box } from '@mui/material';
import useLifeCycles from '../../../../../serverInteraction/hooks/content/life-cycles/useLifeCycles';

export const LifeCyclesCreateDialog = () => {
  const { create } = useCreate<CreateLifeCyclesProps, LifeCycleProps>('life-cycle');
  const { refetch } = useLifeCycles();

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    formState: { errors },
  } = useForm<CreateLifeCyclesProps>();

  const createItems: GridOneToThreeItemProps[] = [
    {
      title: 'Feature ID',
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register('lifeCycleId', {
              required: {
                value: true,
                message: 'ID is required',
              },
              minLength: {
                value: 3,
                message: 'Must be at least 3 symbols',
              },
            }),
          }}
          helperText={errors.lifeCycleId?.message}
          error={errors.lifeCycleId !== undefined}
          sx={{ width: '100%' }}
        />
      ),
    },
  ];

  const onSubmit = async (newLifeCycle: CreateLifeCyclesProps) => {
    await create(newLifeCycle);
  };

  const validate = async () => {
    return await trigger();
  };

  return (
    <AddDialog
      title="Create Life-Cycle"
      add={handleSubmit(onSubmit)}
      validate={validate}
      isCreate={() => refetch()}
    >
      <hr />
      <Box sx={{ minHeight: 500 }}>
        <GridOneToThree items={createItems} />
      </Box>
    </AddDialog>
  );
};
