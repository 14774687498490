import { useForm, Controller, useFieldArray, FormProvider, useFormContext } from 'react-hook-form';
import {
  Button,
  TextField,
  Box,
  Typography,
  Paper,
  Stack,
  FormControl,
  FormControlLabel,
  Tooltip,
  IconButton,
  Checkbox,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import { Page } from '../../../common/page/Page';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '../../../common/page/PageHeader';
import { PageContent } from '../../../common/page/PageContent';
import { Loader } from '../../../common/loader/Loader';
import { Trans, useTranslation } from 'react-i18next';
import { createContext, Fragment, useContext } from 'react';
import IconMoreInfo from '../../../common/button/iconButton/MoreInfo';
import { theme } from '../../../../styling/Theme';
import { DeleteIconButton } from '../../../common/button/iconButton/DeleteIconButton';
import { ControlledSelect } from '../../../common/select/ControlledSelect';
import useAlert from '../../../../context/alertContext/useAlert';
import useUpdate from '../../../../serverInteraction/hooks/entity/useUpdate';
import { FieldsSelectValue } from '../../../../staticValues/Constants';
import useFieldsSchema from '../../../../serverInteraction/hooks/content/schemas/useFieldsSchema';
import { SchemaWithFieldsProps } from '../../../../types/content/schemas/SchemaWithFieldsProps';
import useSchemaWithFields from '../../../../serverInteraction/hooks/content/schemas/useSchemaWithFields';
import { convertSchemaFieldsJsonToSchemaFieldsType } from '../../../../utils/CommonUtils';
import { SchemaProps } from '../../../../types/content/schemas/SchemaProps';

const FormContext = createContext<SchemaWithFieldsProps>({
  id: 0,
  featureId: '',
  prefix: '',
  companyName: '',
  documentName: '',
  isImported: false,
  fields: []
});

const SchemaFieldsForm = () => {
  const { state } = useLocation();
  if(state.schemaFullHierarchy){
    return (
      <FormContext.Provider
        value={state.schemaFullHierarchy}
      >
        <Form />
      </FormContext.Provider>
    );
  };

  return <GetSchemaForm state={state} />;
};

function GetSchemaForm({ state }:{state: SchemaProps}){
  const { schema, isLoading, isFetching } = useSchemaWithFields(state);
  if (isLoading || isFetching || schema === undefined) {
    return <Loader />;
  }

  return (
    <FormContext.Provider value={convertSchemaFieldsJsonToSchemaFieldsType(JSON.parse(schema.toString()))} >
      <Form />
    </FormContext.Provider>
  );
}

function Form() {
    const { t } = useTranslation();
    const formFields = useContext(FormContext);
    
    const formMethods = useForm<SchemaWithFieldsProps>({
        defaultValues: formFields
    });
    const { control, register, formState: { errors } } = formMethods;

    return (
        <FormProvider {...formMethods}>
            <Page>
            <PageHeader title={`${t('schemaName')} ${formFields.featureId}`}></PageHeader>
            <PageContent subtitle={<SchemaTitle />}>
                <Paper elevation={2} square sx={{ p: 2 }}>
                <Stack spacing={3}></Stack>
                <Box display="flex" marginBottom="10px">
                    <Box display="flex" alignItems="center" sx={{ paddingRight: '1em' }}>
                        <Controller
                            name='featureId'
                            control={control}
                            render={({field})=>(
                                <TextField
                                    {...field}
                                    disabled
                                    variant="outlined"
                                    size="small"
                                    label={t('schemaName')}
                                />
                            )}
                        />
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Controller
                            name='prefix'
                            control={control}
                            render={({field})=>(
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    size="small"
                                    label={t('schemaPrefix')}
                                    {...register('prefix',{
                                    required: {
                                        value: true,
                                        message: t('inputRequired').replace('%1', t('schemaPrefix')),
                                    },
                                    minLength: {
                                        value: 2,
                                        message: t('minValidation').replace('%1', '2'),
                                    },
                                    maxLength: {
                                        value: 256,
                                        message: t('maxValidation').replace('%1', '256'),
                                    },
                                    pattern: {
                                        value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                                        message: t('patternValidation'),
                                    }
                                    })}
                                    helperText={errors.prefix?.message}
                                    error={errors.prefix !== undefined}
                                />
                            )}
                        />
                    </Box>
                    {formFields.isImported && 
                      <Box display="flex" alignItems="center" sx={{ paddingLeft: '1em' }}>
                        <Typography textAlign="center">{t('importedFromXsdFile')}</Typography>
                      </Box>
                    }
                </Box>
                <SchemaFieldsTable />
                </Paper>
            </PageContent>
            </Page>
        </FormProvider>
    );
}

export function SchemaTitle() {
  return (
    <Typography>
      <Trans i18nKey={'headerSchema'} />
      <IconMoreInfo title={<InfoTitleSchema />} />
    </Typography>
  );
}

function InfoTitleSchema() {
  return (
    <Fragment>
      <Typography color="inherit">
        <Trans i18nKey={'info_title1'} />
      </Typography>
      <Trans i18nKey={'info_schemaWhat'} />
      <Typography color="inherit" marginTop={1}>
        <Trans i18nKey={'info_title2'} />
      </Typography>
      <Trans i18nKey={'info_schemaHowTo'} />
    </Fragment>
  );
}

function SchemaFieldsTable() {
  const { t } = useTranslation();
  const { showSuccess, showError } = useAlert();
  const navigate = useNavigate();
  const { update } = useUpdate<SchemaWithFieldsProps, SchemaWithFieldsProps>('schemas');
  
  const { control, handleSubmit, register, setValue, getValues, formState: { errors }, watch } = useFormContext<SchemaWithFieldsProps>();
  const { fields,  append, remove, replace } = useFieldArray({control, name: 'fields'});
  const { isImported } = useContext(FormContext);

  const handleClickToFieldPage = (index: number)=> {
    navigate('field/' + getValues(`fields.${index}.name`), {state: getValues()})
  };
  
  const onSubmit = async (data: SchemaWithFieldsProps) => {
    const res = JSON.parse(`${await update(data.id.toString(), data)}`);
    if (!res.message) {
      showSuccess(t('dataUpdated'));
    } else {
      showError(res.message);
    }
  };

  const handleItemAdd = () => {
    append({
        id: 0,
        name: "",
        fieldType: "STRING",
        isMultiValue: false,
        defaultValue: "",
        constraints: [],
        referenceConfiguration: null,
        subFields: null
    });
  };

  const hadleChangeType = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setValue(`fields.${index}.fieldType`, event.target.value);
    if (['BLOB', 'COMPLEX'].indexOf(event.target.value) !== -1) {
      setValue(`fields.${index}.defaultValue`, '');
    }
  };

  return (
    <Fragment>
      <Box
        sx={{
          width: '100%',
          paddingBottom: '1em',
        }}
      >
        <Button
          disabled = {isImported}
          variant="contained"
          color="primary"
          style={{ marginRight: 10 }}
          startIcon={<AddIcon />}
          onClick={handleItemAdd}
        >
          <Trans i18nKey={'fieldAdd'} />
        </Button>
      </Box>
      <Box
        sx={{
          maxHeight: '500px',
          overflowY: 'auto',
          border: '1px solid grey',
          borderRadius: '5px',
        }}
      >
        <Box>
          <Stack
            direction="row"
            sx={{
              fontWeight: 600,
              height: '40px',
            }}
          >
            <div
              style={{
                width: '40px',
              }}
            ></div>
            <div
              style={{
                backgroundColor: theme.palette.grey[200],
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '1em',
                borderLeft: '1px solid grey',
              }}
            >
              <Trans i18nKey={'fieldsConfig'} />
            </div>
          </Stack>
        </Box>
        <form>
          {fields.map((item: any, index: number) => (
            <Box key={item.id}>
              <Stack direction="row" height="auto" width="100%">
                <div
                  style={{
                    width: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderTop: '1px solid grey',
                  }}
                >
                  <DeleteIconButton disabled = {isImported} onClick={() => remove(index)} size="small" />
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    borderLeft: '1px solid grey',
                    borderTop: '1px solid grey',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '1em',
                  }}
                >
                  <Stack
                    direction="row"
                    py={2}
                    sx={{
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      <Controller
                        name={`fields.${index}.name`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            disabled = {isImported}
                            label={<Trans i18nKey={'fieldName'} />}
                            variant="outlined"
                            size="small"
                            sx={{
                              paddingRight: '1em',
                            }}
                            {...register(`fields.${index}.name`, {
                              required: {
                                value: true,
                                message: t('inputRequired').replace('%1', t('fieldName')),
                              },
                              minLength: {
                                value: 2,
                                message: t('minValidation').replace('%1', '2'),
                              },
                              maxLength: {
                                value: 256,
                                message: t('maxValidation').replace('%1', '256'),
                              },
                              pattern: {
                                value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                                message: t('patternValidation'),
                              },
                            })}
                            helperText={(errors.fields?.[index] as any)?.name?.message}
                            error={(errors.fields?.[index] as any)?.name !== undefined}
                          />
                        )}
                      />
                      <Controller
                        name={`fields.${index}.fieldType`}
                        control={control}
                        render={({ field }) => (
                          <FormControl variant="outlined">
                            <ControlledSelect
                              disabled = {isImported}
                              valueList={FieldsSelectValue}
                              {...field}
                              size="small"
                              label={<Trans i18nKey={'fieldType'} />}
                              sx={{
                                width: '200px',
                              }}
                              {...register(`fields.${index}.fieldType`, {
                                required: {
                                  value: true,
                                  message: t('inputRequired').replace('%1', t('fieldType')),
                                },
                              })}
                              helperText={(errors.fields?.[index] as any)?.fieldType?.message}
                              error={(errors.fields?.[index] as any)?.fieldType !== undefined}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                hadleChangeType(e, index)
                              }
                            />
                          </FormControl>
                        )}
                      />
                      <Controller
                        name={`fields.${index}.isMultiValue`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <FormControlLabel
                            disabled = {isImported}
                            control={<Checkbox onChange={onChange} checked={value} />}
                            label={<Trans i18nKey={'fieldMulti'} />}
                            sx={{ paddingLeft: '1em' }}
                          />
                        )}
                      />
                      <Controller
                        name={`fields.${index}.defaultValue`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            disabled = {isImported}
                            label={<Trans i18nKey={'fieldDefault'} />}
                            {...register(`fields.${index}.defaultValue`, {})}
                            variant="outlined"
                            size="small"
                            sx={
                                ['BLOB', 'COMPLEX'].indexOf(watch(`fields.${index}.fieldType`)) !== -1
                                ? { display: 'none' }
                                : {}
                            }
                          />
                        )}
                      />
                    </div>
                    <Stack spacing={2} direction="row" style={{
                      marginRight: '1%'
                    }}>
                      <Tooltip title={<Trans i18nKey={'fieldAdvSett'} />}>
                        <IconButton disabled = {isImported} onClick={()=> handleClickToFieldPage(index)}>
                          <SettingsIcon/>
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Stack>
                </div>
              </Stack>
            </Box>
          ))}
        </form>
      </Box>
      <Box
        sx={{
          width: '100%',
          justifyContent: 'right',
          display: 'flex',
          paddingTop: '1em',
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => replace(getValues('fields'))}
        >
          <Trans i18nKey={'button_discard'} />
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          sx={{ marginLeft: '1em' }}
        >
          <Trans i18nKey={'save'} />
        </Button>
      </Box>
    </Fragment>
  );
}

export default SchemaFieldsForm;
