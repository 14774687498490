import { ThemeOptions, createTheme } from "@mui/material/styles";

export const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#222f39",
    },
    secondary: {
      main: "#93c7f7",
    },
  },
  typography: {
    h1: {
      fontSize: "4.6rem",
    },
    fontSize: 12,
  },
};

export const theme = createTheme(themeOptions);
