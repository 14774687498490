import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type Props = {
    title: string;
} & IconButtonProps;

export function CopyIconButton({title, ...props }: Props) {
  return (
    <Tooltip title={title}>
      <IconButton aria-label={title} {...props}>
        <ContentCopyIcon />
      </IconButton>
    </Tooltip>
  );
}
