import { Link, Stack, Typography, Box } from '@mui/material';
import { Loader } from '../../../common/loader/Loader';
import { AxiosError } from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import { theme } from '../../../../styling/Theme';
import useLifeCycles from '../../../../serverInteraction/hooks/content/life-cycles/useLifeCycles';
import { LifeCycleProps } from '../../../../types/content/life-cycles/LifeCycleProps';
import { LifeCyclesDeleteDialog } from './delete/LifeCyclesDeleteDialog';

export function LifeCyclesPageList() {
  const { status, lifeCycles, isLoading, isFetching, error, refetch } = useLifeCycles();

  if (isLoading || isFetching || lifeCycles === undefined) {
    return <Loader />;
  }

  if (status === 'error' && error instanceof AxiosError) {
    return (
      <Typography variant="h6" align="center">
        Error: {error.message}
      </Typography>
    );
  }

  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 600,
          backgroundColor: theme.palette.grey[200],
          py: 1,
          px: 2,
        }}
      >
        Name
      </Typography>
      <Stack>
        {JSON.parse(`${lifeCycles}`).map((lifeCycle: LifeCycleProps) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ py: 1, px: 2 }}
            key={lifeCycle.lifeCycleId}
          >
            <Link underline="hover" component={RouterLink} to={`${lifeCycle.lifeCycleId}`}>
              {lifeCycle.lifeCycleId}
            </Link>
            <Stack justifyContent="space-between" alignItems="right" spacing={2}>
              <LifeCyclesDeleteDialog lifeCycle={lifeCycle} onDelete={() => refetch()} />
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
