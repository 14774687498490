import { CommonEntityProps } from "../types/common/CommonEntityProps";

export const DefaultFacetsForDocument: CommonEntityProps[] = [ 
    {
        featureId: 'audio',
        label: 'Audio - Document is holding an audio file',
        description: ''
    },
    {
        featureId: 'collection',
        label: 'Collection - Document is a collection',
        description: 'The document type is a collection of others documents (it references them but does not contain them).'
    },
    {
        featureId: 'commentable',
        label: 'Commentable - Document can be commented',
        description: "Displays the comment tab (unless you forbfeatureId it in the tabs filtering section), and the document instance's comments in the summary tab"
    },
    {
        featureId: 'folderish',
        label: 'Folderish - Document is folderish',
        description: 'Document becomes a container for other documents. Needed for folders or similar document types.'
    },
    {
        featureId: 'hasrelatedtext',
        label: 'HasRelatedText - Full-text indexing of related text',
        description: 'Facet to be used for full-text indexing of related text content (e.g. comments, annotations, tags...)'
    },
    {
        featureId: 'hasstoryboard',
        label: 'HasStoryboard - A story board can be extracted from the document (also requires the VfeatureIdeo facet)',
        description:''
    },
    {
        featureId: 'hasvideopreview',
        label: 'HasVideoPreview - A screenshot can be extracted from the document (also requires the VfeatureIdeo facet)',
        description: ''
    },
    {
        featureId: 'hiddenincreation',
        label: 'HiddenInCreation - Document is hidden in creation',
        description: "This should be set on types which you don't want to see in the documentation creation menus or screens. You can still create these types programmatically."
    },
    {
        featureId: 'hiddeninfacetedsearch',
        label: 'HiddenInFacetedSearch - Document is hfeatureIdden in faceted search results',
        description: 'The document will not appear in faceted search results, but will still appear in navigation and standard search results.  (deprecated)'
        },
    {
        featureId: 'hiddeninnavigation', 
        label: 'HiddenInNavigation - Document is hfeatureIdden in navigation',
        description: "The document type won't appear in the tree and in the folder content listing."
    },
    {
        featureId: 'indexable',
        label: 'Indexable - Document is indexable (deprecated)',
        description: 'This facet is an old marker that is not used anymore, it can safely be removed.'
    },
    {
        featureId: 'masterpublishspace',
        label: 'MasterPublishSpace - Document is a publish space root',
        description: 'For advanced users only. Use this facet for document types replacing the SectionRoot document type.'
    },
    {
        featureId: 'multiviewpicture',
        label: 'MultiviewPicture - Multi-resolutions pictures can be generated and stored on this document (deprecated)',
        description:''
    },
    {
        featureId: 'nxtag',
        label: 'NXTag - Document can be tagged',
        description: 'Allows to manage tags to your document type. Available starting from Nuxeo Server LTS 2017.'
    },
    {
        featureId: 'notcollectionmember',
        label: 'NotCollectionMember - Document cannot be added to a collection',
        description: 'Facet to be used on document types that should not be referenced in a collection. By default, all document types can be referenced in a collection, so this facet is useful to flag document types that should not be.'
    },
    {
        featureId: 'orderable',
        label: 'Orderable - Children of this document are orderable',
        description: "Allows to manage the order of this document's children. Requires the folderish facet."
    },
    {
        featureId: 'picture',
        label: 'Picture - Document is holding a picture file',
        description: ''
    },
    {
        featureId: 'publishspace',
        label: 'PublishSpace - Document is a publish space',
        description: 'For advanced users only. Use this facet for document types replacing the Section document type.'
    },
    {
        featureId: 'publishable',
        label: 'Publishable - Document can be published',
        description: 'Displays the publishing tab (unless you forbfeatureId it in the tabs filtering section).'
    },
    {
        featureId: 'routingtask',
        label: 'RoutingTask - Document is a core routing task',
        description: 'For advanced users only. The document type can be used as a core routing task type if it has this facet, the "Task" facet and the "task" life cycle'
    },
    {
        featureId: 'superspace',
        label: 'SuperSpace - Document is a superspace',
        description: 'Enables local configuration (choosing enabled document types, search forms...) for people having management rights on this space. Requires the folderish facet. The document type will be available in the seam context as variable "currentSuperSpace" when navigating in its children documents. Various features in DM are based on this like the display of local configuration tab and the notifications configuration.'
    },
    {
        featureId: 'task',
        label: 'Task - Document is a task',
        description: 'For advanced users only. The document type can be used as task type by the workflow if it has this facet, the "Routing task" facet and the "task" life cycle'
    },
    {
        featureId: 'versionable',
        label: 'Versionable - Document is versionable',
        description: 'Allows to create versions for this document type.'
    },
    {
        featureId: 'video',
        label: 'Video - Document is holding a vfeatureIdeo file',
        description: ''
    }
]