import { Box, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { AddDialog } from '../../../../common/dialog/AddDialog';
import useCreate from '../../../../../serverInteraction/hooks/entity/useCreate';
import { useForm, useFormContext } from 'react-hook-form';
import { ControlledInput } from '../../../../common/input/ControlledInput';
import { GridOneToThree, GridOneToThreeItemProps } from '../../../../common/grid/GridOneToThree';
import { SchemaProjectProps, SchemaProps } from '../../../../../types/content/schemas/SchemaProps';
import { SimpleAccordion } from '../../../../common/accordion/SimpleAccordion';
import { ControlledRadioGroup } from '../../../../common/radioGroup/ControlledRadioGroup';
import { ECreateSchemaMode } from '../../../../../enum/ECreateSchemaMode';
import useEnumHelper from '../../../../../helpers/hooks/useEnumHelper';
import { ControlledSingleResource } from '../../../../common/resource/ControlledSingleResource';
import { Loader } from '../../../../common/loader/Loader';
import { useTranslation } from 'react-i18next';
import { useProjectContext } from '../../../../../ProjectContext';
import { SchemaWithFieldsProps } from '../../../../../types/content/schemas/SchemaWithFieldsProps';
import { useNavigate } from 'react-router-dom';
import { convertSchemaProjectToSchema } from '../../../../../utils/CommonUtils';

const modeItems = [
  {
    item: ECreateSchemaMode.DEFAULT,
    helperText: <>Create an empty schema where you can add fields</>,
  },
  {
    item: ECreateSchemaMode.INITIALIZE,
    helperText: (
      <>
        Initialize the schema from an existing XSD file{' '}
        <span style={{ fontStyle: 'italic' }}>
          (the file will be generated again based on Studio configuration)
        </span>
      </>
    ),
  },
  {
    item: ECreateSchemaMode.IMPORT,
    helperText: (
      <>
        Import an existing XSD file{' '}
        <span style={{ fontStyle: 'italic' }}>
          (the resulting Nuxeo schema will be readonly and the XSD file will be deployed as is into
          the Nuxeo Repository)
        </span>
      </>
    ),
  },
  {
    item: ECreateSchemaMode.REFERENCE,
    helperText: (
      <>
        Reference an XSD file{' '}
        <span style={{ fontStyle: 'italic' }}>
          (make Studio aware of the corresponding schema, but don't deploy it into the Nuxeo
          Repository)
        </span>
      </>
    ),
  },
];

export function SchemasCreateDialog() {
  const { getValues: getValuesMainForm } = useFormContext<{listSchemas: SchemaProps[]}>();
  const isUniqueFeature = (value: string) => getValuesMainForm('listSchemas').map((item: SchemaProps) => item.featureId).indexOf(value) === -1;

  const project = useProjectContext();
  const { t } = useTranslation();
  const { create, isCreating: isLoading } = useCreate<SchemaWithFieldsProps, SchemaWithFieldsProps>('schemas');
  const { translate } = useEnumHelper();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    reset,
    formState: { errors },
  } = useForm<SchemaWithFieldsProps>({
    defaultValues: {
      companyName: project.companyName,
      documentName: project.documentName,
      fields: [],
    },
    // mode: 'onBlur',
    // reValidateMode: 'onBlur',
    // defaultValues: {
    //   // mode: ECreateSchemaMode.DEFAULT,
    //   // isAllowNestedComplexTypes: false,
    //   // isActivateVcsSanityChecks: false,
    // },
  });

  // const watchMode = watch("mode");

  const onSubmit = async (newSchema: SchemaWithFieldsProps) => {
    isLoading && <Loader />;
    const res: SchemaProjectProps = JSON.parse(`${await create(newSchema)}`);
    reset();
    navigate(`${res.id}`, { state: convertSchemaProjectToSchema(res)});
  };

  const validate = async () => {
    return await trigger();
  };

  const createItems: GridOneToThreeItemProps[] = [
    {
      title: 'Feature ID',
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register('featureId', {
              pattern: {
                value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                message: t('patternValidation')
              },
              required: {
                value: true,
                message: t('inputRequired').replace('%1', t('Feature ID')),
              },
              minLength: {
                value: 3,
                message: t('minValidation').replace('%1', '2'),
              },
              validate: value => isUniqueFeature(value) || 'FeatureId is not unique'
            }),
          }}
          helperText={errors.featureId?.message}
          error={errors.featureId !== undefined}
          sx={{ width: '100%' }}
        />
      ),
    },
    {
      title: 'Schema Prefix',
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register('prefix', {
              pattern: {
                value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                message: t('patternValidation')
              },
              required: {
                value: true,
                message: t('inputRequired').replace('%1', t('Schema Prefix')),
              },
              minLength: {
                value: 3,
                message: t('minValidation').replace('%1', '2'),
              }
            }),
          }}
          helperText={errors.prefix?.message}
          error={errors.prefix !== undefined}
          sx={{ width: '100%' }}
        />
      ),
    },
    // {
    //   title: 'Company Field',
    //   required: true,
    //   content: (
    //     <ControlledInput
    //       type="text"
    //       register={{
    //         ...register('companyName', {
    //           required: {
    //             value: true,
    //             message: 'Company Field is required',
    //           },
    //         }),
    //       }}
    //       helperText={errors.companyName?.message}
    //       error={errors.companyName !== undefined}
    //       sx={{ width: '100%' }}
    //     />
    //   ),
    // },
    // {
    //   title: 'Mode',
    //   required: true,
    //   content: (
    //     <ControlledInput
    //       type="text"
    //       register={{
    //         ...register('version', {
    //           required: {
    //             value: true,
    //             message: 'version is required',
    //           },
    //           minLength: {
    //             value: 1,
    //             message: 'Must be at least 1 symbols',
    //           },
    //         }),
    //       }}
    //       helperText={errors.featureId?.message}
    //       error={errors.featureId !== undefined}
    //       sx={{ width: '100%' }}
    //     />
    //   ),
    // },
  ];

  // const advancedConfigurationItems: GridOneToThreeItemProps[] = [
  //   {
  //     title: 'Mode',
  //     required: true,
  //     content: (
  //       <Controller
  //         control={control}
  //         name="mode"
  //         render={({ field: { onChange, value } }) => (
  //           <ControlledRadioGroup
  //             id="mode-radio-group"
  //             value={value}
  //             items={modeItems.map((modeItem) => {
  //               return {
  //                 label: translate('ECreateSchemaMode', ECreateSchemaMode[modeItem.item]),
  //                 value: modeItem.item,
  //               };
  //             })}
  //             onChange={onChange}
  //           />
  //         )}
  //       />
  //     ),
  //   },
  // ];

  // const xsdSchemaFileItems: GridOneToThreeItemProps[] = [
  //   {
  //     title: 'XSD Schema file',
  //     required: true,
  //     content: (
  //       <Stack>
  //         <ControlledSingleResource control={control} name="xsdSchemaFileId" />
  //         <Controller
  //           control={control}
  //           name="isActivateVcsSanityChecks"
  //           render={({ field: { onChange, value } }) => (
  //             <FormControlLabel
  //               control={
  //                 <Checkbox checked={value} onChange={(event, checked) => onChange(checked)} />
  //               }
  //               label="Allow nested Complex types"
  //             />
  //           )}
  //         />
  //       </Stack>
  //     ),
  //   },
  // ];

  return (
    <AddDialog
      title="Create Document Schema"
      add={handleSubmit(onSubmit)}
      validate={validate}
    >
      <Box sx={{ minHeight: 500 }}>
        <GridOneToThree items={createItems} />
        <SimpleAccordion
          title="Advanced configuration"
          sx={{ mt: 3 }}
          defaultExpanded={false}
          // children={''}
        >
          {/* <GridOneToThree items={advancedConfigurationItems} /> */}
          {/* <Typography>
            {
              modeItems.find((modeItem) => modeItem.item === watchMode)
                ?.helperText
            }
          </Typography> */}
          {/* {watchMode !== ECreateSchemaMode.DEFAULT && ( */}
          {/* <GridOneToThree items={xsdSchemaFileItems} /> */}
          {/* )} */}
          {/* <Controller
            control={control}
            name="isAllowNestedComplexTypes"
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={watchMode !== ECreateSchemaMode.DEFAULT}
                    checked={
                      watchMode === ECreateSchemaMode.DEFAULT ? value : true
                    }
                    onChange={(event, checked) => onChange(checked)}
                  />
                }
                label="Allow nested Complex types"
              />
            )}
          /> */}
        </SimpleAccordion>
      </Box>
    </AddDialog>
  );
}
