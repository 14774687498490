import { TPackagesState } from "./types";
export const USER_COMPANY_NAME: string = 'company6';

export const initialState: TPackagesState = {
  userPackage: {
    id: 1,
    company: USER_COMPANY_NAME,
    document: 'document6',
    description: 'description',
    project: 'company6-document6'
  }
};

