import { PropsWithChildren, useState } from 'react';
import { SimpleModal } from '../modal/SimpleModal';
import { Stack, Button, BoxProps, Box } from '@mui/material';
import { UpdateIconButton } from '../button/iconButton/UpdateIconButton';

type Props = {
  title: string;
  update: () => Promise<void>;
  validate?: () => Promise<boolean>;
  isUpdate?: () => void;
} & BoxProps &
  PropsWithChildren;

export function UpdateDialog({ title, update, validate, children, isUpdate, ...props }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const handleUpdate = async () => {
    var result = true;
    if(validate){
      result = await validate();
    };

    if (result) {
      setIsOpen(false);
      await update();
      isUpdate && isUpdate();
    }
  };

  return (
    <div>
      <UpdateIconButton onClick={() => setIsOpen(true)} size="small" />
      <SimpleModal title={title} isOpen={isOpen} handleClose={() => setIsOpen(false)}>
        <Box {...props}>
          {children}
          <Stack
            direction="row-reverse"
            spacing={3}
            alignItems="right"
            justifyContent="end"
            sx={{ pt: 3 }}
          >
            <Button variant="contained" onClick={handleUpdate} sx={{ width: 150 }}>
              Update
            </Button>
            <Button variant="outlined" onClick={() => setIsOpen(false)} sx={{ width: 150 }}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </SimpleModal>
    </div>
  );
}
