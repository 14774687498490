import { Box } from "@mui/material";
import { AddDialog } from "../../../common/dialog/AddDialog";
import useCreate from "../../../../serverInteraction/hooks/entity/useCreate";
import { useForm } from "react-hook-form";
import { BrandingProps } from "../../../../types/branding/BrandingProps";
import { ControlledInput } from "../../../common/input/ControlledInput";
import {
  GridOneToThree,
  GridOneToThreeItemProps,
} from "../../../common/grid/GridOneToThree";

export function BrandingCreateDialog() {
  const { create } = useCreate<BrandingProps, BrandingProps>("branding");

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<BrandingProps>({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const onSubmit = async (newBranding: BrandingProps) => {
    console.log(newBranding);
    await create(newBranding);
  };

  const validate = async () => {
    return await trigger();
  };

  const createItems: GridOneToThreeItemProps[] = [
    {
      title: "Feature ID",
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register("name", {
              required: {
                value: true,
                message: "Enter you branding name",
              },
              minLength: {
                value: 3,
                message: "Must be at least 3 symbols",
              },
            }),
          }}
          helperText={errors.name?.message}
          error={errors.name !== undefined}
          sx={{ width: "100%" }}
        />
      ),
    },
  ];

  return (
    <AddDialog
      title="Create Branding"
      add={handleSubmit(onSubmit)}
      validate={validate}
    >
      <Box sx={{ minHeight: 500 }}>
        <GridOneToThree items={createItems} />
      </Box>
    </AddDialog>
  );
}
