import { AppBar, Toolbar, Typography, Box, IconButton, Menu, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AdbIcon from '@mui/icons-material/Adb';
import React from 'react';
import { Profile } from '../common/authorization/Profile';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { theme } from '../../styling/Theme';
import { Loader } from '../common/loader/Loader';
import { useSelector } from 'react-redux';
import { PackageProps } from '../../types/packages/PackageProps';


export function Header() {
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const { i18n } = useTranslation();
  const changeLanguage = (language: string | undefined) => {
    i18n.changeLanguage(language);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handlePackage = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}create-package`, {
        method: 'GET',
      });

      if (response.status !== 200) {
        return <Loader />;
      }

      if (response.status === 200) {
        const blob = await response.blob();

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'package.zip';
        document.body.appendChild(a);

        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        console.error('Не удалось выполнить GET-запрос');
      }
    } catch (error) {
      console.error('Произошла ошибка:', error);
    }
  };

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Box sx={{ px: 3 }}>
        <Toolbar disableGutters>
          <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            STUDIO 2.0
          </Typography>
          <Button
            variant="contained"
            size="large"
            style={{ backgroundColor: 'white', color: 'black', marginRight: 10 }}
            onClick={() => changeLanguage('ru')}
          >
            RUS
          </Button>
          <Button
            variant="contained"
            size="large"
            style={{ backgroundColor: 'white', color: 'black' }}
            onClick={() => changeLanguage('en')}
          >
            ENG
          </Button>
          <Button
            variant="contained"
            size="large"
            style={{ backgroundColor: 'white', color: 'black', marginLeft: 10 }}
            onClick={handlePackage}
          >
            package
          </Button>
          <Typography
            variant="h6"
            noWrap
            sx={{
              marginLeft: 2,
            }}
          >
            {userPackage?.project || "-"}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            ></Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            STUDIO 2.0
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}></Box>
          <Box sx={{ flexGrow: 0 }}>
            <Profile />
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
}
