import ReactDOM from 'react-dom/client';
import { Provider as StoreProvider } from 'react-redux';
import './index.css';
import reportWebVitals from './reportWebVitals';
import i18n from './i18n/i18n';
import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Routes } from './router/Routes';
import { theme } from './styling/Theme';
import { I18nextProvider } from 'react-i18next';
import store from './store';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // <React.StrictMode>
  <I18nextProvider i18n={i18n}>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <StoreProvider store={store}>
          <Routes />
        </StoreProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </I18nextProvider>,
  // {/* </React.StrictMode>, */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
