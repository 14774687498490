export enum DefaultTypesForDocument {
  Document = 0,
  File = 1,
  Folder = 2,
  Audio,
  Collection,
  Collections,
  Domain,
  Forum,
  MailFolder,
  MailMessage,
  Note,
  OrderedFolder,
  Picture,
  PictureBook,
  Post,
  Root,
  Section,
  SectionRoot,
  TemplateRoot,
  Thread,
  Video,
  Workspace,
  WorkspaceRoot,
}

// пример для получения текстовых значений

// Object.keys(DefaultTypesForDocument)
//     .filter((key: any) => !isNaN(Number(DefaultTypesForDocument[key])))
//     .map((key: any) => (
//       <option key={key} value={key}>
//         {key}
//   </option>
// ))
