import { Checkbox, FormControl, Stack } from "@mui/material"
import { Fragment, useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { useLocation } from "react-router-dom";
import { FieldProps } from "../../../../../../../../types/content/schemas/Fields/NewFieldProp";
import { InfoListRequired, Inforequired, LabelRequired } from "../ValidationLabels";

export default function ListRequiredView({index}: {index: number}){
    const { state } = useLocation();
    const { control, setValue } = useFormContext<FieldProps>();
    const fieldsWatches = useWatch({
        control,
        name: ['isMultiValue', `constraints.${index}.listElementsMandatoryConstraint.value`, 'name']
    });

    useEffect(() => {
        if(fieldsWatches[0] === false) setValue(`constraints.${index}.listElementsMandatoryConstraint.value`, false);
    }, [fieldsWatches[0]])

    if (fieldsWatches[0]){
        return (
            <Fragment>
                <Stack direction='row' alignItems='center' sx={{marginTop: '1em', marginLeft: '1em'}}>
                    <Controller
                        name={`constraints.${index}.listElementsMandatoryConstraint.value`}
                        control={control}
                        render={({ field: { onChange, value } }) => <Checkbox onChange={onChange} checked={value} />}
                    />
                    <FormControl sx={{cursor: 'default', width: '100%'}}>
                        <InfoListRequired />  
                    </FormControl>
                </Stack>
                {fieldsWatches[1] && <LabelRequired schema={state.featureId as string} field={fieldsWatches[2]}/>}
            </Fragment>
        )
    }

    return <Fragment />
}