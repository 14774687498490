import { Checkbox, FormControl, Stack, TextField } from "@mui/material"
import { Fragment, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { Trans } from "react-i18next";
import { useLocation } from "react-router-dom";
import { PatternLists } from "../../../../../../../../staticValues/Constants";
import { FieldProps } from "../../../../../../../../types/content/schemas/Fields/NewFieldProp";
import { ControlledSelect } from "../../../../../../../common/select/ControlledSelect";
import { LabelTextFormat, TextLengthCheckbox } from "../ValidationLabels";

export default function TextFormatView({index}: {index: number}){
    const { state } = useLocation();
    const { control, setValue, getValues } = useFormContext<FieldProps>();
    const pattern: string = getValues(`constraints.${index}.textFormatConstraint.pattern`);
    const [isShowPattern, setShowPattern] = useState(pattern !== null) 
    const fieldsWatches = useWatch({
        control,
        name: 'name'
    });
    return (
        <Fragment>
            <Stack direction='row' alignItems='center' sx={{marginTop: '1em', marginLeft: '1em'}}>
                <Checkbox onChange={() => setShowPattern(!isShowPattern)} checked={isShowPattern} />
                <FormControl sx={{cursor: 'default', width: '100%'}}>
                    <TextLengthCheckbox />  
                </FormControl>
            </Stack>
            {isShowPattern &&
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '3em'
                }}>
                    <div style={{
                        marginTop: '1em',
                        marginBottom: '0.5em',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <Controller
                        name={`constraints.${index}.textFormatConstraint.pattern`}
                        control={control}
                        render={({ field: { onChange, value } }) => (<TextField
                                variant="outlined" 
                                size='small'
                                label={<Trans i18nKey={'validPattern'} />}
                                value={value === null ? '' : value}
                                onChange={onChange}
                                sx={{
                                    width: '30%'
                                }}
                                />
                            )}
                        />
                        <ControlledSelect valueList={PatternLists} selectedValue={pattern === null ? 'unknown' : pattern} size='small' label={<Trans i18nKey={'validPattern'} />} sx={{
                        width: '200px',
                        marginLeft: '1em'
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(`constraints.${index}.textFormatConstraint.pattern`, e.target.value === 'unknown' ? null : e.target.value)}
                    />
                    </div>
                    <LabelTextFormat schema={state.featureId as string} field={fieldsWatches} />
                </div>  
            }
        </Fragment>
    )
}