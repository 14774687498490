import { useParams } from 'react-router-dom';
import useFacet from '../../../../serverInteraction/hooks/content/facets/useFacet';
import { Loader } from '../../../common/loader/Loader';
import SaveIcon from '@mui/icons-material/Add';
import { AxiosError } from 'axios';
import { Box, Button, Paper, Stack, TextField, TextareaAutosize, Typography } from '@mui/material';
import { Page } from '../../../common/page/Page';
import { PageHeader } from '../../../common/page/PageHeader';
import { PageContent } from '../../../common/page/PageContent';
import React, { useEffect, useState } from 'react';
import { SimpleAccordion } from '../../../common/accordion/SimpleAccordion';
import { useFieldArray, useForm } from 'react-hook-form';
import { FacetProps } from '../../../../types/content/facets/FacetProps';
import useCreate from '../../../../serverInteraction/hooks/entity/useCreate';
import { CreateFacetsProps } from '../../../../types/content/facets/CreateFacetsProps';
import { TransferList } from '../../../common/transfetList/TransferList';
import useSchemas from '../../../../serverInteraction/hooks/content/schemas/useSchemas';

type Params = {
  id: string;
};

export function FacetPage() {
  const params = useParams<Params>();
  const id = params.id;

  const { control, handleSubmit, register } = useForm();
  const { status, facet, isLoading, isFetching, error } = useFacet(`${id}`);
  const {
    status: schemasStatus,
    schemas,
    isLoading: scheamsLoading,
    isFetching: schemasFetching,
  } = useSchemas();
  const { create } = useCreate<CreateFacetsProps, FacetProps>('facets');

  const [parentRight, setParentRight] = useState<any[]>([]);

  const { append } = useFieldArray({
    control,
    name: 'schemas',
  });

  useEffect(() => {
    handleAppendSchemas();
  }, [parentRight]);

  useEffect(() => {
    if (facet) {
      const parsedFacet = JSON.parse(`${facet}`);
      if (parsedFacet.schemas) {
        const schemaIds = parsedFacet.schemas.map((schema: any) => schema.featureId);
        setParentRight(schemaIds);
      }
    }
  }, [facet]);

  const handleAppendSchemas = () => {
    const saverSchemas = parentRight.map((schemaId) => ({
      schemaId,
      prefix: '',
      version: '',
      idField: '',
    }));
    append(saverSchemas);
  };

  let upploadSchemas: any[] = [];
  let newArr: any[] = [];
  let schemasList: any;
  let schemasListItems: any[] = [];

  if (schemasStatus === 'success') {
    schemasList = JSON.parse(`${schemas}`);
    schemasList.map((i: any) => schemasListItems.push(i.featureId));
  }

  let fct: any;

  if (facet && facet !== undefined) {
    fct = JSON.parse(facet.toString());
    upploadSchemas.push(JSON.parse(`${facet}`).schemas);
    upploadSchemas.map((i) => {
      i.map((value: any) => {
        newArr.push(value.featureId);
      });
    });
  }

  if (
    isLoading ||
    isFetching ||
    scheamsLoading ||
    schemasFetching ||
    schemas === undefined ||
    facet === undefined
  ) {
    return <Loader />;
  }

  if (status === 'error' && error instanceof AxiosError) {
    return (
      <Typography variant="h6" align="center">
        Error: {error.message}
      </Typography>
    );
  }

  const updateParentRight = (newRight: any) => {
    setParentRight(newRight);
  };

  const onSubmit = async (newFacet: any) => {
    const uniqueSchemas = [];
    const existingSchemaIds: any[] = [];

    for (const schema of newFacet.schemas) {
      if (!existingSchemaIds.includes(schema.featureId)) {
        uniqueSchemas.push(schema);
        existingSchemaIds.push(schema.featureId);
      }
    }

    newFacet.schemas = uniqueSchemas;

    const schemasToSubmit = parentRight.map((schemaId) => ({
      schemaId,
    }));

    const newFacetData = {
      ...newFacet,
      schemas: schemasToSubmit,
    };

    await create(newFacetData);
  };

  return (
    <>
      {fct.facetId === undefined ? (
        <Page>
          <Box>
            <Loader />
          </Box>
        </Page>
      ) : (
        <Page>
          <PageHeader title={`Facet ${id}`}></PageHeader>
          <PageContent subtitle={`Define the main characteristics of your document type`}>
            <Stack spacing={3}></Stack>
            <Paper elevation={2} square sx={{ p: 2 }}>
              <Button
                form="facets_form"
                style={{ marginBottom: 20 }}
                startIcon={<SaveIcon />}
                type="submit"
                variant="contained"
                color="primary"
              >
                Сохранить
              </Button>
              <Box display="flex" alignItems="center">
                <form id="facets_form" onSubmit={handleSubmit(onSubmit)}>
                  <Box>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                      <Typography style={{ marginRight: 10 }}>Label</Typography>
                      {/* <TextField value={value} onChange={(e) => setValue(e.target.value)} /> */}
                      <TextField defaultValue={fct.label} {...register('label')} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography style={{ marginRight: 10 }}>Description</Typography>
                      <TextareaAutosize
                        maxRows={3}
                        minRows={3}
                        style={{
                          width: '100%',
                          border: '1px solid',
                          borderColor: 'lightgrey',
                          padding: 10,
                          borderRadius: 4,
                        }}
                        defaultValue={fct.description}
                        {...register('description')}
                      />
                      <input value={`${id}`} hidden {...register('facetId')} />
                    </div>
                    <SimpleAccordion
                      title="Assosiated Schemas"
                      sx={{ mt: 3 }}
                      defaultExpanded={true}
                    >
                      <TransferList
                        entityList={schemasListItems}
                        updateParent={updateParentRight}
                        loadEntities={newArr}
                      />
                    </SimpleAccordion>
                  </Box>
                </form>
                ``
              </Box>
            </Paper>
          </PageContent>
        </Page>
      )}
    </>
  );
}
