import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Paper, Stack } from '@mui/material';
import { Page } from '../../common/page/Page';
import { PageHeader } from '../../common/page/PageHeader';
import { PageContent } from '../../common/page/PageContent';

const PackagePage = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  return (
  <Page>
    <PageHeader title={`${t('package')} ${state.project}`}></PageHeader>
      <PageContent subtitle=''>
        <Paper elevation={2} square sx={{ p: 2 }}>
          <Stack spacing={3}>
            {Object.keys(state).map((elem: string) => (
                <Stack direction="row" gap="10px">
                <Typography fontWeight="bold">
                  {elem}:
                </Typography>
                <Typography >
                  {state[elem]}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Paper>
      </PageContent>
    </Page>
  )
};





export default PackagePage;
