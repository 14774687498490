import { useRef, useEffect, useState } from 'react';

type DrawingLine = {
  startPoint: { x: number; y: number };
  endPoint: { x: number; y: number };
};

interface TestCanvasProps {
  drawLines: DrawingLine[];
  onDrawLine: (startPoint: { x: number; y: number }, endPoint: { x: number; y: number }) => void;
}

// ...

export const TestCanvas: React.FC<TestCanvasProps> = ({ drawLines, onDrawLine }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [lines, setLines] = useState<DrawingLine[]>([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [startPoint, setStartPoint] = useState<{ x: number; y: number } | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');

    if (canvas && context) {
      context.strokeStyle = 'black';
      context.lineWidth = 2;
      context.lineCap = 'round';

      // Отрисовать все сохраненные линии при изменении drawLines
      drawLines.forEach((line) => {
        context.beginPath();
        context.moveTo(line.startPoint.x, line.startPoint.y);
        context.lineTo(line.endPoint.x, line.endPoint.y);
        context.stroke();
      });

      const getMousePosition = (event: MouseEvent) => {
        const rect = canvas.getBoundingClientRect();
        return {
          x: event.clientX - rect.left,
          y: event.clientY - rect.top,
        };
      };

      const handleMouseDown = (event: MouseEvent) => {
        setIsDrawing(true);
        setStartPoint(getMousePosition(event));
      };

      const handleMouseUp = () => {
        setIsDrawing(false);
        setStartPoint(null);
      };

      const handleMouseMove = (event: MouseEvent) => {
        if (!isDrawing || !startPoint) return;

        const currentPosition = getMousePosition(event);

        const newLine: DrawingLine = {
          startPoint: startPoint,
          endPoint: currentPosition,
        };

        setLines((prevLines) => [...prevLines, newLine]);

        // Отрисовать все сохраненные линии
        lines.forEach((line) => {
          context.moveTo(line.startPoint.x, line.startPoint.y);
          context.lineTo(line.endPoint.x, line.endPoint.y);
          context.stroke();
        });

        // Отрисовать текущую линию
        context.moveTo(startPoint.x, startPoint.y);
        context.lineTo(currentPosition.x, currentPosition.y);
        context.stroke();

        setStartPoint(currentPosition);
      };
      canvas.addEventListener('mousedown', handleMouseDown);
      canvas.addEventListener('mouseup', handleMouseUp);
      canvas.addEventListener('mousemove', handleMouseMove);

      return () => {
        canvas.removeEventListener('mousedown', handleMouseDown);
        canvas.removeEventListener('mouseup', handleMouseUp);
        canvas.removeEventListener('mousemove', handleMouseMove);
      };
    }
  }, [isDrawing, startPoint, onDrawLine, lines, drawLines]);

  return <canvas ref={canvasRef} width={1600} height={600} />;
};
