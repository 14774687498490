import { TextField, TextFieldProps } from "@mui/material";

type Props = {
  register: any;
} & TextFieldProps;

export function ControlledInput({ register, ...props }: Props) {
  return (
    <TextField
      sx={{ width: 150 }}
      inputProps={{ style: { padding: "0.5rem 1rem " } }}
      {...register}
      {...props}
    />
  );
}
