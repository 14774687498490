import { Box, Checkbox, FormControl, Stack, TextField } from "@mui/material";
import { Fragment, useState } from "react";
import { Controller } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next";
import { LabelTextLength, TextLengthCheckbox } from "../ValidationLabels";

interface IProps {
    schemaName: string,
    fieldName: string,
    index: number,
    fieldIndex: number,
    control: any,
    register: any,
    errors: any,
    getValues: any,
    setValue: any
}


export default function TextLengthView({schemaName, fieldName, index, fieldIndex, control, register, errors, getValues, setValue}: IProps){
    const { t } = useTranslation();
   
    const textLengthConstraint: Array<any | number> = getValues([`fields.${fieldIndex}.constraints.${index}.textLengthConstraint.minimum`, `fields.${fieldIndex}.constraints.${index}.textLengthConstraint.maximum`]);
    const [isWatchTextLen, setWatchTextLen] =  useState(textLengthConstraint[0] || textLengthConstraint[1]);
    
    
    return(
        <Fragment>
          <Stack direction='row' alignItems='center' sx={{marginTop: '1em', marginLeft: '1em'}}>
                <Checkbox onChange={() => setWatchTextLen(!isWatchTextLen)} checked={isWatchTextLen} />
                <FormControl sx={{cursor: 'default', width: '100%'}}>
                    <TextLengthCheckbox />  
                </FormControl>
            </Stack>
            {isWatchTextLen &&
            <div style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Box style={{
                    marginLeft: '3em',
                    marginTop: '1em'
                }}>
                    <Controller
                    name={`fields.${fieldIndex}.constraints.${index}.textLengthConstraint.minimum`}
                    control={control}
                    render={({ field }) => (<TextField {...field}
                            variant="outlined" 
                            size='small'
                            type='number'
                            label={<Trans i18nKey={'validMinimum'} />}
                            sx={{
                                marginRight: '1em'
                            }}
                            {...register(`fields.${fieldIndex}.constraints.${index}.textLengthConstraint.minimum`, {
                                min: {
                                    value: 0,
                                    message: t('minValueValidation').replace('%1', '0')
                                },
                                max: {
                                    value: 999999999,
                                    message: t('maxValueValidation').replace('%1', '999999999')
                                },
                                pattern: {
                                    value: /^\d+$/,
                                    message: t('onlyIntegerNumber')
                                }
                            })}
                            helperText={errors.fields ? errors.fields[fieldIndex]?.constraints?.[index]?.textLengthConstraint?.minimum?.message : undefined}
                            error={errors.fields ? errors.fields[fieldIndex]?.constraints?.[index]?.textLengthConstraint?.minimum !== undefined : undefined}
                            />
                        )}
                    />
                    <Controller
                        name={`fields.${fieldIndex}.constraints.${index}.textLengthConstraint.maximum`}
                        control={control}
                        render={({ field }) => (<TextField {...field}
                            {...register(`fields.${fieldIndex}.constraints.${index}.textLengthConstraint.maximum`, {
                                min: {
                                    value: 0,
                                    message: t('minValidation').replace('%1', '0')
                                },
                                max: {
                                    value: 999999999,
                                    message: t('maxValidation').replace('%1', '999999999')
                                },
                                pattern: {
                                    value: /^\d+$/,
                                    message: t('onlyIntegerNumber')
                                }
                            })}
                            variant="outlined" 
                            size='small'
                            type='number'
                            label={<Trans i18nKey={'validMaximum'} />}
                            helperText={errors.fields ? errors.fields[fieldIndex]?.constraints?.[index]?.textLengthConstraint?.maximum?.message : undefined}
                            error={errors.fields ? errors.fields[fieldIndex]?.constraints?.[index]?.textLengthConstraint?.maximum !== undefined : undefined}
                            />
                        )}
                    />
                </Box>
                <LabelTextLength schema={schemaName} field={fieldName} />
            </div>
            }
        </Fragment>
    )
};
