export const modeItems = [
    {
      value: 'Document',
      item: 'Nothing',
      helperText: 'documentHelperText'
    },
    {
      value: 'File',
      item: 'File',
      helperText: 'fileHelperText'
    },
    {
      value: 'Folder',
      item: 'Folder',
      helperText: 'folderHelperText'
    },
  ];