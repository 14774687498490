import { Box, Button, ButtonProps, Paper, Stack, Tab, Tabs, Typography } from '@mui/material';
import { Page } from '../../../../common/page/Page';
import { PageHeader } from '../../../../common/page/PageHeader';
import { Trans, useTranslation } from 'react-i18next';
import { Fragment, useState } from 'react';
import { useSaveSettings, usePostListSettings, useSaveListSettings } from '../../../../../serverInteraction/general/settings/useSettings';
import { Loader } from '../../../../common/loader/Loader';
import { Setting } from '../../../../../types/common/Setting';
import warning from "../../../../common/img/warning.gif";
import { UsersSettings, getSettingsUsers, UsersSettingsProps } from './impl/UsersSettings';
import IconMoreInfo from '../../../../common/button/iconButton/MoreInfo';
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from '@mui/icons-material/Add';
import { UsersContainer, UserTabProps, ChangeUserTab } from './impl/UsersContainer';
import { GroupsContainer, GroupTabProps, changeGroupTab } from './impl/GroupsContainer';
import { NewUser, User } from '../../../../../types/content/roles-and-permissions/users&groups/User';
import {Group, NewGroup} from '../../../../../types/content/roles-and-permissions/users&groups/Group';
import useGetAllUsers from '../../../../../serverInteraction/hooks/content/roles-and-permissions/users&groups/useGetAllUsers';
import useGetAllGroups from '../../../../../serverInteraction/hooks/content/roles-and-permissions/users&groups/useGetAllGroups';
import { SimpleModal } from '../../../../common/modal/SimpleModal';
import useCreate from '../../../../../serverInteraction/hooks/entity/useCreate';

let structSettings: Map<string, any> = new Map<string, any>();
type UserGroup = {
  users: Array<User | NewUser>;
  groups: Array<Group | NewGroup>;
};

let usersAndGroups: UserGroup = {
  users: [],
  groups: []
};

let numberTab: number = 0;

export default function UsersGroups() {
    const { t } = useTranslation();
    const { isFetching, isLoading, settings } = usePostListSettings(['isCreatedStruct', 'listCreateUsers', 'listLoadingPolicy', 'createPolicy', 'dataLoadingPolicy']);
    const { isFetchingUsers, isLoadingUsers, UsersList } = useGetAllUsers();
    const { isFetchingGroups, isLoadingGroups, GroupsList } = useGetAllGroups();
    const saveSettings = useSaveSettings('userStructure');
    
    if (isLoading || isFetching || settings === undefined
        || isFetchingUsers || isLoadingUsers || UsersList === undefined
        || isFetchingGroups || isLoadingGroups || GroupsList === undefined) {
        return <Loader />;
    }

    structSettings.set('isCreatedStruct', JSON.parse(`${settings}`).isCreatedStruct === 'true');
    structSettings.set('listCreateUsers', JSON.parse(JSON.parse(`${settings}`).listCreateUsers));
    structSettings.set('listLoadingPolicy', JSON.parse(JSON.parse(`${settings}`).listLoadingPolicy));
    structSettings.set('createPolicy', JSON.parse(`${settings}`).createPolicy);
    structSettings.set('dataLoadingPolicy', JSON.parse(`${settings}`).dataLoadingPolicy);

    usersAndGroups.users = JSON.parse(`${UsersList}`);
    usersAndGroups.groups = JSON.parse(`${GroupsList}`);

    return (
      <Page>
        <PageHeader title={t('usersGroups_title')} />
        <PageContentUsers isCreatesStruct={structSettings.get('isCreatedStruct')} createFunction={saveSettings} settings={structSettings}/>
      </Page>
    );
}

function LabelInfoUsers(){
    return (
      <Typography>
        <Trans i18nKey={'usersGroups_descr'} />
        <IconMoreInfo title={<InfoTitlePermsForDoc />} />
      </Typography>
    )
}

function InfoTitlePermsForDoc(){
    return (
      <Fragment>
        <Typography color="inherit">
          <Trans i18nKey={'info_title1'} />
        </Typography>
        <Trans i18nKey={'usersGroups_exposition'} />
      </Fragment>
    )
}

type ContUsers = {
    isCreatesStruct: boolean;
    createFunction: Function;
    settings: Map<string, any>;
}

function PageContentUsers({isCreatesStruct, createFunction, settings} : ContUsers){
  const {create: createUsers} = useCreate<Array<User | NewUser>, User>('saveUsers');
  const {create: createGroups} = useCreate<Array<Group | NewGroup>, Group>('saveGroups');
  const saveSettings = useSaveListSettings();
  const [isCreated, setIsCreated] = useState<boolean>(isCreatesStruct);
  const [userGroup, setUserGroup] = useState<UserGroup>(usersAndGroups);
  const handlerCreated = () => {
      let savedSettings: Setting = {id: 0, value: (!isCreated).toString()}
      setIsCreated(!isCreated);
      createFunction(savedSettings);
      if(!isCreated) setUserGroup({users: [], groups: []});
  }

  const handlerSave = async () => {
    if(numberTab === 0){
      const UserTab: UserTabProps = await ChangeUserTab();
      usersAndGroups.users = UserTab.listUsers;
      const Users: Array<User | NewUser> = UserTab.listUsers;
      const Groups: Array<Group | NewGroup> = UserTab.listGroups;
      if(UserTab.isCorrect) await savePost(Users, Groups);
    }else if(numberTab === 1){
      const groupTab: GroupTabProps = await changeGroupTab();
      usersAndGroups.groups = groupTab.listgroups;
      const Users: Array<User | NewUser> = groupTab.listUsers;
      const Groups: Array<Group | NewGroup> = groupTab.listgroups;
      if(groupTab.isCorrect) await savePost(Users, Groups);
    };
  };

  const savePost = async (Users: Array<User | NewUser>, Groups: Array<Group | NewGroup>) => {
    let objectSettings: UsersSettingsProps = getSettingsUsers();
    let listSettings: Array<Setting> = [];
    for(var key of Object.keys(objectSettings)){
      listSettings.push({id: Number(key), value: objectSettings[key as keyof typeof objectSettings]});
    };
    await saveSettings(listSettings);
    await createUsers(Users);
    await createGroups(Groups);
    window.location.reload();
  };

  const changeNumberTab = (index: number, newUserGroup: UserGroup): void => {
    setUserGroup(newUserGroup);
    numberTab = index;
  };

  return (
    <Box sx={{ py: 2, px: 3 }}>
        <Stack>
            <Typography>{isCreated ? <LabelInfoUsers/> : <LabelIsNotStructure/>}</Typography>
            <Stack spacing={3}>
                <Button variant="contained" onClick={handlerCreated} sx={{ width: 150 }}>
                  <Trans i18nKey={isCreated ? 'button_delete' : 'button_create'} />
                </Button>
                {isCreated ?
                    <Fragment>
                      <Paper elevation={2} square sx={{ p: 2, borderLeft: '3px solid #fc8800' }}>
                          <Stack spacing={3}>
                              <Box sx={{display: 'flex'}}>
                                  <img src={warning} alt="(Warning)" style={{
                                      height: 'fit-content'
                                  }} />
                                  <Trans i18nKey={'usersGroups_warning'} />
                              </Box>
                          </Stack>
                      </Paper>
                      <UsersSettings settings={settings}/> 
                      <Paper elevation={1} square sx={{ paddingInline: 2 }}>
                          <Stack spacing={3}>
                            <TabsUsersGroups setChangeTab={changeNumberTab} usersGroup={userGroup} />
                          </Stack>
                      </Paper>
                      <Typography>
                        <div style={{ 
                                marginLeft: 25,
                                right: '1%',
                                bottom: '5%',
                                float: 'right'
                              }}>
                          <DiscardButton style={{
                                marginRight: '10px',
                                color: '#222f39',
                                backgroundColor: '#fff',
                                border: '1px solid rgba(34, 47, 57, 0.5)'
                              }
                            }/>
                          <Button
                                variant="contained"
                                color="primary"
                                style={{
                                  width: '100px'
                                }}
                                onClick={handlerSave}
                            >
                                <Trans i18nKey={'save'} />
                          </Button>
                        </div>
                      </Typography>        
                      
                    </Fragment> : 
                    null
                }
            </Stack>
        </Stack>
    </Box>
      
  )
}

function LabelIsNotStructure() {
    return (
        <Fragment>
          <Typography color="inherit">
            <Trans i18nKey={'usersGroups_notStruct'} />
          </Typography>
          <br/>
          <Typography color="black">
            <Trans i18nKey={'usersGroups_createStruct'} />
          </Typography>
        </Fragment>
    )
}

function TabsUsersGroups({setChangeTab, usersGroup}:{setChangeTab: (index: number, newUserGroup: UserGroup) => void, usersGroup: UserGroup}){
  const [value, setValue] = useState<number>(0);
  const handleChange = async (event: React.SyntheticEvent, newValue: number) => {
    let isChange: boolean = false;
    let newUserGroup: UserGroup = {
      users: [],
      groups: []
    };
    if(newValue === 0){
      const groupTab: GroupTabProps = await changeGroupTab();
      newUserGroup.groups = groupTab.listgroups;
      newUserGroup.users = groupTab.listUsers;
      isChange = groupTab.isCorrect;
    }else if(newValue === 1){
      const UserTab: UserTabProps = await ChangeUserTab();
      newUserGroup.users = UserTab.listUsers;
      newUserGroup.groups = UserTab.listGroups;
      isChange = UserTab.isCorrect;
    }
    if (isChange) {
      setChangeTab(newValue, newUserGroup);
      setValue(newValue)
    };
  };

  return (
    <Box >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} aria-label="simple tabs example" onChange={handleChange}>
          <Tab label={<Trans i18nKey={'usersGroups_users'} />} />
          <Tab label={<Trans i18nKey={'usersGroups_groups'} />} />
        </Tabs>
      </Box>
        <TabPanel value={value} index={0}>
          <UsersContainer users={usersGroup.users} groups={usersGroup.groups} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <GroupsContainer users={usersGroup.users} groups={usersGroup.groups}/>
        </TabPanel>
  </Box>
  )
}

interface TabPanelProps {
  children?: React.ReactNode;
  index?: any;
  value?: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

type ButtonsFunction = {
  onAdded: () => Promise<void>;
  onDelete: () => Promise<void>;
  title: string;
};

export function ButtonsContainer({onAdded, onDelete, title}: ButtonsFunction){
  const [isOpenDialog, setOpenDialog] = useState<boolean>(false);

  const handleDelete = async () => {
    setOpenDialog(false);
    await onDelete();
  };

  return (
    <Fragment>
      <Button 
          onClick={() => setOpenDialog(true)} 
          size='small'
          sx={{
            borderRight: '1px solid black',
            borderRadius: 0
          }}
        >
          <DeleteIcon/>
          <Trans i18nKey={'button_delete'}/>
        </Button>
        <SimpleModal
          title={title}
          isOpen={isOpenDialog}
          handleClose={() => setOpenDialog(false)}
        >
          <Box >
            <Stack
              direction="row-reverse"
              spacing={3}
              alignItems="right"
              justifyContent="end"
              sx={{ pt: 3 }}
            >
              <Button
                variant="contained"
                onClick={handleDelete}
                sx={{ width: 150 }}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                onClick={() => setOpenDialog(false)}
                sx={{ width: 150 }}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </SimpleModal>
        <Button 
          onClick={onAdded} 
          size='small'
        >
          <AddIcon color='secondary'/>
          <Trans i18nKey={'add'}/>
        </Button>
    </Fragment>
  );
}

function DiscardButton({...props }: ButtonProps){
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleOnClick = () => {
    setIsOpen(true);
    window.location.reload();
  };
  return (
    <Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsOpen(true)}
        {...props }
      >
        <Trans i18nKey={'button_discard'} />
      </Button>
      <SimpleModal title={t('discard_description')} isOpen={isOpen} handleClose={() => setIsOpen(false)}>
        <Box>
          <Stack
            direction="row-reverse"
            spacing={3}
            alignItems="right"
            justifyContent="end"
            sx={{ pt: 3 }}
          >
            <Button variant="contained" onClick={handleOnClick} sx={{ width: 150 }}>
              <Trans i18nKey={'answer_yes'} />
            </Button>
            <Button variant="outlined" onClick={() => setIsOpen(false)} sx={{ width: 150 }}>
              <Trans i18nKey={'answer_no'} />
            </Button>
          </Stack>
        </Box>
      </SimpleModal>
    </Fragment>
  )
}
