import { TextareaAutosize, TextFieldProps } from '@mui/material';

type Props = {
  register?: any;
  maxRows?: number;
  minRows?: number;
} & TextFieldProps;

export function ControlledTextarea({ register, maxRows, minRows, ...props }: Props) {
  // let { maxRows = 1, minRows = 1 } = register;
  return (
    <TextareaAutosize
      maxRows={maxRows}
      minRows={minRows}
      style={{
        width: '100%',
        border: '1px solid',
      }}
      {...register}
      {...props}
    />
  );
}
