import { Box, Grid, List, ListItemButton, ListItemText, Paper, TextFieldProps, Typography } from "@mui/material";
import { Fragment, useEffect, useMemo, useState } from "react";
import { FieldErrors, useForm, UseFormRegister, UseFormTrigger } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import useAlert from "../../../../../../context/alertContext/useAlert";
import useCreate from "../../../../../../serverInteraction/hooks/entity/useCreate";
import useRemove from "../../../../../../serverInteraction/hooks/entity/useRemove";
import { Group, NewGroup } from "../../../../../../types/content/roles-and-permissions/users&groups/Group";
import { NewUser, User } from "../../../../../../types/content/roles-and-permissions/users&groups/User";
import { ControlledInput } from "../../../../../common/input/ControlledInput";
import { TransferList } from "../../../../../common/transfetList/TransferList";
import { ButtonsContainer } from "../UsersGroups";

type GroupContainerProps = {
  users: Array<User | NewUser>, 
  groups: Array<Group | NewGroup>
};

export type GroupTabProps = {
  listgroups: Array<Group | NewGroup>,
  listUsers: Array<User | NewUser>,
  index: number,
  trigger: any,
  isCorrect: boolean,
  curGroupUsers: any
};

let groupTab: GroupTabProps = {
  listgroups: [],
  listUsers: [],
  index: 0,
  trigger: undefined,
  isCorrect: true,
  curGroupUsers: []
};

export function GroupsContainer({users, groups} : GroupContainerProps){
  const [listGroups, setListGroups] = useState<Array<Group | NewGroup>>(groups);
  
  useEffect(() => {
    groupTab.listgroups = groups;
    groupTab.listUsers = users;
    groupTab.index = 0;
  }, [
    
  ])
  
  const { t } = useTranslation();
  const { showSuccess } = useAlert();
  const { remove } = useRemove('Group');
  const { create } = useCreate('saveGroup');

  const {register, trigger, formState: { errors }} = useForm<Group | NewGroup>();
  groupTab.trigger = trigger;

  const newListGroupUsers = (newList: any) => {
    newGroupsCurUser = newList;
    groupTab.curGroupUsers = newList;
  };

  const listNameGroups: Array<string> = listGroups.map((item: Group | NewGroup) => item.name);
  const [selectedIndex, setSelectedIndex] = useState<number>(listGroups.length === 0 ? -1 : 0);
  const allUsers: Array<string> = users.map((item: User | NewUser)=> item.name);
  const groupUsers: Array<string> = useMemo(() => {
    if(listGroups.length !== 0) {
      let list: Array<string> = [];
      users.map((item: User | NewUser) => {
        if(listGroups[selectedIndex].listUsers.indexOf(Number(item.id)) !== -1) list.push(item.name);
      });
      return list;
    } else {
      return [];
    };
  }, [selectedIndex]);
  let newGroupsCurUser: Array<string> = groupUsers;

const handleUpdateGroupsInUser = async (curGroup: Group | NewGroup, newListUsersId: Array<string | null>): Promise<void> => {
  for(let i: number = 0; i < users.length; i++){
    let listGroupsId: Array<string | null> = users[i].groups.map((item: Group | NewGroup) => (item.id?.toString() || null));
    let indexGroup: number = listGroupsId.indexOf(curGroup.id?.toString() || null);
    let isGroupInUser: boolean = newListUsersId.indexOf(users[i].id?.toString() || null) === -1;
    if(indexGroup !== -1 && isGroupInUser){
      users[i].groups.splice(indexGroup, 1);
    }else if(indexGroup === -1 && !isGroupInUser){
      users[i].groups.push(curGroup);
    };
  };
  groupTab.listUsers = users;
}

  const handleListItemClick = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    if(await trigger()){
      let listUsersId: Array<string | null> = [];
      const values: Group | NewGroup = {
        id: listGroups[selectedIndex].id,
        name: (document.getElementById('groupName') as HTMLInputElement).value,
        description: (document.getElementById('groupDescription') as HTMLTextAreaElement).value,
        listUsers: newGroupsCurUser.map((item: string) => {
          const index: number = allUsers.indexOf(item);
          listUsersId.push(users[index].id?.toString() || null);
          return Number(users[index].id);
        })
      };
    let newGroups: Array<Group | NewGroup> = listGroups;
    newGroups[selectedIndex] = values;
    groupTab.index = index;
    groupTab.listgroups = newGroups;
    handleUpdateGroupsInUser(values, listUsersId);
    setListGroups(newGroups);
    setSelectedIndex(index);
    };
  };

  const onAddedGroup = async () => {
     if(await trigger()){
      const newGroup: Group = {
        id: 0,
        name: 'NewGroup',
        description: '',
        listUsers: []
      };
      let listUsersId: Array<string | null> = [];
      const savedGroup: Group = JSON.parse(`${await create(newGroup)}`);
      let list: Array<Group | NewGroup> = [...listGroups, savedGroup];
      if (listGroups.length !== 0) {
        const values: Group | NewGroup = {
          id: listGroups[selectedIndex].id,
          name: (document.getElementById('groupName') as HTMLInputElement).value,
          description: (document.getElementById('groupDescription') as HTMLTextAreaElement).value,
          listUsers: users.length === 0 ? [] : newGroupsCurUser.map((item: string) => {
            const index: number = allUsers.indexOf(item);
            listUsersId.push(users[index].id?.toString() || null);
            return Number(users[index].id);
          })
        };
        list[selectedIndex] = values;
        await handleUpdateGroupsInUser(values, listUsersId);
      }
      groupTab.index = list.length - 1;
      groupTab.listgroups = list;
      setListGroups(list);
      setSelectedIndex(list.length - 1);
     }
  };

  const onDeleteGroup = async () => {
    await remove(listGroups[selectedIndex].id?.toString());

    const newIndex: number = selectedIndex === 0 ? listNameGroups.length - 2 : selectedIndex - 1;
    const newList: Array<Group | NewGroup> = listGroups.filter((item, index) => index !== selectedIndex);
    for(let i: number = 0; i < users.length; i++){
      for(let j: number = 0; j < users[i].groups.length; j++){
        if(listGroups[selectedIndex].id === users[i].groups[j].id){
          users[i].groups.splice(j, 1);
          break;
        }
      };
    };
    
    groupTab.index = newIndex;
    groupTab.listgroups = newList;
    groupTab.listUsers = users;
    showSuccess(t('deletedRecord'));
    setSelectedIndex(newIndex);
    setListGroups(newList);
  };

  return (
    <Grid container 
          sx={{
              marginBottom: 1,
              marginTop: 1
          }}
      >
      <Grid item xs={12} sx={{
        border: '1px solid #e7e7ee',
        borderRadius: '3px'
        
      }}>
        <ButtonsContainer onAdded={onAddedGroup} onDelete={onDeleteGroup} title={t('groupDeleteTitile').replace('%1', selectedIndex === -1 ? '' : listGroups[selectedIndex].name)} />
      </Grid>
      {selectedIndex === -1 ? null :
        <Fragment>
          <Grid item 
            xs={2} 
            sx={{
              marginTop: 1,
              height: 'auto'
            }}
          >
            <Paper
              style={{
                overflow: 'auto',
                height: '95%'
              }}
            >
              <List component="nav" aria-label="Groups">
              {listNameGroups.map((item: string, index: number) => (
                <ListItemButton key={index} selected={selectedIndex === index} onClick={(event) => handleListItemClick(event, index)}>
                  <ListItemText primary={item}  key={index}/>
                </ListItemButton>
              ))}
              </List>
            </Paper>
          </Grid>
          <Grid item 
            xs={5} 
            sx={{
              marginTop: 1,
              height: 'auto'
            }}
          >
            <InputsGroups index={selectedIndex} register={register} errors={errors} listGroups={listGroups} />
          </Grid>
          <Grid item 
            xs={5} 
            sx={{
              marginTop: 1,
              height: 'auto'
            }}
          >
            <div style={{display: 'inline-flex', width: '100%'}}>
              <div style={{width: '50%'}}>
                <b style={{marginLeft: '20%'}}><Trans i18nKey={'usersGroups_allUsers'} /></b>
              </div>
              <div style={{width: '50%'}}>
                <b style={{marginLeft: '20%'}}><Trans i18nKey={'usersGroups_usersInGroup'} /></b>
              </div>
            </div>
            <div style={{marginLeft: '1.25em'}}>
              <TransferList entityList={allUsers} loadEntities={newGroupsCurUser} isUserTransferList={true} updateParent={newListGroupUsers} width={window.screen.width < 2000 ? 170 : 230}/>
            </div>
          </Grid>
        </Fragment>
      }
    </Grid>
  )
}

type InputsGroupProps = {
  index: number,
  register: UseFormRegister<Group | NewGroup>,
  errors: FieldErrors<Group | NewGroup>,
  listGroups: Array<Group | NewGroup>
}

function InputsGroups({index, register,listGroups, errors} : InputsGroupProps){

  return (
    <Box sx={{
        marginLeft: 2,
        width: '100%',
        height: '100%'
    }}>
      <InputGroup
        currentValue={listGroups[index].name}
        selectedIndex={index} 
        type="text"
        id='groupName'
        variant='outlined'
        label={<Trans i18nKey={'usersName'}/>}
        inputProps={{ 
            style: { 
            padding: "0.5rem 1rem "
            },
            maxLength: 100
        }} 
        sx={{
            width: '100%'
        }}
        size='small'
        register={{
            ...register('name', {
            required: {
              value: true,
              message: 'Name is required',
            },
            minLength: {
              value: 3,
              message: 'Must be at least 3 symbols',
            },
            pattern:{
              value: /^[A-Za-z]{1}[A-Za-z\-\_\s+0-9]+$/,
              message: "Only alphanumeric characters, '_' and '-' are allowed in the IDs. The ID must start with a letter.",
            }}),
        }}
        helperText={errors.name?.message}
        error={errors.name !== undefined}
      />
      <InputGroup 
        currentValue={listGroups[index].description}
        selectedIndex={index}
        id='groupDescription'
        variant='outlined'
        multiline
        rows={6}
        label={<Trans i18nKey={'groupsDescription'}/>}
        inputProps={{ 
            style: { 
            padding: "0.5rem 1rem "
            },
            maxLength: 1000
        }} 
        sx={{
            width: '100%',
            marginTop: 2
        }}
        register={{
            ...register('description', {}),
        }}
      />
    </Box>
  )
}

type TypeInputGroupProps = {
    register: any;
    currentValue: string | null;
    selectedIndex: number;
  } & TextFieldProps;

function InputGroup({register, currentValue, selectedIndex, ...textFieldProps}: TypeInputGroupProps){
  const [value, setValue] = useState<string | null>(currentValue);
  useEffect(() => {
    setValue(currentValue);
  }, [selectedIndex])
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };
  return(<ControlledInput register={register} {...textFieldProps} value={value} onChange={onChange}/>);
}

export const changeGroupTab = async (): Promise<GroupTabProps> => {
  let users: Array<User | NewUser> = groupTab.listUsers;
  const handleUpdateGroupsInUser = async (curGroup: Group | NewGroup, newListUsersId: Array<string | null>): Promise<void> => {
    for(let i: number = 0; i < users.length; i++){
      let listGroupsId: Array<string | null> = users[i].groups.map((item: Group | NewGroup) => (item.id?.toString() || null));
      let indexGroup: number = listGroupsId.indexOf(curGroup.id?.toString() || null);
      let isGroupInUser: boolean = newListUsersId.indexOf(users[i].id?.toString() || null) === -1;
      if(indexGroup !== -1 && isGroupInUser){
        users[i].groups.splice(indexGroup, 1);
      }else if(indexGroup === -1 && !isGroupInUser){
        users[i].groups.push(curGroup);
      };
    };
    groupTab.listUsers = users;
  }
  try {
    let allUsers = users.map((item: User | NewUser)=> item.name);

    groupTab.isCorrect = groupTab.trigger && await groupTab.trigger();
    if(groupTab.isCorrect && groupTab.listgroups.length !== 0){
      let listUsersId: Array<string | null> = [];
      const values: Group | NewGroup = {
        id: groupTab.listgroups[groupTab.index].id,
        name: (document.getElementById('groupName') as HTMLInputElement).value,
        description: (document.getElementById('groupDescription') as HTMLTextAreaElement).value,
        listUsers: users.length === 0 ? [] : groupTab.curGroupUsers.map((item: string) => {
          const index: number = allUsers.indexOf(item);
          listUsersId.push(users[index].id?.toString() || null);
          return Number(users[index].id);
        })
      };
      groupTab.listgroups[groupTab.index] = values;
      await handleUpdateGroupsInUser(values, listUsersId);
    }

    return groupTab;
  } catch(error) {
    throw('error');
  }

};