import { Select, SelectChangeEvent } from "@mui/material";
import { DefaultTypesForDocument } from "../../../../../../enum/DefaultTypesForDocument";
import useDocTypes from "../../../../../../serverInteraction/hooks/content/document-types/useDocTypes";
import { Loader } from "../../../../../common/loader/Loader";

interface IProps {
    variant: 'CREATE' | 'UPDATE'
    value: string,
    setValue: (value: string) => void
}

function ExtensionSelector({ variant, value, setValue }: IProps) {
    const { documentTypes, isLoading: documentTypesLoading } = useDocTypes();

    const handleChange = (event: SelectChangeEvent) => {
        setValue(event.target.value as string);
    };

    if ( documentTypesLoading || documentTypes === undefined ) {
        return <Loader />;
    }
    
    const documentTypesValues = JSON.parse(`${documentTypes}`).map(
        (documentType: any) => documentType.featureId) || [];
    //При создании документа основные типы (Document, File, Folder) выделены в отдельные radio
    //Здесь их надо исключить из списка
    const exceptionKeys = variant === 'CREATE' ? ['Document', 'File', 'Folder'] : [];
    return(
        <Select
            value={value}
            native
            id="grouped-native-select"
            onChange={handleChange}
            sx={{ width: '300px' }}
          >
            <option aria-label="None" value="" />
            <optgroup label="Local types">
              {documentTypesValues.map((item: any) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                );
              })}
            </optgroup>
            <optgroup label="Default types">
              {Object.keys(DefaultTypesForDocument)
                .filter((key: any) => (!isNaN(Number(DefaultTypesForDocument[key])) && !exceptionKeys.includes(key) ))
                .map((key: any) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
            </optgroup>
          </Select>
    )
}

export default ExtensionSelector;