import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Select, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PackageProps } from '../../../../../types/packages/PackageProps';
import { GetDocTypesProps } from '../../../../../types/content/documentTypes/GetDocTypesProps';
import { CreateUpdateDocTypesProps } from '../../../../../types/content/documentTypes/CreateUpdateDocTypesProps';
import { DOCTYPES_ENDPOINT } from '../../../../../serverInteraction/services/endpointsConstants';
import useDocTypes from '../../../../../serverInteraction/hooks/content/document-types/useDocTypes';
import useCreate from '../../../../../serverInteraction/hooks/entity/useCreate';
import { AddDialog } from '../../../../common/dialog/AddDialog';
import { ControlledInput } from '../../../../common/input/ControlledInput';
import { GridOneToThree, GridOneToThreeItemProps } from '../../../../common/grid/GridOneToThree';
import { SimpleAccordion } from '../../../../common/accordion/SimpleAccordion';
import { ControlledRadioGroup } from '../../../../common/radioGroup/ControlledRadioGroup';
import { ControlledTextarea } from '../../../../common/textarea/ControlledTextarea';
import { modeItems } from '../tabs/ExtensionSelector/constants';
import ExtensionSelector from '../tabs/ExtensionSelector/ExtensionSelector';
import useAlert from '../../../../../context/alertContext/useAlert';

export function DocumentTypesCreateDialog() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { create } = useCreate<CreateUpdateDocTypesProps, GetDocTypesProps>(DOCTYPES_ENDPOINT);
  const { refetch } = useDocTypes();
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const { showError } = useAlert();

  const {
    control,
    register,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<CreateUpdateDocTypesProps>(
    {
      defaultValues: {
        extendType: modeItems[0].value
      }
    }
  );
  
  const watchMode = watch('extendType');

  const createItems: GridOneToThreeItemProps[] = [
    {
      title: 'Feature ID',
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register('featureId', {
              pattern: {
                value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                message:
                  "Only alphanumeric characters, '_' and '-' are allowed in the IDs. The ID must start with a letter.",
              },
              required: {
                value: true,
                message: 'ID is required',
              },
              minLength: {
                value: 3,
                message: 'Must be at least 3 symbols',
              },
            }),
          }}
          helperText={errors.featureId?.message}
          error={errors.featureId !== undefined}
          sx={{ width: '100%' }}
        />
      ),
    },
    {
      title: t('extends'),
      required: true,
      content: (
        <>
          <Controller
            control={control}
            name="extendType"
            render={({ field: { onChange, value } }) => (
              <ControlledRadioGroup
                id="mode-radio-group"
                {...register('extendType')}
                value={value}
                items={modeItems.map((modeItem) => {
                  return {
                    // label: ECreateDocTypeMode[modeItem.item],
                    // label: translate('', ECreateDocTypeMode[modeItem.item]),
                    label: modeItem.item,
                    value: modeItem.value,
                    name: Math.random().toFixed(2),
                  };
                })}
                onChange={onChange}
              />
            )}
          />
          <Typography style={{ marginLeft: '25px', minWidth: '20px' }}>
            {t(modeItems.find((modeItem) => modeItem.value === watchMode)?.helperText || "")}
          </Typography>
        </>
      ),
    },
    {
      title: '',
      content: (
        <SimpleAccordion title={t('otherAdvanced')} defaultExpanded={false}>
          <ExtensionSelector 
            variant = 'CREATE' 
            value={getValues('extendType')}
            setValue = {(value: string) => {setValue('extendType', value)}}
          />
        </SimpleAccordion>
      ),
    },
  ];

  const desLab = [
    {
      title: 'Label',
      required: true,
      content: (
        <ControlledInput
          type="text"
          register={{
            ...register('label', {
              required: {
                value: true,
                message: 'Label is required',
              },
              minLength: {
                value: 5,
                message: 'Must be at least 5 symbols',
              },
            }),
          }}
          helperText={errors.label?.message}
          error={errors.label !== undefined}
          sx={{ width: '100%' }}
        />
      ),
    },
    {
      title: 'Description',
      required: false,
      content: (
        <ControlledTextarea
          type="text"
          register={{
            ...register('description', {}),
            maxRows: 4,
            minRows: 3,
          }}
          sx={{ width: '100%' }}
        />
      ),
    },
  ];

  const onSubmit = async (data: CreateUpdateDocTypesProps) => {
    const newDoctype :CreateUpdateDocTypesProps= {
      featureId: data.featureId,
      label: data.label,
      description: data.description,
      extendType: data.extendType,
      company: userPackage.company,
      document: userPackage.document,
      lifeCycle: 'default',
      containerTypes: ['Folder', 'Workspace'],
      deploymentMode: 'MERGE',
      customSchema: {
        featureId: data.featureId,
        prefix: data.featureId,
        companyName: userPackage.company,
        documentName: userPackage.document,
        fields: []
      },
    }
    try {
      const res: GetDocTypesProps = JSON.parse(`${await create(newDoctype)}`);
      reset();
      navigate(`${res.id}`);
    } catch (error: any ) {
      console.log(error);
    }
  };

  const validate = async () => {
    return await trigger();
  };

  return (
    <AddDialog
      title="Create Document Type"
      add={handleSubmit(onSubmit)}
      validate={validate}
      isCreate={() => refetch()}
    >
      <hr />
      <Box sx={{ minHeight: 500 }}>
        <GridOneToThree items={createItems} />
        <SimpleAccordion title="Advanced configuration" sx={{ mt: 3 }} defaultExpanded={false}>
          <Select native defaultValue="" id="grouped-native-select">
            <option aria-label="None" value="" />
            <optgroup label="Category 1">
              <option value={1}>Option 1</option>
              <option value={2}>Option 2</option>
            </optgroup>
            <optgroup label="Category 2">
              <option value={3}>Option 3</option>
              <option value={4}>Option 4</option>
            </optgroup>
          </Select>
        </SimpleAccordion>
        <GridOneToThree items={desLab} />
      </Box>
    </AddDialog>
  );
}
