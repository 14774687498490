import { useSearchParams } from 'react-router-dom';
import { AppBar, Box, Button, Stack, Tab, Tabs, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { PageHeader } from '../../../common/page/PageHeader';
import { Page } from '../../../common/page/Page';
import { PageContent } from '../../../common/page/PageContent';
import { DefinitionForm } from './tabs/DefinitionForm';
import { SchemaForm } from './tabs/SchemaForm';
import { GetDocTypesProps } from '../../../../types/content/documentTypes/GetDocTypesProps';
import useUpdate from '../../../../serverInteraction/hooks/entity/useUpdate';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DOCTYPES_ENDPOINT } from '../../../../serverInteraction/services/endpointsConstants';
import { CreateUpdateDocTypesProps } from '../../../../types/content/documentTypes/CreateUpdateDocTypesProps';
import { useSelector } from 'react-redux';
import { PackageProps } from '../../../../types/packages/PackageProps';
import { CommonEntityProps } from '../../../../types/common/CommonEntityProps';

interface TabPanelProps {
  children?: React.ReactNode;
  index?: any;
  value?: any;
}

interface IProps {
  initialData: CreateUpdateDocTypesProps,
  documentTypesList: string[],
  facetsList: CommonEntityProps[],
  facetsRequired: string[],
  lifeCyclesList: any[],
  schemasList: string[],
  refetch: any
}

export function DocumentTypeView({initialData, documentTypesList, facetsList, facetsRequired, lifeCyclesList, schemasList, refetch }: IProps) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const userPackage: PackageProps = useSelector((state: any) => state.packages.userPackage);
  const tabIndex = Number(searchParams.get('tab') || '0');
  const extensionParam = searchParams.get('ext') || 'false';
  const { update } = useUpdate<CreateUpdateDocTypesProps, GetDocTypesProps>(DOCTYPES_ENDPOINT);
 
  const { control, getValues, setValue, register, handleSubmit, formState: {errors}, reset } = useForm<CreateUpdateDocTypesProps>({
    defaultValues: initialData
  });

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleCancel = () => {
    reset()
  }

  const onSubmit = async (formData: CreateUpdateDocTypesProps) => {
    //фасеты пишем только необязательные 
    const newFacets: string[] | undefined = formData.facets?.filter((elem: string) => !facetsRequired.includes(elem))

    const newData: CreateUpdateDocTypesProps = {
      featureId: initialData.featureId,
      company: initialData.company,
      document: initialData.document,
      label: formData.label,
      description: formData.description,
      extendType: formData.extendType,
      iconUrl: formData.iconUrl,
      bigIconUrl: formData.bigIconUrl,
      lifeCycle: formData.lifeCycle,
      containerTypes: formData.containerTypes,
      subTypes: formData.subTypes,
      facets: newFacets,
      deploymentMode: 'MERGE',
      schemas: formData.schemas,
      customSchema: {
        featureId: formData.customSchema.featureId,
        prefix: formData.customSchema.prefix,
        companyName: userPackage.company,
        documentName: userPackage.document,
        fields: formData.fields || []
      }
    }

    try {
      await update('id', newData);
      refetch();
    } catch (error: any ) {
      console.log(error);
    }
  };

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSearchParams({tab: newValue.toString(), ext: extensionParam});
  };

  return (
    <Page>
      <PageHeader title={`Document Type ${initialData.featureId}`}></PageHeader>
      <PageContent subtitle={`Define the main characteristics of your document type`}>
        <form id="doctype__form" onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <Stack >
              <Box
                sx={{
                  width: '100%',
                  justifyContent: 'right',
                  display: 'flex',
                  paddingTop: '1em',
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  endIcon={<ArrowForwardIcon/>}
                  sx={{alignItems:'center'}}
                >
                  {`${t('configureLayoutsButton')} Designer`}
                </Button>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  justifyContent: 'right',
                  display: 'flex',
                  paddingTop: '1em',
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick = {handleCancel}
                >
                  {t('button_discard')}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  
                  sx={{ marginLeft: '1em' }}
                >
                  {t('save')}
                </Button>
              </Box>
            </Stack>
            <AppBar style={{ backgroundColor: 'white', color: 'black' }} position="static">
              <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="simple tabs example">
                <Tab label="Definition" />
                <Tab label="Schema" />
              </Tabs>
            </AppBar>
            <TabPanel value={tabIndex} index={0}>
              <DefinitionForm
                documentTypesList = {documentTypesList}
                facetsList = {facetsList}
                facetsRequired={facetsRequired}
                lifeCyclesList={lifeCyclesList}
                initialContainerTypes = {initialData.containerTypes}
                initialFacets = {initialData.facets || []}
                register={register}
                errors={errors}
                getValues={getValues}
                setValue = {setValue}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <SchemaForm
                extendType = {initialData.extendType}
                inheritedSchemas = {initialData.inheritedSchemas || ''}
                schemasBroughtByFacets = {initialData.schemasBroughtByFacets || ''}
                schemasList = {schemasList}
                control = {control}
                register={register}
                errors={errors}
                getValues={getValues}
                setValue = {setValue}
              />
            </TabPanel>
          </Stack>
        </form>
      </PageContent>
    </Page>
  );
}
