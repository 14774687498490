import { LifeCycleProps } from '../../../../types/content/life-cycles/LifeCycleProps';
import useGet from '../../entity/useGet';

const useLifeCycle = (id: string) => {
  const entityName = 'life-cycle';

  const { status, data, error, isLoading, isFetching, isRefetching, refetch } =
    useGet<LifeCycleProps>(entityName, id);

  return {
    status,
    lifeCycle: data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default useLifeCycle;
