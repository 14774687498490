import { PackageProps } from '../../../types/packages/PackageProps';
import useGetAll from '../entity/useGetAll';

const usePackages = (company?: string) => {
  const entityName = `package?company=${company}`;

  const { status, data, error, isLoading, isFetching, isRefetching, refetch } =
    useGetAll<PackageProps>(entityName);

  return {
    status,
    projects: data,
    error,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default usePackages;
