import { FormProvider, useForm } from 'react-hook-form';
import { PackageProps } from '../../../types/packages/PackageProps';
import { PackageCreateDialog } from './create/PackageCreateDialog';
import { PackagesFormList } from './PackagesFormList';

export function PackagesForm({ packages }:{ packages: PackageProps[] }){
  const packageFormMethods = useForm<{listPackages: PackageProps[]}>({
    defaultValues: {
      listPackages: packages
    }
  });

  return (
    <FormProvider {...packageFormMethods}>
      <PackageCreateDialog/>
      <PackagesFormList packages = {packages}/>
    </FormProvider>
  );
}

