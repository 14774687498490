import { Checkbox, FormControl, Stack } from "@mui/material"
import { Fragment, useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { useLocation } from "react-router-dom";
import { FieldProps } from "../../../../../../../../types/content/schemas/Fields/NewFieldProp";
import { Inforequired, LabelRequired } from "../ValidationLabels";

export default function RequiredView({index}: {index: number}){
    const { state } = useLocation();
    const { control } = useFormContext<FieldProps>();
    const watchParams = useWatch({
        control,
        name: [`constraints.${index}.mandatoryConstraint.value`, 'name']
    });

    return (
        <Fragment>
            <Stack direction='row' alignItems='center' sx={{marginLeft: '1em'}}>
                <Controller
                        name={`constraints.${index}.mandatoryConstraint.value`}
                        control={control}
                        render={({ field: { onChange, value } }) => <Checkbox onChange={onChange} checked={value} />}
                />
                <FormControl sx={{cursor: 'default', width: '100%'}}>
                    <Inforequired />  
                </FormControl>
            </Stack>
            {watchParams[0] && <LabelRequired schema={state.featureId as string} field={watchParams[1]}/>}
        </Fragment>
        )
}