import { Checkbox, FormControl, Stack } from "@mui/material"
import { Controller, useWatch } from "react-hook-form"
import { InfoListRequired, LabelRequired } from "../ValidationLabels";


interface IProps {
    schemaName: string,
    fieldName: string,
    index: number,
    fieldIndex: number,
    control: any,
    
}

export default function ListRequiredView({schemaName, fieldName, index, fieldIndex, control }: IProps){
    const fieldsWatches = useWatch({
        control,
        name: [`fields.${fieldIndex}.isMultiValue`, `fields.${fieldIndex}.constraints.${index}.mandatoryConstraint.value`]
    });

    
    if (fieldsWatches[0]){
        return (
            <>
                <Stack direction='row' alignItems='center' sx={{marginTop: '1em', marginLeft: '1em'}}>
                    <Controller
                        name={`constraints.${index}.listElementsMandatoryConstraint.value`}
                        control={control}
                        render={({ field: { onChange, value } }) => <Checkbox onChange={onChange} checked={value} />}
                    />
                    <FormControl sx={{cursor: 'default', width: '100%'}}>
                        <InfoListRequired />  
                    </FormControl>
                </Stack>
                {fieldsWatches[1] && <LabelRequired schema={schemaName} field={fieldName}/>}
            </>
        )
    }

    return (<></>)
}