import { Paper, Stack, Link, Box, Button, TextField, FormControlLabel, Checkbox } from "@mui/material";
import { createContext, Fragment, useContext, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Link as RouterLink, useLocation, useParams} from "react-router-dom";
import { Page } from "../../../../common/page/Page";
import { PageContent } from "../../../../common/page/PageContent";
import { PageHeader } from "../../../../common/page/PageHeader";
import { SchemaTitle } from "../SchemaFieldsForm";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { ControlledSelect } from "../../../../common/select/ControlledSelect";
import AdvancedBooleanSettings from "./AdvancedTypeSettings/BooleanField";
import StringFieldForm from "./AdvancedTypeSettings/StringField";
import AdvancedIntegerSettings from "./AdvancedTypeSettings/IntegerField";
import { FieldsSelectValue } from "../../../../../staticValues/Constants";
import AdvancedFloatingSettings from "./AdvancedTypeSettings/FloatingField";
import { AdvancedDateSettings } from "./AdvancedTypeSettings/DateField";
import AdvancedBlobSettings from "./AdvancedTypeSettings/BlobField";
import { AdvancedDocumentSettings } from "./AdvancedTypeSettings/DocumentField";
import { AdvanceUserGroupSettings } from "./AdvancedTypeSettings/UserGroupField";
import AdvancedDirectoryField from "./AdvancedTypeSettings/DirectoryField";
import { Setting } from "../../../../../types/common/Setting";
import AdvancedComplexSetting from "./AdvancedTypeSettings/ComplexField";
import { SchemaWithFieldsProps } from "../../../../../types/content/schemas/SchemaWithFieldsProps";
import { t } from "i18next";
import { FieldProps } from "../../../../../types/content/schemas/Fields/NewFieldProp";

type paramUrl = {
    id: string;
    fieldName: string
}

export const FieldContext = createContext<{
        field: FieldProps| null,
        setField: (newValue: FieldProps) => void,
        setKey: (newValue: number) => void
    }>({
        field: null,
        setField: (newValue: FieldProps) => {},
        setKey: (newValue: number) => {}
    });

export default function AdvancedFieldSetting(){
    const schema: SchemaWithFieldsProps = useLocation().state;
    
    return (
        <Page>
          <PageHeader title={`${t('schemaName')} ${schema.featureId}`} />
          <PageContent subtitle={<SchemaTitle />}>
            <Paper elevation={2} square sx={{ p: 2 }}>
                <FormField schema={schema} />
            </Paper>
          </PageContent>
        </Page>
    );
}

type AdvancedSettingsContextProps = {
    mainUseForm: any;
    JsonAdvancSettings: string;
    parentsFields: Array<Setting>;
    parentUrl: string;
}

export const advancedSettingsContext = createContext<AdvancedSettingsContextProps>({
    mainUseForm: {},
    JsonAdvancSettings: '[]',
    parentsFields: [],
    parentUrl: ''
});



function FormField({ schema }:{ schema: SchemaWithFieldsProps}){
    const paramsUrl = useParams() as paramUrl;
    const originalField = schema.fields.filter((item: FieldProps) => item.name === paramsUrl.fieldName)[0];
    const [field, setFieldType] = useState(originalField);
    const [key, setKey] = useState(Math.random());
    
    const renderFormType = () => {
        switch(field.fieldType){
            case 'STRING':
                return (<StringFieldForm />);
            default:
                return(<Fragment />);
        }
    };

    return (
        <FieldContext.Provider key={key} value={{
            field: field,
            setField: setFieldType,
            setKey: setKey
        }}>
            {renderFormType()}
        </FieldContext.Provider>
    )

    // switch (fieldType) {
    //     case 'STRING':
    //         return (<AdvancedStringSettings />);
    //     // case 'Boolean':
    //     //     return (<AdvancedBooleanSettings />);
    //     // case 'integer':
    //     //     return (<AdvancedIntegerSettings />);
    //     // case 'double':
    //     //     return(<AdvancedFloatingSettings />);
    //     // case 'date':
    //     //     return(<AdvancedDateSettings />);
    //     // case 'blob':
    //     //     return (<AdvancedBlobSettings />);
    //     // case 'documentResolver.reference':
    //     //     return (<AdvancedDocumentSettings />);
    //     // case 'userManagerResolver.reference':
    //     //     return (<AdvanceUserGroupSettings />);
    //     // case 'directoryResolver.reference':
    //     //     return (<AdvancedDirectoryField />);
    //     // case 'Complex':
    //     //     return(<AdvancedComplexSetting />);
    //     default:
    //         return(<Fragment />);
    // };
};

export function ButtonsOnSubmitAdvancedField({handleOnSubmit, handleOnReset}: {handleOnSubmit: () => void; handleOnReset: () => void;}){
    return(
        <div style={{
            display: 'flex',
            justifyContent: 'end',
            marginTop: '1em',
            marginRight: '1em'
        }}>
            <Button variant="outlined" color="primary" onClick={handleOnReset}>
                <Trans i18nKey={'button_discard'} />
            </Button>
            <Button onClick={handleOnSubmit} variant="contained" color="primary" sx={{marginLeft: '1em'}}>
                <Trans i18nKey={'save'} />
            </Button>
        </div>
    )
}